import { ExamPrevDays } from '@/types/types'
import { useEffect, useState } from 'react'
import Icon from './icons/Icon'


interface ExamPrevCardProps {
    taskPrevExam: ExamPrevDays
    boolToday: number
    cartasDiasPrevios: any[]
    playExam: (cartasDiasPrevios: any[]) => void
}

export default function ExamPrevCard ({taskPrevExam, boolToday, cartasDiasPrevios, playExam}:ExamPrevCardProps) {

    const isDone = boolToday != 1 && taskPrevExam.num_cards == taskPrevExam.num_cartas_estudiadas

    return (
        <div className="w-full lg:w-[38vw] h-auto rounded-[var(--border-radius)] bg-[var(--color-background)] flex font-medium items-start overflow-hidden m-[10px]">

            {(boolToday!=0 || isDone)&& <div style={{marginLeft:'12px'}}></div>}
            {!isDone?(<Icon iconName='circleBlank' size='20' isClickable={false} />):(<div style={{color:'var(--color-secondary', marginRight:'10px'}}>
                <Icon iconName='checkCircle' size='20' isClickable={false} /></div>)}
            
            {boolToday==0 ? <div
                style={{
                    color: 'var(--color-text-light)',
                    cursor: 'pointer',
                    marginLeft: '3px',
                }}
                onMouseEnter={(e) => {
                    if (!isDone) {
                        e.currentTarget.style.color = 'var(--color-purple)';
                    }
                }}
                onMouseLeave={(e) => {
                    if (!isDone) {
                        e.currentTarget.style.color = 'var(--color-text-light)';
                    }
                }}
                >
                {!isDone && (
                    <Icon
                        iconName='circlePlay'
                        size='20'
                        onClick={() => {
                            if (!isDone) {
                                playExam(cartasDiasPrevios);
                            }
                        }}
                        isClickable={!isDone}
                    />
                )}
                </div>:
                <div style={{marginRight:'11px'}}></div>
            }
            <div style={{
                textDecoration: isDone ? 'line-through' : 'none',
                textDecorationThickness: '1.2px',
                marginLeft: '10px',
                marginTop: '-3px', 
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {boolToday !== 1 ? (
                    <>{taskPrevExam.num_cartas_estudiadas}/{taskPrevExam.num_cards} Tarjetas - Repaso de examen de {taskPrevExam.exam_name}</>
                ):(<>{taskPrevExam.num_cards} Tarjetas - Repaso de examen de {taskPrevExam.exam_name}</>)}
            </div>
        </div>
    )
}
