/**
 * Verifica si el formato de un nombre es válido.
 * Para que sea válido, el nombre debe contener entre 1 y 20 caracteres, y solo puede contener
 * letras minúsculas, mayúsculas, los caracteres; 'ñ', las vocales acentuadas y espacios.
 * @param name - El nombre a validar.
 * @returns Devuelve true si el formato del nombre es válido, de lo contrario, devuelve el error.
 */
export const checkNameFormat = (name: string): true | string => {
	const regex = /^[a-zA-ZñáéíóúÁÉÍÓÚ\s]{1,20}$/
	if (regex.test(name))
		return true
	else
		return 'Contiene caracteres inválidos'
}

/**
 * Verifica si el formato de una fecha de nacimiento es válido.
 * Para que sea válido, la fecha de nacimiento debe tener el formato dd/mm/aaaa ser posterior a 04/03/1907 
 * y anterior a la fecha actual.
 * @param birthday - La fecha de nacimiento a validar.
 * @returns Devuelve true si el formato de la fecha de nacimiento es válido, de lo contrario, devuelve el error.
 */
export const checkBirthdayFormat = (birthday: string): true | string => {
	const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/
	if (regex.test(birthday)) {
		const [day, month, year] = birthday.split("/");
		const birthdayDate = new Date(`${year}-${month}-${day}`)
		const minDate = new Date('1907-03-04') // Nacimiento de Maria Branyas Morera
		const maxDate = new Date()
		maxDate.setFullYear(maxDate.getFullYear() - 12) // El usuario debe tener al menos 14 años
		if (birthdayDate >= minDate && birthdayDate < maxDate)
			return true
		else 
			return 'Fecha invalida'
	} else
		return 'Formato invalido'
}

/**
 * Verifica si el formato del nombre de usuario es válido.
 * Para que sea válido, el nombre de usuario debe contener entre 1 y 20 caracteres, y solo puede contener
 * letras minúsculas, mayúsculas, números, los caracteres; '.' y '_'.
 * @param username - El nombre de usuario a validar.
 * @returns Devuelve true si el formato del nombre de usuario es válido, de lo contrario, devuelve el error.
 */
export const checkUsernameFormat = (username: string): true | string => {
	const regex = /^[a-zA-Z0-9._]{1,20}$/
	if(regex.test(username))
		return true
	else
		return 'Contiene caracteres inválidos'
}

/**
 * Verifica si el formato del correo electrónico es válido.
 * Para que sea válido, el correo electrónico debe contener entre 1 y 50 caracteres, y deve seguir el formato:
 * letras minúsculas, mayúsculas, números y el caracter '.'; seguido de un '@'; seguido de letras minúsculas,
 * letras mayúsculas y números; seguido de un '.'; seguido de letras minúsculas.
 * @param email - El correo electrónico a validar.
 * @returns Devuelve true si el formato del correo electrónico es válido, de lo contrario, devuelve el error.
 */
export const checkEmailFormat = (email: string): true | string => {
	const regex = /^[a-zA-Z0-9.]+@+[a-zA-Z0-9.-]+\.[a-zA-Z]{1,50}$/
	if (regex.test(email))
		return true
	else
		return 'El formato del correo electrónico no es correcto'
}

/**
 * Verifica si el formato de la contraseña es válido.
 * Para que sea válido, la contraseña debe contener entre 6 y 30 caracteres, y solo puede contener
 * letras minúsculas, mayúsculas, números y los caracteres; '!', '"', '#', '$', '%', '&', ''', '(', ')', '*',
 * '+', ',', '-', '.', '/', ';', '<', '=', '>', '@', '[', '\', ']', '^', '_', '`', '{', '|', '}', '~'.
 * @param password - La contraseña a validar.
 * @returns Devuelve true si el formato de la contraseña es válido, de lo contrario, devuelve el error.
 */
export const checkPasswordFormat = (password: string): true | string => {
	const regex = /^[a-zA-Z0-9!"#$%&'()*+,-./;<=>@[\]^_`{|}~]{6,30}$/
	if (password.length < 6)
		return 'La contraseña debe contener mas de 6 caracteres'
	else if (password.length > 30)
		return 'La contraseña no puede contener mas de 30 caracteres'
	else if (regex.test(password))
		return true
	else
		return 'Contiene caracteres inválidos'
}

/**
 * Verifica si el formato de un nombre de usuario es valido y si no esta ya registrado.
 * @param username - el nombre de usuario a comprobar
 * @returns true si el formato es valido y el nombre de usuario no esta registrado, 
 * de lo contrario devuelve un string con el error.
 */
export const checkNewUsername = (username: string): Promise<true | string> => {
	const usernameQuery = async () => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/user/exists_username?username=${encodeURIComponent(username)}`, 
				{
					method: 'GET',
					headers: { 'Content-Type': 'application/json', },
				}
			)
			if (!response.ok) {
				throw new Error('Error al comprobar usuario')	
			}		
			const data = await response.json()
			if (data.exists == true) {
				return 'Este nombre de usuario ya esta registrado'
			} else {
				return true
			}
		} catch (error) {
			console.error('Error:', error)
			return 'Nombre de usuario no valido'
		}
	}
	const usernameFormatCheck = checkUsernameFormat(username)
	if (usernameFormatCheck !== true) {
		return Promise.resolve(usernameFormatCheck)
	} else {
		return usernameQuery()
	}
}

/**
 * Verifica si el formato de un email es valido y si no esta ya registrado.
 * @param email - email a comprobar
 * @returns true si el formato es valido y el email no esta registrado, 
 * de lo contrario devuelve un string con el error.
 */
export const checkNewEmail = (email: string): Promise<true | string> => {
	const emailQuery = async () => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/user/exists_email?email=${encodeURIComponent(email)}`, 
				{
					method: 'GET',
					headers: { 'Content-Type': 'application/json', },
				}
			)
			if (!response.ok) {
				throw new Error('Error al comprobar email')
			}		
			const data = await response.json()
			if (data.exists == true) {
				return 'Este email ya esta registrado'
			} else {
				return true
			}
		} catch (error) {
			console.error('Error:', error)
			return 'Email no valido'
		}
	}
	const emailFormatCheck = checkEmailFormat(email)
	if (emailFormatCheck !== true) {
		return Promise.resolve(emailFormatCheck)
	} else {
		return emailQuery()
	}
}