import '@/styles/Spinner.css';

interface SpinnerProps {
  fillAll?: boolean
  size?: string
}

export default function Spinner({fillAll=true, size='35px'}: SpinnerProps) {

  const borderWidth: string = (parseInt(size)/5)+'px solid var(--color-background)'

  return (
    <div className={fillAll ? 'spinner-container' : 'spinner-reduce-container'}>
      <div className='spinner' style={{
        width: size, 
        height: size, 
        border: borderWidth, 
        borderLeftColor: 'var(--color-secondary)'
      }}></div>
    </div>
  )
}