import { useRef, useEffect, useCallback } from 'react'

/** 
 * Custom Hook que permite crear una versión "debounced" de una función de callback que devuelva una promesa.
 * Es decir, crea una función que establece un delay por cada llamada recibida, ejecutandose tan solo
 * la última llamada recibida a la conclusión del delay, limitando así el número de peticiones.
 * @param callback - La función asincrona de callback que se quiere retrasar.
 * @param delay - El número de milisegundos de espera antes de ejecutar el callback.
 * @returns {Promise<any>} Una versión "debounced" de la función de callback proporcionada.
 */
export function useAsyncDebouncedCallback(callback: (...args: any) => Promise<any>, delay: number): (...args: any) => Promise<any> {

    const callbackRef = useRef(callback)
    const timeoutRef = useRef<number>()

    // Se actualizar la referencia del callback si este cambia.
    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    /**
     * Versión debounced de la función callback
     * Esta función esta además memoizada mediante useCallback,
     * de forma que se reuse la misma instancia mientras delay no cambie
     */
    const debouncedCallback = useCallback(async (...args: any) => {
        const callback = callbackRef.current
        // Limpiar el timeout anterior si existe
        timeoutRef.current && clearTimeout(timeoutRef.current)
        // Crear un nuevo timeout que llama al callback después del delay
        return new Promise<any>((resolve, reject) => {
          timeoutRef.current = setTimeout(async () => {
            try {
              const result = await callback(...args)
              resolve(result)
            } catch (error) {
              reject(error)
            }
          }, delay)
        })
    }, [delay])

    return debouncedCallback
}