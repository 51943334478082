import Examenes from "@/components/Examenes";
import OrganizacionMenu from "@/components/OrganizacionMenu";
import PomodoroPage from "@/components/PomodoroPage";
import Study from "@/components/pages/study/Study";
import TareasPendientesPage from "@/components/TareasPendientesPage";
import DefaultLayout from "@/components/layout/DefaultLayout";
import { Card } from "@/types/cards";
import { Exam } from "@/types/types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Organizacion() {

   const location = useLocation();
   const { pagina } = location.state || {}; 

	const [selectedButton, setSelectedButton] = useState<number>(pagina?parseInt(pagina):1);
   const [tiempoPomodoro, setTiempoPomodoro] = useState(25);
   const [tiempoDescanso, setTiempoDescanso] = useState(5);
   const [inExam, setInExam] = useState<boolean>(false)
   const [cardsExam, setCardsExam] = useState<Card[] | null>(null)
   const [modoExam, setModoExam] = useState<number | null>(null)

	
	return (
		<DefaultLayout>
         {inExam? (
            <Study cartas={cardsExam} modo={modoExam} 
            examen={null} time={null}
            onClickReturn={()=>setInExam(false)}/>
         ):(
         <>
            <OrganizacionMenu 
               buttonChange={(selectedButton: number) => setSelectedButton(selectedButton)}
               tiempoPomodoro={(tiempoPomodoro: number) => setTiempoPomodoro(tiempoPomodoro)}
               tiempoDescanso={(tiempoDescanso: number) => setTiempoDescanso(tiempoDescanso)}
            />
            {(selectedButton==1) && <TareasPendientesPage estudio={(tarjetas,modo) => {setCardsExam(tarjetas);setModoExam(modo);setInExam(true)}}/>}
            {(selectedButton==2) && <PomodoroPage tiempoPomodoro={tiempoPomodoro} tiempoDescanso={tiempoDescanso} />}
         </>
         )}
        
      </DefaultLayout>
	)
}
