import { useRef, useEffect, useCallback } from 'react'

/** 
 * Custom Hook que permite crear una versión "debounced" de una función de callback.
 * Es decir, crea una función que establece un delay por cada llamada recibida, ejecutandose tan solo
 * la última llamada recibida a la conclusión del delay, limitando así el número de peticiones.
 * @param callback - La función de callback que se quiere retrasar.
 * @param delay - El número de milisegundos de espera antes de ejecutar el callback.
 * @returns {Function} Una versión "debounced" de la función de callback proporcionada.
 */
export function useDebouncedCallback(callback: (...args: any[]) => void, delay: number): Function {

    const callbackRef = useRef(callback)
    const timeoutRef = useRef<number>()

    // Se actualizar la referencia del callback si este cambia.
    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    /**
     * Versión debounced de la función callback
     * Esta función esta además memoizada mediante useCallback,
     * de forma que se reuse la misma instancia mientras delay no cambie
     */
    const debouncedCallback = useCallback((...args: any[]) => {
        const callback = callbackRef.current
        // Limpiar el timeout anterior si existe
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        // Crear un nuevo timeout que llama al callback después del delay
        timeoutRef.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, [delay])

    return debouncedCallback
}