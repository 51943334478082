import { useEffect, useRef, useState } from 'react'
import { useAuth } from '@/context/auth/useAuth'
import useWindowSize from '@/hooks/useWindowSize'
import DashboardLayout from '@/components/layout/DashboardLayout'
import landingImage from '../assets/landingImage.svg'
import '../styles/ContactForm.css'

export default function Contacto () {
  const [message, setMessage] = useState<string>('')
	const [submitted, setSubmitted] = useState(false)
	const [statusMessage, setStatusMessage] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [mail, setMail] = useState('')

  const { width } = useWindowSize()
  const auth = useAuth()

  useEffect(() => {
    if (!auth.loading && auth.isAuth) {
      const getUsername = async () => {
        try{
          const response = await fetch (
            `${import.meta.env.VITE_API_URL}/user/data`, 
            {
              method: 'GET',
              headers: { 'Content-Type': 'application/json', },
              credentials: 'include',
            }
          )
          if(!response.ok) {
            throw new Error('Error al obtener los datos del usuario')
          }
          const data = await response.json()
          setMail(data.correo)
          setName(data.nombre)
          setSurname(data.apellidos)
        } catch(error) {
          console.log(error)
        }
      }
      getUsername()
    }
  }, [auth.loading])

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false)
				setStatusMessage('')
			}, 5000)
		}
	}, [submitted])

	const submitMessage = async (event: React.FormEvent) => {
		event.preventDefault()
		if (message !== undefined) {
			let statesToCheck = [message]
			const results: boolean[] = []
			statesToCheck.forEach((state) => results.push(state == '' ? false : true))
			if (results.every((result) => result == true)) {
				try {
					const response = await fetch(
						`${import.meta.env.VITE_API_URL}/feedback/store`, 
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({ 'nombre': name, 'apellidos': surname, 'email': mail, 'message': message }),
						}
					)
					if (!response.ok)
						throw new Error('Error en el envio del mensaje')
          setSubmitted(true)
          setMessage('')
          setStatusMessage('¡Recibido! Gracias por tu mensaje')
        } catch (error) {
          console.error('Error:', error)
          setSubmitted(true)
          setMessage('')
          setStatusMessage('Error en el envio del mensaje')
				}
			} else {
				setStatusMessage('Error en el envio del mensaje')
			}
		}
	}
    
  return (
    <DashboardLayout>
      <div className={`flex items-center justify-around h-full px-10 ${width < 1000 ? 'flex-col' : 'flex-row'}`}>
        <div className={`max-w-xl flex flex-col items-start justify-center p-8 ${width > 1000 ? 'w-3/5': width > 640 ? 'w-full': 'w-auto'}`}>
          <h2 className='text-[var(--color-primary)]'>Contáctanos</h2>
          <h3>Problemas, sugerencias, feedback...<br/> ¡Estamos aquí para ayudarte!</h3>
          <textarea
            ref={textareaRef}
            className='h-48 border-2 text-[var(--color-text)] p-2 my-4'
            placeholder="Deja tu mensaje aquí..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className={`w-full flex flex-col ${width > 1000 ? 'items-start': 'items-center'}`}>
            <button 
              className='w-32'
              disabled={message === ''}
              onClick={(e) => submitMessage(e)}
            >
              Enviar
            </button>
            <p className={`h-5 ${submitted ? 'text-[var(--secondary-color)]' : 'text-[var(--error-color)]'}`}>
              {statusMessage}
            </p>
          </div>
        </div>
        { width > 1000 &&
          <div className='max-w-xl w-2/5 flex items-center justify-center m-5'>
            <img className='landing-img' src={landingImage} />
          </div>
        }
      </div>
    </DashboardLayout>
  )
}