import { useEffect, useRef, useState } from 'react'
import FormInput from '@/components/common/ui/FormInput'
import FormInputRow from '@/components/common/ui/FormInputRow'
import { Datalist } from '@/components/common/ui/Datalist'
import DashboardLayout from '@/components/layout/DashboardLayout'
import Spinner from '@/components/common/ui/Spinner'
import { GoBackArrow } from '@/components/common/ui/GoBackArrow'
import { PasswordCheckInputs } from '@/components/common/ui/PasswordCheckInputs'
import { useAuth } from '@/context/auth/useAuth'
import * as validaciones from '@/utils/validaciones'
import { UserData } from '@/types/types'
import '../styles/Settings.css'
import '../styles/ContactForm.css'
import { useNavigate } from 'react-router-dom'

export default function Settings() {
	const navigate = useNavigate()
	const auth = useAuth()
	const loading = auth?.loading
	const authenticated = auth?.isAuth

	const infoLabelRef = useRef<HTMLLabelElement>(null)
	const [onUpdateError, setOnUpdateError] = useState(false)
	const [user, setUser] = useState<UserData | null>(null)
	
	const fetchData = async() => {
		if (authenticated) {
			try{
				const response = await fetch(
					`${import.meta.env.VITE_API_URL}/user/data`, 
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
					}
				)
				if(!response.ok){
					throw new Error('Error al obtener los datos del usuario')
				}
				const data = await response.json()
				setUser(data)
				setTipoEstudios(data.tipo_estudios!)

			} catch(error){
				console.error('Error:', error)
			}
		}
	}

	useEffect(() => {
		fetchData()
	}, [authenticated])

	useEffect(() => {
		if (infoLabelRef.current) {
			if (onUpdateError)
				infoLabelRef.current.style.color = 'var(--color-error)'
			else 
				infoLabelRef.current.style.color = 'var(--color-text-light)'
		}
	}, [onUpdateError])

	const [existsChanges, setExistsChanges] = useState(false)
	const [onDeleteUser, setOnDeleteUser] = useState(false)
	const [deleteUserPassword, setDeleteUserPassword] = useState('')

	const [actualPassword, setActualPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [tipoEstudios, setTipoEstudios] = useState('')
	const [changeStates, setChangeStates] = useState({
		name: '', surname: '', username: '', location: '', titulacion: '', centroEstudios: ''
	})

	const deleteUser = async () => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/auth/delete`, 
				{
					method: 'DELETE',
					headers: {
					'Content-Type': 'application/json',
					},
					credentials: 'include',
			  }
			)
			if (!response.ok){
			  throw new Error('Error al eliminar cuenta');
			}		
			auth.fetchAuthState()
			localStorage.clear()
			navigate('/')
		} catch (error) {
			console.error('Error:', error);
		}
	}

	const handleInputChange = (input: string, userAttribute: keyof UserData, stateKey: keyof typeof changeStates) => {
		if (input != user?.[userAttribute] && input != '') 	{
			setChangeStates(prev => ({ ...prev, [stateKey]: input }))
			setExistsChanges(true)
			infoLabelRef.current!.innerText = 'Existen cambios sin guardar'
			setOnUpdateError(false)
		}
		else  {
			setChangeStates(prev => ({ ...prev, [stateKey]: '' }))
			if ((Object.keys(changeStates).every(key => key === stateKey || changeStates[key as keyof typeof changeStates] === '')) && tipoEstudios == user?.tipo_estudios!){
				setExistsChanges(false)
				infoLabelRef.current!.innerText = 'Aun no se han realizado cambios'
			}
		}
	}

	const handleTipoEstudios = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value != user?.tipo_estudios) {
			setTipoEstudios(e.target.value)
			setExistsChanges(true)
			infoLabelRef.current!.innerText = 'Existen cambios sin guardar'
			setOnUpdateError(false)
		}
		else if (Object.keys(changeStates).every(key => changeStates[key as keyof typeof changeStates] === '')) {
			setTipoEstudios(e.target.value)
			setExistsChanges(false)
			infoLabelRef.current!.innerText = 'Aun no se han realizado cambios'
		}
	}

	const handleActualPassword = (input: string) => {
		if (input != '') {
			setActualPassword(input)
			if (newPassword != '') {
				setExistsChanges(true)
				infoLabelRef.current!.innerText = 'Existen cambios sin guardar'
				setOnUpdateError(false)
			}
		} else if ((Object.keys(changeStates).every(key => changeStates[key as keyof typeof changeStates] === '')) && tipoEstudios == user?.tipo_estudios!){
			setExistsChanges(false)
			infoLabelRef.current!.innerText = 'Aun no se han realizado cambios'
		}
	}

	const handleNewPassword = (input: string) => {
		// TODO comprobar que no sea la misma que la actual? o no?
		if (input != '') 	{
			setNewPassword(input)
			if (actualPassword != '') {
				setExistsChanges(true)
				infoLabelRef.current!.innerText = 'Existen cambios sin guardar'
				setOnUpdateError(false)
			}
		}
		else if ((Object.keys(changeStates).every(key => changeStates[key as keyof typeof changeStates] === '')) && tipoEstudios == user?.tipo_estudios!){
			setExistsChanges(false)
			infoLabelRef.current!.innerText = 'Aun no se han realizado cambios'
		}
	}

	const saveChanges = async () => {
		if (user && existsChanges) {
			let usernameExists = false
			// REVISAR ESTO
			// ersto primero se ejecuta si lo que se cambia es el username
			if (changeStates.username != user.usuario && changeStates.username != '') {
				try {
					const response = await fetch(
						`${import.meta.env.VITE_API_URL}/user/exists_username?username=${changeStates.username}`, 
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
							},
							credentials: 'include',
						}
					)
					if (!response.ok){
						throw new Error('Error al eliminar mazo');
					}			
					const data = await response.json()
					if(data.exists == true) {
						infoLabelRef.current!.innerText = 'El nombre de usuario ya está en uso'
						usernameExists = true
						setOnUpdateError(true)
					}
				} catch (error) {
					setOnUpdateError(true)
					infoLabelRef.current!.innerText = 'Ha ocurrido un error al guardar los cambios'
				}
			}
			if(!usernameExists) {
				try {
					if (Object.keys(changeStates).some(key => changeStates[key as keyof typeof changeStates] != '' && key != 'email' )) {
						const updatedUser = {
							created_at: user.created_at,
							objetivo: user.objetivo,
							nacimiento: user.nacimiento,
							correo: user.correo,
							// campos con posibles cambios:
							nombre: changeStates.name,
							apellidos: changeStates.surname,
							usuario: changeStates.username,
							ubicacion: changeStates.location,
							tipo_estudios: tipoEstudios,
							titulacion: changeStates.titulacion,
							centro_estudios: changeStates.centroEstudios,
						}
						const response = await fetch(
							`${import.meta.env.VITE_API_URL}/user/update`, 
							{
								method: 'POST',
								headers: { 'Content-Type': 'application/json', },
								body: JSON.stringify(updatedUser),
								credentials: 'include',
							}
						)
						if (!response.ok){
							throw new Error('Error en la actualización de los datos del usuario');
						}
					}
					if (newPassword != '' && actualPassword != ''){
						const response = await fetch(
							`${import.meta.env.VITE_API_URL}/auth/update_password`,
							{
								method: 'POST',
								headers: {
								'Content-Type': 'application/json',
								},
								body: JSON.stringify({ email:user.correo, oldPassword:actualPassword, newPassword:newPassword }),
								credentials: 'include',
							}
						)
						if (!response.ok){
							throw new Error('Error en la actualización de la contraseña del usuario');
						}
					}
					await fetchData()
					setExistsChanges(false)
					setChangeStates({ name: '', surname: '', username: '', location: '', titulacion: '', centroEstudios: '' })
					infoLabelRef.current!.innerText = 'Cambios guardados correctamente'
					setOnUpdateError(false)
				}
				catch (error) {
					setOnUpdateError(true)
					infoLabelRef.current!.innerText = 'Ha ocurrido un error al guardar los cambios'
				}
			}
		}
	}

	return (
		<DashboardLayout>
			{!loading && user ? 
				<div className='Settings'>
					<div className='settings-title'>
						<GoBackArrow onClick={() => navigate('/dashboard')} />
						<h2>Configuración de la Cuenta</h2>
					</div>
					<hr/>
					<div className='settings_layout'>
						<div className='left'>
							<h3>Perfil</h3>
							<FormInputRow>
								<FormInput type='text' name='name' defaultValue={user.nombre} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'nombre', 'name')}>
									Nombre:
								</FormInput>
								<FormInput type='text' name='surname' defaultValue={user.apellidos} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'apellidos', 'surname')}>
									Apellidos:
								</FormInput>
							</FormInputRow>
							<FormInput type='text' name='username'  defaultValue={user.usuario} constraint={validaciones.checkUsernameFormat} getInput={(input) => handleInputChange(input, 'usuario', 'username')}>
								Nombre de Usuario:
							</FormInput>
						</div>
						<div className='right'>
							<h3>Datos Académicos</h3>
							<FormInputRow>
								<Datalist id='localidadesDataList' defaultValue={user.ubicacion} dataOp='poblaciones' getSelected={(input) => handleInputChange(input, 'ubicacion', 'location')}>
									¿De donde eres? *
								</Datalist>
								<div className='input-container'>
									<label>¿Qué estudias?</label>
									<select name='tipo estudios' defaultValue={user.tipo_estudios!} onChange={handleTipoEstudios}>
										<option value='grado'>Estudiante de grado</option>
										<option value='oposicion'>Opositor</option>
										<option value='fp'>FP / Bachillerato</option>
										<option value='otros'>Otros</option>
									</select>
								</div>
							</FormInputRow>
							{tipoEstudios == 'grado' ? (
								<>
									<Datalist id='gradosUniversitariosDataList' defaultValue={user.titulacion} dataOp='gradosUniversitarios' getSelected={(input) => handleInputChange(input, 'titulacion', 'titulacion')}>
										¿Que grado? *
									</Datalist>
									<Datalist id='universidadesDataList' defaultValue={user.centro_estudios} dataOp='universidades' getSelected={(input) => handleInputChange(input, 'centro_estudios', 'centroEstudios')}>
										¿En qué universidad? *
									</Datalist>
								</>
							) : tipoEstudios == 'oposicion' ? (
								<>
									<FormInput type='text' name='oposicion' defaultValue={user.titulacion} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'titulacion', 'titulacion')}>
										¿Que oposición? *
									</FormInput>
									<FormInput type='text' name='academia' defaultValue={user.centro_estudios} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'centro_estudios', 'centroEstudios')}>
										¿Tienes academia? (Nombre) *
									</FormInput>
								</>
							) : tipoEstudios == 'fp' ? (
								<>
									<FormInput type='text' name='modalidad' defaultValue={user.titulacion} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'titulacion', 'titulacion')}>
										¿Modalidad? (Ciencias, FP de Automoción...): *
									</FormInput>
									<FormInput type='text' name='instituto' defaultValue={user.centro_estudios} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'centro_estudios', 'centroEstudios')}>
										¿En qué instituto? *
									</FormInput>
								</>
							) : tipoEstudios == 'otros' ? (
								<>
									<FormInput type='text' name='estudios' defaultValue={user.titulacion} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'titulacion', 'titulacion')}>
										¿Puedes especificar tus estudios? *
									</FormInput>
									<FormInput type='text' name='centro' defaultValue={user.centro_estudios} constraint={validaciones.checkNameFormat} getInput={(input) => handleInputChange(input, 'centro_estudios', 'centroEstudios')}>
										¿En qué universidad?/Otros *
									</FormInput>
								</>
							) : null}
						</div>
						<div className='left'>
							<h3>Cambiar Contraseña</h3>
							<FormInputRow>
								<FormInput type='password' name='actualpassword' constraint={validaciones.checkPasswordFormat} getInput={handleActualPassword}>
									Contraseña Actual:
								</FormInput>
								<div className='w-full'></div>
							</FormInputRow>
							<PasswordCheckInputs getPassword={handleNewPassword} />
						</div>
						<div className='right'>
							<h3>Otros</h3>
							<div className='deleteUser-container'>
								{ !onDeleteUser ?
									<button onClick={() => setOnDeleteUser(true)} className='delete_button'>Eliminar Cuenta</button>
								:
									/* TODO meter el componente de delete user*/
									<div className='deleteUser'>
										<div className='divided-row'>
											<FormInput type='password' name='confirmpassword' constraint={validaciones.checkPasswordFormat} getInput={(input) => {input != '' && setDeleteUserPassword(input)}}>
												Contraseña Actual:
											</FormInput>
											<div style={{ 'width': '100%' }}></div>
										</div>
										<p>¿Estás seguro de que quieres eliminar tu cuenta? Perderas todas tus flashcards</p>
										<button onClick={() => deleteUser()} className={deleteUserPassword != '' ? 'delete_button' : 'delete_button-grey'}>Sí, eliminar</button>
										<button onClick={() => setOnDeleteUser(false)} className='cancel_button'>Cancelar</button>
									</div>
								}
							</div>
						</div>
					</div>
					<div className='settings_footer'>
						<label ref={infoLabelRef}>Aun no se han realizado cambios</label>
						<button className={existsChanges ? undefined : 'noChanges_button'} onClick={saveChanges}>Guardar Cambios</button>
					</div>
				</div>
			: <Spinner />
			}
		</DashboardLayout>
	)
}