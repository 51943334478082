import { useEffect, useState } from 'react'
import FormInput from '@/components/common/ui/FormInput'
import FormInputRow from '@/components/common/ui/FormInputRow'
import * as validaciones from '@/utils/validaciones'

/**
 * @prop getPassword - Devuelve la contraseña en caso de ser correcta y coincidir 
 * con la confirmación, en caso contrario devuelve un string vacío ('').
 */
interface PasswordCheckInputsProps {
	getPassword: (value: string) => void
}

export type PasswordCheckInputsRef = {
  handleInvalidPassword: () => void;
}

export const PasswordCheckInputs = ({ getPassword }: PasswordCheckInputsProps) => {

	/*
	 * passwordCheck guarda la confirmación de la contraseña, al guardar este valor
	 * se cambia lateChange a true, lo cual permite reevaluar el input con la contraseña
	 */
	const [passwordCheck, setPasswordCheck] = useState('')
	const [lateChange, setLateChange] = useState(false)
	
	// Una vez efectuado el cambio de lateChange a true, este debe volver a false
	useEffect(() => {
		if (lateChange) {
			setLateChange(false)
		}
	}, [lateChange])

	const checkPassword = (input: string): true | string => {
		const formatCheck = validaciones.checkPasswordFormat(input)
		if (formatCheck !== true) {
			getPassword('')	
			return formatCheck
		}
		else if (input !== '' && input !== passwordCheck) {
			getPassword('')	
			if (passwordCheck !== '') return 'Las contraseñas no coinciden'
		}
		return true	
	}

	const handleSetPasswordCheck = (input: string) => {
		setPasswordCheck(input)
		setLateChange(true)
	}

	const handleGetPassword = (input: string) => {
		if (input != '' && input == passwordCheck)
			getPassword(input)
		else 
			getPassword('')
	}

	return (
		<FormInputRow>
			<FormInput type='password' name='newpassword' constraint={checkPassword} getInput={handleGetPassword} reapprise={lateChange}>
			  Contraseña:
			</FormInput>
			<FormInput type='password' name='passwordcheck' getInput={handleSetPasswordCheck}>
				Repetir Contraseña:
			</FormInput>
		</FormInputRow>
	)
}
