import { useEffect, useState } from 'react'
import Popup from './common/ui/Popup'
import FormInput from './common/ui/FormInput'

interface BuyTokensPopupProps {
    open: boolean
    onClose: () => void
    onBuy: (tokens: number) => void
}

const MIN_TOKENS = 50000 // (Minimo 50000 tokens)
const PRICE_PER_TOKEN = 0.002 // Precio por token en centimos(1 token = 0.002cents)

export default function BuyTokensPopup({open, onClose, onBuy}: BuyTokensPopupProps) {

  // Cantidad de tokens a comprar
  const [tokens, setTokens] = useState<number>(MIN_TOKENS)
  // Precio de los tokens en centimos (Minimo 1€, el coste de 40 tokens)
  const [price, setPrice] = useState<number>(100)
  // Precio de los tokens en centimos
  const [invalidInput, setInvalidinput] = useState<boolean>(false)
  // Conversion de tokens a centimos
  useEffect(() => setPrice(!isNaN(tokens) ? tokens * PRICE_PER_TOKEN : 0), [tokens])

  // Funcion de validacion del formato de los tokens
  const tokensConstraint = (input: string): true | string => {
    if (input == '') {
      setInvalidinput(false)
      return true
    }
    const inputInt = parseInt(input)
    if (inputInt < MIN_TOKENS) {
      setInvalidinput(true)
      return 'Invalido'
    }
    setInvalidinput(false)
    return true
  }

  return (
      <Popup open={open} onClose={onClose} width='500px' height='500px'>
        <div className='w-full h-[95%] flex flex-col items-center justify-between'>
          <h2 className='text-[--color-primary]'>Compra de Tokens</h2>
          <div className='m-3 h-[120px] flex flex-col justify-between text-sm'>
            <h4>Los tokens se pueden canjear de manera directa por importaciones mediante nuestra Inteligencia Artificial.</h4>
            <h4>(800 tokens equivalen más o menos a una página del temario)</h4>
            <h4>Recordamos que las suscripciones son más económica.</h4>
          </div>
          <div className='w-1/2 mb-10'>
            { invalidInput &&
              <p className='absolute -mt-1 text-[var(--color-error)]'>
                La compra minima es de 1€ (50.000 tokens)
              </p>
            }
            <FormInput 
              type='number' 
              name='Cantidad de Tokens' 
              defaultValue={MIN_TOKENS.toString()}
              getInput={(t) => setTokens(parseInt(t))} 
              constraint={tokensConstraint}
            >
              Número de Tokens
            </FormInput>
            <div className='mt-3 flex flex-col items-start'>
              <h4 className='font-normal'>Precio:</h4>
              <h2 className='font-normal'>{((price/100).toFixed(2))}€</h2>
            </div>
          </div>
          <button 
            className={invalidInput ? 'w-1/2 bg-[var(--color-text-light)] hover:bg-[var(--color-text-light)] hover: border-[var(--color-text-light)] cursor-default' : 'w-1/2'}
            onClick={() => {onClose; onBuy(tokens)}} 
            disabled={invalidInput}
          >
            Comprar Tokens
          </button>
        </div> 
      </Popup >
  )
}
