import { useState, useEffect } from 'react'
import { useEditors } from '@/context/EditorsContext'
import FlashCardEditor, { FlashCardInit } from './FlashCardEditor'
import OcultarCardEditor, { OcultarCardInit } from './OcultarCardEditor'
import TestCardEditor, { TestCardInit } from './TestCardEditor'
import Toolkit from './Toolkit'
import { GoBackArrow } from '../../common/ui/GoBackArrow'
import SelectButton from '../../common/ui/SelectButton'
import Icon from '@/components/icons/Icon'
import { Folder, Mazo } from '../../../types/types'
import { Card, CardType, FlashCard, OcultarCard, TestCard } from '../../../types/cards'
import { Editor } from '@tiptap/react'
import Latex from 'react-latex-next'
import 'katex/dist/katex.min.css'
import './FlashcardEditor.css'

interface CardEditorProps {
	mazo: Mazo | null
	onClickReturn: () => void
	folder: Folder | null
	cartaEditar?: Card
	returnToStudy?: (editCard: CardInit ) => void
}

// El tipo de carta inicial del editor, puediendo ser una ya existente o cualquier tipo inicial
type CardInit = Card | FlashCardInit | OcultarCardInit | TestCardInit
// El editor puede estar en modo crear o en modo editar
type EditorMode = 'create' | 'edit'
// El editor puede estar en modo crear o en modo editar
type OperationState = 'waiting' | 'send' | 'completed' | 'error'

// Tiempo que se muestra el mensaje 'toast' de guardado o error
const TOAST_TIMEOUT = 3000

export const CardEditor = ({ mazo, onClickReturn, folder, cartaEditar, returnToStudy }: CardEditorProps) => {


	const editorMode: EditorMode = cartaEditar ? 'edit': 'create'
	
	const [cardType, setCardType] = useState<CardType>(cartaEditar ? cartaEditar.type : 'flashcard') // Por defecto al crear una nueva carta esta sera de tipo flashcard
	const [operationState, setOperationState] = useState<OperationState>('waiting')
	const [editCard, setEditCard] = useState<CardInit | undefined>(cartaEditar ? cartaEditar : undefined)
	const [clean, setClean] = useState<boolean>(false)

	// CONTEXTO DE OBTENCION DEL EDITOR ACTUAL
	const { getSelectedEditor, selectedEditorId } = useEditors()
  	const [editor, setEditor] = useState<Editor | null>(null)

	const [perteneceAExamen, setPerteneceAExamen] = useState(0) //1 si pertenece y 2 si no,si esta a 0 todavia no ha llegado la consulta y no se puede guardar la carta
	const [fechaFutura, setFechaFutura] = useState('')

	useEffect(() => {
		setEditor(getSelectedEditor())
	}, [selectedEditorId])

    useEffect(() => {
        const obtenerExamenes = async () => {
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/exam/get_from_user`, 
                    {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                    }
                )
		if(response.status === 401){
			setPerteneceAExamen(2)
			return
		}
                if (!response.ok) {
                    throw new Error('Error al obtener los examenes')
                } 
                let data=await response.json()
				
				const localDate = new Date();
				const localYear = localDate.getFullYear();
				const localMonth = String(localDate.getMonth() + 1).padStart(2, '0'); // Los meses son base 0
				const localDay = String(localDate.getDate()).padStart(2, '0');
				const today = `${localYear}-${localMonth}-${localDay}`;


				const cumpleCondiciones = data.some((examen: any) => {
					const fechaFin = examen.fecha_fin;
					const idsMazos = examen.id_mazos;
					return mazo && idsMazos.includes(mazo.id) && fechaFin > today;
				});

				if (cumpleCondiciones) {
					const tomorrowDate = new Date(localDate)
					tomorrowDate.setDate(tomorrowDate.getDate() + 1)
					const tomorrowYear = tomorrowDate.getFullYear()
					const tomorrowMonth = String(tomorrowDate.getMonth() + 1).padStart(2, '0')
					const tomorrowDay = String(tomorrowDate.getDate()).padStart(2, '0')
					const tomorrow = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`
					setPerteneceAExamen(1)
					setFechaFutura(tomorrow)
				} else {
					setPerteneceAExamen(2)
					setFechaFutura('')
				}			
                
            } catch (error) {
                console.error('Error:', error)
            }
        }
        obtenerExamenes()
    }, [])
	

	const storeFlashcard = async () => {
		if (!editCard || editCard == cartaEditar) {
			setOperationState('error')
			setTimeout(() => setOperationState('waiting'), TOAST_TIMEOUT)
			throw new Error('Debes completar todos los campos o realizar cambios en caso de edición')
		}
		setOperationState('send')
		let newCard = { ...editCard };
		if(cartaEditar && cartaEditar.type !== cardType){
			if(cardType === 'flashcard'){
				newCard = {
					pregunta: (editCard as FlashCard).pregunta,
					respuesta: (editCard as FlashCard).respuesta,
					type: cardType,
					id: cartaEditar.id
				}
			}else if(cardType === 'ocultar'){
				newCard = {
					text: (editCard as OcultarCard).text,
					type: cardType,
					id: cartaEditar.id
					 
				}
			}else if(cardType === 'test'){
				newCard = {
					pregunta: (editCard as TestCard).pregunta,
					respuestas: [(editCard as TestCard).respuestas[0], (editCard as TestCard).respuestas[1], (editCard as TestCard).respuestas[2], (editCard as TestCard).respuestas[3]],
					correcta: (editCard as TestCard).correcta,
					type: cardType,
					id: cartaEditar.id
				}
			}
		}
		
		const response = await fetch(
			`${import.meta.env.VITE_API_URL}/card/${editorMode}`,  
			{
				method: editorMode === 'create' ? 'POST': 'PATCH',
				headers: { 'Content-Type': 'application/json', },
				body: JSON.stringify({ 
					...newCard, 
					mazo_id: editorMode === 'create' && mazo?.id,
					type: cardType,
					perteneceAExamen: fechaFutura == ''? 'no': fechaFutura,
					oldType: cartaEditar ? cartaEditar.type : undefined
				}),
			}
		)
		if (!response.ok){
			setOperationState('error')
			setTimeout(() => setOperationState('waiting'), TOAST_TIMEOUT)
			console.error('Error:', response)
			throw new Error('Error al intentar guardar la carta en el servidor')
		}		
		setOperationState('completed')
		setEditCard(undefined)
		setClean(true)
		setTimeout(() => setOperationState('waiting'), TOAST_TIMEOUT)
		if (editorMode === 'edit'){
			if (returnToStudy && cartaEditar && editCard !== undefined) {
				let cardReturn = { ...cartaEditar }
				cardReturn.type = cardType
				if(cardType === 'flashcard'){
					cardReturn = {
						...cardReturn,
						pregunta: (editCard as FlashCard).pregunta,
						respuesta: (editCard as FlashCard).respuesta
					}
				}else if(cardType === 'ocultar'){
					cardReturn = {
						...cardReturn,
						text: (editCard as OcultarCard).text
					}
				}else if(cardType === 'test'){
					cardReturn = {
						...cardReturn,
						pregunta: (editCard as TestCard).pregunta,
						respuestas: [(editCard as TestCard).respuestas[0], (editCard as TestCard).respuestas[1], (editCard as TestCard).respuestas[2], (editCard as TestCard).respuestas[3]],
						correcta: (editCard as TestCard).correcta
					}
				}
				returnToStudy(cardReturn);
			  }
			onClickReturn()
		} 
	}

	const handleSetEditCard = (card: CardInit) => {
		if (clean) setClean(false)
		const values = Object.values(card)
		const emptyField = values.some(value => value === '')
		if (!emptyField){
			setEditCard(card)
		} else if (editCard){
			setEditCard(undefined)
		}
	}

	return (<>
		<div className='p-5'>
			<div className='flex flex-col lg:flex-row gap-3 justify-between items-center mb-4 md:mb-7'>
				<div>
					<div className=''>
						<GoBackArrow 
							title={(cartaEditar ? 'Editar' : 'Añadir') + ' Flashcard'}
							subtitle={folder && mazo ? `${folder.name}: ${mazo.name}` : 'Vista previa'} 
							onClick={() => onClickReturn()} 
						/>
					</div>
				</div>
				<div className='w-auto min-w-80 h-18 md:h-12 p-2 md:p-0 flex flex-col md:flex-row gap-3 items-center bg-[var(--color-background)] rounded-lg px-2'>
					<div className='flex flex-row'>
						<h4 className='ml-3 mr-3'>Tipo:</h4>
						<SelectButton 
							className='w-[150px]' 
							name='tipo'
							defaultValue={cardType}
							getValue={(value: string) => setCardType(value as CardType)}
							options={[
								{label: 'Flashcard', value: 'flashcard'},
								{label: 'Ocultar Plabra', value: 'ocultar'},
								{label: 'Test', value: 'test'}
							]}
						/>
					</div>
					<Toolkit editor={editor} cardType={cardType}/>
				</div>
				{/*window.innerWidth > 1200 && 
					<div className='cuadrante_ia'>
						<label>Adición automática por IA</label>
						<button>Próximamente...</button>
					</div>
				*/}
				<div className='separator-top-right'>	
				<button onClick={storeFlashcard} disabled={(!editCard || perteneceAExamen==0 || editCard == cartaEditar || 
					(('pregunta' in (editCard as FlashCardInit)) && ('respuesta' in (editCard as FlashCardInit)) && ((editCard as FlashCardInit).pregunta === undefined || (editCard as FlashCardInit).respuesta === undefined)) ||
					(editCard as FlashCardInit).pregunta !== undefined && (editCard as FlashCardInit).respuesta !== undefined && 
					((editCard as FlashCardInit).pregunta === '' || (editCard as FlashCardInit).respuesta === '' || 
					(editCard as FlashCardInit).pregunta === '<p></p>' || (editCard as FlashCardInit).respuesta === '<p></p>') ||
					(editCard as OcultarCardInit).text !== undefined && 
					((editCard as OcultarCardInit).text === '' || (editCard as OcultarCardInit).text === '<p></p>') ||
					(editCard as TestCardInit).pregunta !== undefined && (editCard as TestCardInit).respuestas !== undefined && (editCard as TestCardInit).respuestas[0] !== undefined && (editCard as TestCardInit).respuestas[1] !== undefined && (editCard as TestCardInit).respuestas[2] !== undefined && (editCard as TestCardInit).respuestas[3] !== undefined && 
					((editCard as TestCardInit).pregunta === '' || (editCard as TestCardInit).respuestas[0] === '' || (editCard as TestCardInit).respuestas[1] === '' || (editCard as TestCardInit).respuestas[2] === '' || (editCard as TestCardInit).respuestas[3] === '' || 
					(editCard as TestCardInit).pregunta === '<p></p>' || (editCard as TestCardInit).respuestas[0] === '<p></p>' || (editCard as TestCardInit).respuestas[1] === '<p></p>' || (editCard as TestCardInit).respuestas[2] === '<p></p>' || (editCard as TestCardInit).respuestas[3] === '<p></p>') 
					)}>
						{operationState === 'send' ? 'Cargando...' : ((cartaEditar ? 'Editar' : 'Añadir') + ' Flashcard')}
					</button>
				</div>
			</div>
			{
				cardType === 'flashcard' ?
					<FlashCardEditor card={cartaEditar as FlashCard} onChangeCard={handleSetEditCard} clean={clean} />
				: cardType === 'ocultar' ?
					<OcultarCardEditor card={cartaEditar as OcultarCard} onChangeCard={handleSetEditCard} clean={clean} />
				: cardType === 'test' ?
					<TestCardEditor card={cartaEditar as TestCard} onChangeCard={handleSetEditCard} clean={clean} />
				: null
			}
			{ operationState === 'completed' ?
					<div className='mensaje'>
						<Icon iconName='checkCircle' isClickable={false}/>
						¡Tarjeta guardada correctamente!
					</div>
				: operationState === 'error' ?
					<div className='mensaje'>
						<Icon iconName='xmark' isClickable={false}/>
						Error al guardar la tarjeta
					</div>
				: null
			}
		</div>
	</>)
}
// CAMBIAR ESO DE 'MENU OCULTO' POR 'PREVISUALIZACION OCULTA'
