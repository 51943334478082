import { ReactNode } from 'react'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import useWindowSize from '@/hooks/useWindowSize'
import './Header.css'

interface HeaderProps {
  navBar?: ReactNode
  rightNode?: ReactNode
}

export default function Header({ navBar, rightNode }: HeaderProps) {

  const { width } = useWindowSize()

  if (width <= 900) {
    return <HeaderMobile navBar={navBar} rightNode={rightNode} />
  } else {
    return <HeaderDesktop navBar={navBar} rightNode={rightNode} />
  }

}
