interface SpinnerProps {
    percentage: number;
    loadingData: boolean;
  }
  
  export default function ExamPercentage({ percentage, loadingData }: SpinnerProps) {
    const validPercentage = isNaN(percentage) ? 0 : percentage;
    const truncatedPercentage = parseFloat(validPercentage.toFixed(1));
    const radius = 25;
    const circumference = 2 * Math.PI * radius;
    const offset = (truncatedPercentage / 100) * circumference;
    let strokeColor;
    if (truncatedPercentage < 30) {
      strokeColor = 'var(--color-delete)';
    } else if (truncatedPercentage >= 30 && truncatedPercentage < 70) {
      strokeColor = '#F7D26E';
    } else {
      strokeColor = 'var(--color-secondary)';
    }

    return (
      <div style={{ position: 'relative', width: '60px', height: '60px' }}>
        <svg width="60" height="60">
          <circle
            cx="30"
            cy="30"
            r={radius}
            stroke={strokeColor}
            strokeWidth="9.5"
            fill="none"
          />
            <circle
            cx="30"
            cy="30"
            r={radius}
            stroke="lightgray"
            strokeWidth="10"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={1-offset} 
            style={{ 
                transition: 'stroke-dashoffset 1s',
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%'
            }}
            />
        </svg>
        {!loadingData && 
            (<div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '12px',
                color: '#000',
                fontWeight: 'bold'
            }}>
            {truncatedPercentage}%
            </div>)
        }

      </div>
    );
  }