import { useRef, useEffect, useState } from 'react'
import { useAsyncDebouncedCallback } from '@/hooks/useAsyncDebouncedCallback'
import { ReactiveEyeIcon } from '@/components/icons/ReactiveEyeIcon'

/**
 * @prop {type} - tipo de input [text, password, ...] (propiedad type del html tag input)
 * @prop {name} - nombre único del input (propiedad name del html tag input)
 * @prop {defaultValue} - valor por defecto del input (propiedad defaultValue del html tag input)
 * @prop {placeholder} - texto de ayuda del input (propiedad placeholder del html tag input)
 * @prop {clear} - Borra el contenido del input en caso de ser true
 * @prop {reapprise} - Reevalua el input para obtener su valor en caso de ser true
 * @prop {constraint} - Restriccion que debe cumplir el input para ser valido y obtener su valor
 * @prop {debounceDelay} - Delay de la restriccion en caso de ser asincrona
 * @prop {getInput} - Devuelve el input en caso de cumplir con la restriccion
 * @prop {children} - Nombre del input a modo de label
 */
interface FormInputProps {
	type: string
	name: string
	defaultValue?: string | null
	placeholder?: string
	clear?: boolean
	reapprise?: boolean
	constraint?: (input: any) => true | string | Promise<true | string>
	debounceDelay?: number
	getInput: (value: string) => void
	children: string
	sendValid?: (value: boolean) => void
}

/**
 * Componente FormInput
 * @param props de la interfaz FormInputProps
 * @returns componente que renderiza un input con su respectivo label, que permite obtener su valor y
 * validarlo mediante una funcion de restriccion en caso de ser requerido (pudiendo ser asincrona).
 */
export default function FormInput({ type, name, defaultValue, placeholder, clear, reapprise, constraint, debounceDelay, getInput, children, sendValid }: FormInputProps) {

	const labelRef = useRef<HTMLLabelElement | null>(null)
	const inputRef = useRef<HTMLInputElement | null>(null)
 	const [valid, setValid] = useState(true)
	const [eye, setEye] = type === 'password' ? useState(false) : [false, () => {}]

	useEffect(() => {
		if (clear) {
			inputRef.current!.value = ''
		}
	}, [clear])

	useEffect(() => {
		if (reapprise) {
			handleGetInput(inputRef.current!.value)
		}
	}, [reapprise])

	/**
	 * Ejecución de la función de validación de forma debounced
	 * @prop {constraint} required
	 * @prop {debounceDelay} required
	 */
	const debouncedConstraintCallback = useAsyncDebouncedCallback(async (input: string) => {
		return await constraint!(input)
	}, debounceDelay!)

	const handleGetInput = async (input: string) => {
		if (!constraint) {
			getInput(input)
		}
		else if (input == '') {
			if (!valid && labelRef.current && labelRef.current.textContent) 
				labelRef.current.textContent = children
			setValid(true)
			if (sendValid) sendValid(true)
			getInput(input)
		} 
		else if (labelRef.current && labelRef.current.textContent) {
			let result
			if (debounceDelay) {
				setValid(true) // hasta la resolucion de la promesa
				if (sendValid) sendValid(true)
				labelRef.current.textContent = children
				result = await debouncedConstraintCallback(input)
			}
			else
				result = constraint(input)
			if (result == true) {
				if (!valid) 
					labelRef.current.textContent = children
				setValid(true)
				if (sendValid) sendValid(true)
				getInput(input)
			} else {
				setValid(false)
				if (sendValid) sendValid(false)
				labelRef.current.textContent = `${children} (${result})`
			}
		}
	}

	return (
		<div className={valid ? 'w-full flex flex-col items-start mt-5 text-text-color': 'w-full flex flex-col items-start mt-5 text-error-color'}>
			<label ref={labelRef}>
				{children}
			</label>
			<input ref={inputRef}
			  className={valid ? 'h-10 bg-white border-solid border-2 border-border-color text-text-color focus:border-secondary-color': 'h-10 bg-white border-solid border-2 border-error-color text-error-color focus:border-error-color'}
				type={type !== 'password' ? type : !eye ? 'password' : 'text'}
				name={name}
				defaultValue={defaultValue? defaultValue : ''}
				placeholder={placeholder}
				onChange={(e) => handleGetInput(e.target.value)}
			/>
			{type === 'password' && <ReactiveEyeIcon getEyeState={(s: boolean) => setEye(s)} />}
		</div>
	)
}
