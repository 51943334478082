import { useState, useEffect } from 'react'

export function useSubscription() {
  
  const [subscriptionPlanId, setSubscriptionPlanId] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const fetchSubState = async () => {
    setLoading(true)
    // no se si esto va bien, pues accede pero por el cors da error y ahora con el proxy da 404
    const response = await fetch(`${import.meta.env.VITE_API_URL}/user/subscription_plan`, {
      method: 'GET',
      credentials: 'include', // BORRAR EN PRODUCCION
    })
    if (!response.ok){
      throw new Error('Error al obtener la suscripción')
    }
    const plan = await response.json()
    if (plan)
      setSubscriptionPlanId(plan.id)
    else
      setSubscriptionPlanId('ef0d1a2c-1075-4576-ac9f-276bbb25296c')
  }

  useEffect(() => {
    if (subscriptionPlanId !== undefined)
      setLoading(false)
  }, [subscriptionPlanId])

  useEffect(() => {
    fetchSubState();
  }, [])

  return { fetchSubState, subscriptionPlanId, loading }

}