import Icon from '@/components/icons/Icon'
import '@/styles/Mazos.css'

interface FloatingPlayButtonProps {
	text?: string
	onClickPlay: () => void
}

export const FloatingPlayButton = ({ text, onClickPlay }: FloatingPlayButtonProps) => {
	return (
		<>
			<div className='FloatingPlayButton' onClick={onClickPlay}>
				<h4>{text}</h4>
				<Icon iconName='play' size='20' />
			</div>
		</>
	)
}
