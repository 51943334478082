import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './Header.css'

export default function Navbar() {

  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
		setCurrentPath(location.pathname)
	}, [location.pathname])

  const routes = [
    { path: '/home', label: 'Inicio' },
    { path: '/dashboard', label: 'Biblioteca' },
    { path: '/examenes', label: 'Exámenes' },
    { path: '/organizacion', label: 'Organización' },
  ]

  return (
    <nav aria-label='Main Navigation'>
      {routes.map((route) => (
        <Link 
          className={currentPath === route.path ? 'active' : ''}
          key={route.path}
          to={route.path}
        >
          {route.label}
        </Link>
      ))}
    </nav>
  )

}
