import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useAuth } from '@/context/auth/useAuth'
import BlancLayout from '@/components/layout/BlancLayout'
import PricingCard from '@/components/PricingCard'
import BuyTokensPopup from '@/components/BuyTokensPopup'
import FreeTrialPopup from '@/components/FreeTrialPopup'
import Popup from '@/components/common/ui/Popup'
import Spinner from '@/components/common/ui/Spinner'
import Icon from '@/components/icons/Icon'
import { GoBackArrow } from '@/components/common/ui/GoBackArrow'
import { Subscription, Plan } from '@/types/types'
import { getDaysDiff } from '@/utils/dateFunctions'
import robotIcon from '@/assets/icons/robotIcon.svg'
import examIcon from '@/assets/icons/examIcon.svg'
import calendarIcon from '@/assets/icons/calendarIcon.svg'
import SEO from '@/components/SEO'

/**
 * TODO: si autenticado llamar api con datos, si no --> login
 */
export default function Pricing() {

    const navigate = useNavigate()
    const auth = useAuth()
	const authenticated = auth?.isAuth
    const [plans, setPlans] = useState<Plan[] | null>(null)
    const [currentSubscription, setCurrentSubscription] = useState<Subscription | null>()
    type ActionPopupType = 'cancel' | 'renew'
    const [openActionPopup, setOpenActionPopup] = useState<false | ActionPopupType>(false)
    type ActionPopupStep = 'confirm' | 'loading' | 'success' | 'error'
    const [actionPopupStep, setActionPopupState] = useState<ActionPopupStep>('confirm')
    const [allowFreeTrial, setAllowFreeTrial] = useState<boolean>(false)
    const [openFreeTrialPopup, setOpenFreeTrialPopup] = useState<boolean>(false)
    const [openBuyTokensPopup, setOpenBuyTokensPopup] = useState<boolean>(false)

    const headerInfoDivs = [
        { icon: robotIcon, title: 'Inteligencia Artificial', description: 'Importación de FlashCards y preguntas tipo test de forma automática gracias a nuestra Inteligencia Artificial.' },
        { icon: examIcon, title: 'Examenes', description: 'Crea tus exámenes, selecciona el temario y ponles una fecha. Podrás evaluar tu conocimiento y ponerte las pilas.' },
        { icon: calendarIcon, title: 'Organización', description: 'Junto con tus exámenes se organizarán tus repasos personalizados en el calendario, queremos para que llegues y lo PETES.'},
    ]

    const fetchPlans = async() => {
        try {
            const res = await fetch(
                `${import.meta.env.VITE_API_URL}/pay/plans`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                }
            )
            if (!res.ok) {
                throw new Error('Error al obtener los planes')
            }
            const data = await res.json()
            // Ordenar los planes por precio para mostrarlos del menor al mayor
            data.plans.sort((a: Plan, b: Plan) => a.price - b.price);
            setPlans(data.plans)
        } catch (error) {
            setActionPopupState('error')
            console.error('Error:', error)
        }
    }   

    const fetchCurrentSubscription = async() => {
        try {
            const res = await fetch(
                `${import.meta.env.VITE_API_URL}/user/subscription`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include'
                }
            )
            if (!res.ok) {
                throw new Error('Error al obtener el plan actual')
            }
            const data = await res.json()
            if (data) setCurrentSubscription(data)
        }
         catch (error) {
            setActionPopupState('error')
            console.error('Error:', error)
        }
    }

    const fetchIsFreeTrialAllowed = async() => {
        try {
            const res = await fetch(
                `${import.meta.env.VITE_API_URL}/user/freetrial_allow`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include'
                }
            )
            if (!res.ok) {
                throw new Error('Error al obtener el plan actual')
            }
            const data = await res.json()
            if (data && data.allowed == true) setAllowFreeTrial(true)
            else setAllowFreeTrial(false)
        }
         catch (error) {
            setActionPopupState('error')
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        fetchPlans()
        if (authenticated) {
            fetchCurrentSubscription()
            fetchIsFreeTrialAllowed()
        }
        else setAllowFreeTrial(true)
    }, [authenticated])

    const handleSubscribe = async (planId: string) => {
        if (!authenticated) navigate('/login')
        else {
            let response = null
            response = await fetch(
                `${import.meta.env.VITE_API_URL}/pay/subscribe_session?planId=${planId}`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include'
                }
            )
            if (!response.ok)
                throw new Error('Error al obtener la URL de pago')
            else {
                const data = await response.json()
                // Se redirige al usuario a la pagina del pago
                window.location.href = data.url
            }
        }
    }

    const handleCancelSub = async () => {
        setActionPopupState('loading')
        const response = await fetch(
            `${import.meta.env.VITE_API_URL}/pay/cancel_subscription`, 
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include'
            }
        )
        if (!response.ok) {
            setActionPopupState('error')
            throw new Error('Error al cancelar la subscripcion')
        } else {
            setCurrentSubscription({ ...currentSubscription!, renew: false })
            setActionPopupState('success')
        }
    }

    const handleRenewSub = async () => {
        setActionPopupState('loading')
        const response = await fetch(
            `${import.meta.env.VITE_API_URL}/pay/renew_subscription`, 
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include'
            }
        )
        if (!response.ok) {
            setActionPopupState('error')
            throw new Error('Error al renovar la subscripcion')
        } else {
            setCurrentSubscription({ ...currentSubscription!, renew: true })
            setActionPopupState('success')
        }
    }

    const handleBuyTokens = async (amount: number) => {
        if (!authenticated) navigate('/login')
        else {
            let response = null
            response = await fetch(
                `${import.meta.env.VITE_API_URL}/pay/tokens?amount=${amount}`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include'
                }
            )
            if (!response.ok)
                throw new Error('Error al obtener la URL de pago')
            else {
                const data = await response.json()
                // Se redirige al usuario a la pagina del pago
                window.location.href = data.url
            }
        }
    }

    const handleFreeTrial = async (planId: string) => {
        if (!authenticated) navigate('/login')
        else {
            let response = null
            response = await fetch(
                `${import.meta.env.VITE_API_URL}/pay/subscribe_session?planId=${planId}&freeTrial=true`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include'
                }
            )
            if (!response.ok)
                throw new Error('Error al obtener la URL de pago')
            else {
                const data = await response.json()
                // Se redirige al usuario a la pagina del pago
                window.location.href = data.url
            }
        }
    }

    return (<>
        <SEO 
            title='Bilern - Precios' 
            description='¡Da un impulso a tu rendimiento académico! Con los planes de pago de Bilern tendrás acceso a la Inteligencia Artificial, y podrás organizar tu estudio con la creación de exámenes personalizados.'
        />
        <BlancLayout>
            <div className='flex flex-row m-3 justify-between' >
                <div className='absolute left-3 top-6'>
                    <GoBackArrow onClick={() => navigate('/')} />
                </div>
                <h2 className='w-full text-[var(--color-green-secondary)] ml-10 mr-10'>
                    ¡Da un impulso a tu rendimiento académico!
                </h2>
            </div>
            {window.innerWidth > 840 && // Los header info divs no se muestran en movil
                <div className='w-full flex justify-center'>
                    <div className='flex flex-row justify-between w-[95%] lg:w-[1280px]'>
                        {headerInfoDivs.map((div, index) => (
                            <div key={index} className='flex flex-row items-center m-3'>
                                <i className='m-3'>
                                    <img src={div.icon} className='w-[60px]'/>
                                </i>
                                <div className='flex flex-col items-start'>
                                    <h3 className='text-[var(--color-green-secondary)]'>{div.title}</h3>
                                    <p className='text-left text-sm m-0'>{div.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className='flex justify-center flex-col items-center'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:w-[650px] lg:w-[1200px] gap-5 justify-items-center items-start'>
                    {plans?.map((plan, index) => (
                        <div key={index} style={{width: '250px'}} className='m-2'>
                            {currentSubscription?.plan_id !== plan.id ?
                                <PricingCard 
                                    name={plan.name} 
                                    duration={plan.duration} 
                                    price={plan.price} 
                                    features={plan.features} 
                                    current={false}
                                    callToAction={currentSubscription == null ? 'Suscribirse': 'Cambiar a este Plan'}
                                    onClickButton={ plan.id != 'ef0d1a2c-1075-4576-ac9f-276bbb25296c' ? 
                                        () => handleSubscribe(plan.id) : undefined
                                    }
                                /> :
                                <PricingCard 
                                    name={plan.name} 
                                    duration={plan.duration} 
                                    price={plan.price} 
                                    features={plan.features} 
                                    current={true}
                                    renew={currentSubscription.renew}
                                    daysToExpire={
                                        currentSubscription.renew == false ? 
                                            getDaysDiff(new Date(currentSubscription.end_date), new Date()) : 
                                            undefined
                                    }
                                    onClickButton={ plan.id != 'ef0d1a2c-1075-4576-ac9f-276bbb25296c' &&  
                                        currentSubscription.renew == true ?
                                        () => setOpenActionPopup('cancel') : 
                                        () => setOpenActionPopup('renew')
                                    }
                                />
                            }
                        </div>
                    ))}
                </div>
                <div className='w-full md:w-[650px] lg:w-[1200px] pl-10 mb-3 text-left'>* Sujeto a uso razonable en <div className="inline cursor-pointer font-semibold text-[var(--color-secondary)]" onClick={()=>navigate('/terminos')}>Términos y Condiciones</div></div>
            </div>
            <div className='flex justify-center mb-3'>
                <div className='w-[95%] lg:w-[1280px] flex flex-col md:flex-row items-center justify-around mt-3 text-xl'>
                    { allowFreeTrial &&
                        <button 
                            className='min-w-[250px] w-[35%] h-[45px] rounded-[45px] p-0 mb-5 md:mb-0'
                            onClick={() => setOpenFreeTrialPopup(true)}
                        >
                            ¡Pruebalo Gratis Ahora!
                        </button>
                    }
                    <button 
                        className='min-w-[250px] w-[35%] h-[45px] rounded-[45px] p-0 bg-[var(--color-primary)]' 
                        onClick={() => setOpenBuyTokensPopup(true)}
                    >
                        Compra Tokens
                    </button>
                </div>
            </div>
            <Popup 
                open={openActionPopup === false ? false : true} 
                onClose={() => {setActionPopupState('confirm'); setOpenActionPopup(false)}} 
                height='250px'
            >
                <div className='h-full flex flex-col justify-center items-center'>
                    { actionPopupStep === 'confirm' ?
                        <>
                            <h3 className='m-5'>{
                                openActionPopup === 'cancel' ? '¿Estás seguro de que quieres cancelar la suscripción? Una vez cancelada podrás seguir disfrutando de sus beneficios hasta el '+currentSubscription!.end_date : 
                                openActionPopup === 'renew' ? '¿Estás seguro de que quieres renovar la suscripción? Esta se volverá a cobrar al final del plazo de tu subscripción actual' : ''
                            }</h3>
                            <div className='flex flex-row justify-center'>
                                <button className='bg-[var(--color-delete)]' onClick={
                                    () => setOpenActionPopup(false)
                                }>
                                    Cancelar
                                </button>
                                <button className='ml-5' onClick={
                                    openActionPopup === 'cancel' ? handleCancelSub :
                                    openActionPopup === 'renew' ? handleRenewSub :
                                    () => {}
                                }>
                                    Confirmar
                                </button>
                            </div>
                        </>
                    : actionPopupStep === 'loading' ?
                        <Spinner fillAll={false} />
                    : actionPopupStep === 'success' ?
                        <>
                            <h3 className='m-5'>Operación realizada con éxito</h3>
                            <Icon 
                                className='text-[var(--color-secondary)] rounded-full border-solid border-[var(--color-secondary)] p-1'
                                iconName='check' 
                                size='30'
                                isClickable={false}
                            />
                        </>
                    : actionPopupStep === 'error' ?
                        <>
                            <h3 className='m-5'>Ha ocurrido un error ):</h3>
                            <Icon 
                                className='text-[var(--color-error)]'
                                iconName='xmark' 
                                size='60'
                                isClickable={false}
                            />
                        </>
                    : null
                    }
                </div>
            </Popup>
            <FreeTrialPopup 
                open={openFreeTrialPopup} 
                plansData={plans ? plans
                    .filter(plan => plan.id !== 'ef0d1a2c-1075-4576-ac9f-276bbb25296c')
                    .map(plan => ({
                        label: plan.name, 
                        value: plan.id, 
                        days: plan.freeTrialDetails!.days,
                        tokens: plan.freeTrialDetails!.tokens
                    })) 
                : []}
                onClose={() => setOpenFreeTrialPopup(false)} 
                onBuy={handleFreeTrial}
            />
            <BuyTokensPopup 
                open={openBuyTokensPopup} 
                onClose={() => setOpenBuyTokensPopup(false)} 
                onBuy={handleBuyTokens} 
            />
        </BlancLayout>
    </>)
}
