import { useState, useEffect } from 'react';
import { usePomodoro } from '@/context/ClockContext'

interface PomodoroPageProps {
    tiempoPomodoro: number;
    tiempoDescanso: number;
}

export default function PomodoroPage({ tiempoPomodoro, tiempoDescanso }: PomodoroPageProps) {

    const [timeLeftPomodoro, setTimeLeftPomodoro] = useState(tiempoPomodoro)
    const [timeLeftDescanso, setTimeLeftDescanso] = useState(tiempoDescanso)
    const [pomodoroOrDescanso, setPomodoroOrDescanso] = useState(0)
    const [refreshTicking, setRefreshTicking] = useState(0)

    const [tiempoHoy, setTiempoHoy] = useState(0)



    const { timeLeft, setTimeLeft, 
        dateLeft, setDateLeft,
        started, setStarted,
        running, setRunning, 
    } = usePomodoro();


        
    

    const updateData = async (tiempo:number) => {
        try {

            localStorage.removeItem('tiempoAlEmpezar');
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/estadisticas/update_stats?minEstudioHoy=${tiempo}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                }
            );
            if (!response.ok) {
                throw new Error('Error al actualizar las estadisticas');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    useEffect(() => {
        if(started){
            if (running) {
                let pomTdesF=localStorage.getItem('pomTdesF');
                if(pomTdesF=="true"){
                    setPomodoroOrDescanso(1)
                } else{
                    setPomodoroOrDescanso(2)
                }
                setRefreshTicking(1)
            } else if(!running){
                let pomTdesF=localStorage.getItem('pomTdesF');
                if(pomTdesF=="true"){
                    setPomodoroOrDescanso(1)
                    setTimeLeftPomodoro(timeLeft)
                } else{
                    setPomodoroOrDescanso(2)
                    setTimeLeftDescanso(timeLeft)
                }
            }
        }
        const updateData = async () => {
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/estadisticas/get_stats`, 
                      {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                      }
                    )
                if (!response.ok) {
                    throw new Error('Error al actualizar las estadisticas');
                }
                const data = await response.json();
                const today = new Date().toISOString().split('T')[0]; // Obtener la fecha de hoy en formato YYYY-MM-DD
                if(data.dia_minEstudio == today){
                    setTiempoHoy(data.minEstudioHoy[data.minEstudioHoy.length-1]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        updateData()

    }, []);

    
    useEffect(() => {
        if (!running && !started && pomodoroOrDescanso == 0 && timeLeft==0) {
            setTimeLeftPomodoro(tiempoPomodoro);
            setTimeLeftDescanso(tiempoDescanso);
        }
    }, [tiempoPomodoro, tiempoDescanso]);

    useEffect(() => {

        if(refreshTicking>=0){
            const interval = setInterval(() => {
                if(started){
                    if(running && pomodoroOrDescanso == 1){ 
                        const now = new Date().getTime();
                        const elapsed = (now - dateLeft)/ 1000;
                        const newTimeLeft = Number(timeLeft)*60 - elapsed;
                        if(newTimeLeft>0){
                            setTimeLeftPomodoro(newTimeLeft/60);
                        }else{
                            handleEndPomodoro(true)
                        }
                    } else if(running && pomodoroOrDescanso == 2){
                        const now = new Date().getTime();
                        const elapsed = (now - dateLeft)/ 1000;
                        const newTimeLeft = Number(timeLeft)*60 - elapsed;
                        if(newTimeLeft>0){
                            setTimeLeftDescanso(newTimeLeft/60);
                        }else{
                            handleEndPomodoro(true)
                        }
                    } else if(!running){
                        clearInterval(interval)
                    }
                } else{
                    localStorage.removeItem('tiempoAlEmpezar');
                    localStorage.removeItem('pomodoroTime');
                    localStorage.removeItem('timeLeft');
                    localStorage.removeItem('running');

                    setTimeLeft(0)
                    setRunning(false);
                    setStarted(false);
                    setPomodoroOrDescanso(0)
                    setTimeLeftPomodoro(tiempoPomodoro);
                    setTimeLeftDescanso(tiempoDescanso);
                    clearInterval(interval)
                }
                
            }, 100)

            return () => clearInterval(interval); 
        }

    }, [running,started,dateLeft,timeLeft,refreshTicking]);


    useEffect(() => {
        if(pomodoroOrDescanso ==1){
            if(timeLeft==0){

                setTimeLeft(tiempoPomodoro);
                setDateLeft(new Date().getTime());
                localStorage.setItem('pomodoroTime', new Date().getTime().toString());
                localStorage.setItem('timeLeft', tiempoPomodoro.toString());
                localStorage.setItem('running', true.toString());
                localStorage.setItem('tiempoAlEmpezar', tiempoPomodoro.toString());
                localStorage.setItem('pomTdesF', true.toString());
                setStarted(true)
                setRunning(true)
            } 
        }
        else if(pomodoroOrDescanso ==2){
            if(timeLeft==0){

                setTimeLeft(tiempoDescanso);
                setDateLeft(new Date().getTime());
                localStorage.setItem('pomodoroTime', new Date().getTime().toString());
                localStorage.setItem('timeLeft', tiempoDescanso.toString());
                localStorage.setItem('running', true.toString());
                localStorage.setItem('tiempoAlEmpezar', tiempoDescanso.toString());
                localStorage.setItem('pomTdesF', false.toString());
                setStarted(true)
                setRunning(true);
            }
        }
    }, [pomodoroOrDescanso]);


    const handleStopPomodoro = () => {
        setRunning(false);
        if(pomodoroOrDescanso ==1){
            setTimeLeft(timeLeftPomodoro)
            localStorage.setItem('timeLeft', timeLeftPomodoro.toString());
            localStorage.setItem('running', false.toString());
        }
        else if(pomodoroOrDescanso ==2){
            setTimeLeft(timeLeftDescanso)
            localStorage.setItem('timeLeft', timeLeftDescanso.toString());
            localStorage.setItem('running', false.toString());
        }
    };
    const handleContinuePomodoro = () => {
        setRunning(true);
        setDateLeft(new Date().getTime());
        localStorage.setItem('pomodoroTime', new Date().getTime().toString());
        localStorage.setItem('running', true.toString());
    };

    const handleEndPomodoro = (ceroONo:boolean) => {//ceroONo false si se ha dado al boton de terminar
        if(pomodoroOrDescanso ==1){
            if(!ceroONo){ 
                const tiempoLocal=localStorage.getItem('tiempoAlEmpezar');
                if(tiempoLocal){
                    setTiempoHoy(tiempoHoy + Number(tiempoLocal) - timeLeftPomodoro)
                    updateData(Number(tiempoLocal) - timeLeftPomodoro)
                }
            }
        }

        localStorage.removeItem('tiempoAlEmpezar');
        localStorage.removeItem('pomodoroTime');
        localStorage.removeItem('timeLeft');
        localStorage.removeItem('running');

        setTimeLeft(0)
        setRunning(false);
        setStarted(false);
        setPomodoroOrDescanso(0)
        setTimeLeftPomodoro(tiempoPomodoro);
        setTimeLeftDescanso(tiempoDescanso);
    };


    const formatTime = (minutesVar: number) => {
        const seconds = Number(((minutesVar - Math.floor(minutesVar)) * 60).toFixed(0));
        const minutes = Math.floor(minutesVar);
		if (seconds == 60){
			return `${minutes < 10 ? '0' : ''}${minutes + 1}:00`;
		}else{
			return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		}
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '3vh', height: '66vh' }}>
            <div className="min-w-[40vh] min-h-[40vh] md:min-w-[45vh] md:min-h-[45vh] rounded-full border-3 border-dotted border-black bg-white flex flex-col items-center justify-center text-[var(--color-primary)] font-semibold mb-[3vh]">
            {pomodoroOrDescanso != 1 && (
                <div
                className={`relative ${pomodoroOrDescanso == 0 ? 'top-[27%] text-[7vh]' : 'bottom-2 text-[11vh]'}`}
                >
                {formatTime(timeLeftDescanso)}
                </div>
            )}
            {pomodoroOrDescanso != 2 && (
                <div
                className={`relative ${pomodoroOrDescanso == 0 ? 'bottom-[28%] md:bottom-[24%] text-[9vh]' : 'bottom-2 text-[11vh]'}`}
                >
                {formatTime(timeLeftPomodoro)}
                </div>
            )}
            {pomodoroOrDescanso == 0 && (
                <div
                className="relative w-[40%] h-[4px] bottom-[30%] md:bottom-[25%] bg-[var(--color-primary)]"
                ></div>
            )}
            </div>

            <div className='flex flex-row mx-24'>
            {!started && !running && (
                <>
                    <button
                    onClick={() => { setPomodoroOrDescanso(1) }}
                    className="text-[24px] px-[20px] py-[10px] md:w-[260px]"
                    >
                    Comenzar estudio
                    </button>
                    <button
                    onClick={() => { setPomodoroOrDescanso(2) }}
                    className="text-[24px] ml-[30px] md:ml-[60px] px-[20px] py-[10px] md:w-[260px]"
                    >
                    Comenzar descanso
                    </button>
                </>
                )}
                {started && (
                    <>
                    {running ? (
                        <button
                        onClick={handleStopPomodoro}
                        className="text-[24px] px-[20px] py-[10px] bg-[#FFBC63] transition duration-500 border border-transparent rounded-[var(--border-radius)] hover:bg-[#F7B660] w-36"
                        >
                        Pausar
                        </button>
                    ) : (
                        <button
                        onClick={handleContinuePomodoro}
                        className="text-[24px] px-[20px] py-[10px] bg-[#FFBC63] transition duration-500 border border-transparent rounded-[var(--border-radius)] hover:bg-[#F7B660] w-36"
                        >
                        Reanudar
                        </button>
                    )}
                    <button
                        onClick={() => handleEndPomodoro(false)}
                        className="text-[24px] px-[20px] py-[10px] bg-[var(--color-delete)] transition duration-500 ml-[30px] md:ml-[60px] border border-transparent rounded-[var(--border-radius)] hover:bg-[var(--color-error)] w-36"
                    >
                        Terminar
                    </button>
                    </>
                )}
            </div>
            <div style={{ fontSize: '12px' }}>
                <p>Tiempo concentrado hoy: <span style={{ fontWeight: 600 }}>{Math.floor(tiempoHoy/60)} horas {Math.floor(tiempoHoy%60)} minutos</span></p>
            </div>

        </div>
        
    );

}