import Icon from '@/components/icons/Icon'
import '@/styles/Mazos.css'

interface GoBackArrowProps {
	title?: string
	subtitle?: string
	onClick?: () => void
	className?: string
}

// TODO cambiar en el css #arrow-left-icon por className leftArrow

export const GoBackArrow = ({ title, subtitle, onClick, className }: GoBackArrowProps) => {

	return (
        <div className={`relative text-center sm:ml-24 ${className}`}>
            <Icon 
                className='absolute -ml-8 mt-5'
                iconName='arrowLeft' size='20' onClick={onClick}
            />
            <h3>{title}</h3>
            <h4 className='truncate max-w-xs'>{subtitle}</h4>
        </div>
	)
}