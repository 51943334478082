import { useRef, useEffect } from 'react'

interface TextareaProps {
	placeholder?: string
  height?: string // formato tailwindcss ('h-number')
	clear?: boolean
	getText: (value: string) => void
	children: string
}

export default function FormInput({ placeholder, height, clear, getText, children }: TextareaProps) {

	const textareaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (clear) {
			textareaRef.current!.value = ''
		}
	}, [clear])

	return (
		<div className={'w-full flex flex-col items-start mt-5 text-text-color'}>
			<label>
				{children}
			</label>
			<textarea
				ref={textareaRef}
			  className={`${height} h-48 bg-white border-solid border-2 border-border-color text-text-color focus:border-secondary-color`}
				placeholder={placeholder}
				onBlur={(e) => getText(e.target.value)}
			/>
		</div>
	)
}
