interface ConfirmDeleteCardProps {
    cardId: string
    cardType: string
    onReturn: () => void
    onReturnDelete: () => void
}

export default function ConfirmDeleteCard({ cardId, cardType, onReturn, onReturnDelete }: ConfirmDeleteCardProps) {

    const deleteCard = async () => {
        onReturnDelete()
        try {
			const response = await fetch(
                `${import.meta.env.VITE_API_URL}/card/delete?cardId=${cardId}&cardType=${cardType}`, 
                {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', },
                }
            )
            if (!response.ok){
                throw new Error('Error al eliminar asignatura');
            }			
		} catch (error) {
			console.error('Error:', error);
			return
		}
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '25px',
            fontSize: '1.25rem',
        }}>
        ¿Estás seguro de que quieres borrar esta tarjeta?
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '20px',
                fontSize: '1.2rem',
            }}>
                <button style={{
                    width: '150px',
                    backgroundColor: 'var(--color-delete)',
                }} onClick={onReturn}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-error)'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-delete)'}
                >
                Cancelar
                </button>
                <button style={{
                    marginLeft: '50px',
                    width: '150px',
                }}
                onClick={deleteCard}>
                Eliminar
                </button>
            </div>
        </div>
    )
}
