import '../styles/MethodCard.css'

interface MethodCardProps {
	name: string
	image: string
	children: string
}

export const MethodCard = ({ name, image, children }: MethodCardProps) => {
	return (
		<div className='MethodCard'>
			<div className='MethodCard-img'>
				<img src={image} />
			</div>
			<div>
				<h2>{name}</h2>
				<h4>{children}</h4>
			</div>
		</div>
	)
}
