import { useRef, useState, useEffect } from 'react'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import '@/styles/Register.css'
import ccaa from '@/data/ccaa.json'
import provincias from '@/data/provincias.json'
import poblaciones from '@/data/poblaciones.json'
import gradosUniversitarios from '@/data/gradosUniversitarios.json'
import universidades from '@/data/universidades.json'

interface DatalistProps {
	id: string
	defaultValue?: string | null
	dataOp: string
	getSelected: (value: string) => void
	children: string
}

interface Option {
	parent_code?: string
	code: string
	label: string
}

export const Datalist = ({ id, defaultValue, dataOp, getSelected, children }: DatalistProps) => {

	const data = dataOp === 'ccaa' ? ccaa : dataOp === 'provincias' ? provincias : dataOp === 'poblaciones' ? poblaciones : dataOp === 'gradosUniversitarios' ? gradosUniversitarios : dataOp === 'universidades' ? universidades : []
	
	const inputRef = useRef<HTMLInputElement | null>(null)
	const [selected, setSelected] = useState('')
	const [options, setOptions] = useState<Option[]>([])
	const [filteredOptions, setFilteredOptions] = useState<Option[]>([])

	useEffect(() => {
		const fetchData = async () => {
			try {
				setOptions(data)
				setFilteredOptions(data)
			} catch (error) {
				console.error('Error al cargar los datos:', error)
			}
		}
		fetchData()
	}, [])

	const debouncedHandleInputChange = useDebouncedCallback((query: string) => {
		const newFilteredOptions = options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()))
		setFilteredOptions(newFilteredOptions)
	}, 300) // delay of execution in ms

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value
		debouncedHandleInputChange(query)
	}

	const handleGetSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value
		if (inputValue !== '') {
			// Es necesario para que no se entrar en un bucle infinito de renderizado de este componente al actualizar el estado del padre
			const isInOptions = options.some((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
			if (isInOptions) {
				setSelected(inputValue)
				getSelected(inputValue)
			} else if (inputRef.current) {
				inputRef.current.value = selected
			}
		} else {
			setSelected('')
		}
	}

	return (
		<div className='input-container'>
			<label>
				{children}
			</label>
			<input 
				type='text'
				list={id}
				ref={inputRef}
				onChange={handleInputChange}
				onBlur={handleGetSelected}
				defaultValue={defaultValue? defaultValue : ''}
			/>
			<datalist id={id}>
				{filteredOptions.map((option) => (
					<option key={option.parent_code + ': ' + option.code + option.label} value={option.label} />
				))}
			</datalist>
		</div>
	)
}
