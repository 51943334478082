import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/context/auth/useAuth'

/**
 * Este componente usa useAuth para comprobar la autenticación del usuario de manera que si el usuario
 * ya se ha cargado (loading es false) y no está autenticado (user es null) redirecciona a /login,
 * en caso contrario si el usuario está autenticado devuelve el children
 * @property {ReactNode} children componente de uso autenticado
 * @returns children si el usuario está autenticado
 */
export default function AuthenticatedRoute({ children }: { children: ReactNode }) {

  const auth = useAuth()
  const isAuth = auth?.isAuth
  const loading = auth?.loading
  const navigate = useNavigate()

  useEffect(() => {
    if(!loading){
      if (!isAuth)
        return navigate('/login', { replace: true })
    }
  }, [isAuth, navigate, loading])

  if (!loading)
    return isAuth ? children : null

}
