import React from 'react';
import {useFormikContext} from "formik";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
}

function FormSubmitButton(props : Props) {

    const {submitForm, isSubmitting} = useFormikContext();

    return (
        <button onClick={submitForm} type={"submit"} {...props} children={isSubmitting ? "Loading..." : props.children}/>
    );
}

export default FormSubmitButton;