import { useState } from 'react'
import Popup from './common/ui/Popup'
import RadioGroup from './common/ui/RadioGroup'

type FreeTrialPlanData = {
  label: string
  value: string
  days: number
  tokens: number
}
interface FreeTrialPopupProps {
    open: boolean
    plansData: FreeTrialPlanData[]
    onClose: () => void
    onBuy: (planId: string) => void
}

export default function FreeTrialPopup({open, plansData, onClose, onBuy}: FreeTrialPopupProps) {

   // Plan seleccionado para realizar la subscripción tras acabar la prueba
  const [selectedPlan, setSelectedPlan] = useState<FreeTrialPlanData | undefined>(undefined)

  return (
      <Popup open={open} onClose={onClose} width='500px' height='500px'>
        <div className='w-full h-[95%] flex flex-col items-center justify-between'>
          <h2 className='text-[--color-primary] leading-tight w-[90%]'>Estas a punto de probar nuestra version más pro</h2>
          <div>
            <h4 className='mb-3'>Selecciona que subscripción deseas tras finalizar tu prueba.</h4>
            <RadioGroup options={plansData} getSelected={(planId) => setSelectedPlan(plansData.find(plan => plan.value == planId))} />
            { selectedPlan && <>
              <h4 className='text-left mt-3'>Durante {selectedPlan.days} días podrás:</h4>
              <ul className='list-disc list-inside text-left'>
                <li>Probar el modo examen</li>
                <li>Organizar tu estudio diario con Bilern</li>
                <li>Introducir hasta {(selectedPlan.tokens/800).toFixed(0)} páginas para probar nuestra IA</li>
              </ul>
            </>}
          </div>
          <p className='text-xs'>Podrás cancelar tu subscripción en cualquier momento</p>
          <button 
            className={!selectedPlan ? 'w-1/2 bg-[var(--color-text-light)] hover:bg-[var(--color-text-light)] hover: border-[var(--color-text-light)] cursor-default' : 'w-1/2'}
            disabled={!selectedPlan} 
            onClick={() => {onClose; selectedPlan && onBuy(selectedPlan.value)}}
          >
            Comenzar Prueba
          </button>
        </div> 
      </Popup >
  )
}
