import { ReactNode } from 'react'
import logo from '@/assets/whitelogo.svg'
import './Header.css'
import { useNavigate } from 'react-router-dom'

interface HeaderDesktopProps {
  navBar?: ReactNode
  rightNode?: ReactNode
}

export default function HeaderDesktop({ navBar, rightNode }: HeaderDesktopProps) {

  const navigate = useNavigate()

  return (
    <header>
      <div className='header-left'>
        <img src={logo} alt='logo' onClick={() => navigate('/home')} className='cursor-pointer'/>
      </div>
      <div className='header-center'>
        {navBar}
      </div>
      <div className='header-right'>
        {rightNode}
      </div>
		</header>
  )
}
