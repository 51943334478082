import { Link } from 'react-router-dom'
import './Header.css'
import { useRef, useState } from 'react';
import Icon from '@/components/icons/Icon';
import { useUpdateFolders } from '@/context/UpdateFoldersContext';


interface MazoProps {
  signOut: () => void,
  name: string, 
  surname: string,
  closeUserMenu:()=>void
}

export const UserMenu = ({ signOut, name, surname, closeUserMenu }: MazoProps) => {

  const [isCodigo, setIsCodigo] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [erroneo, setErroneo] = useState(false)
  const { updateFolders, setUpdateFolders } = useUpdateFolders();

  
  const meterCartas = async() =>  {
    const codigo = inputRef.current?.value
    if (codigo){
      inputRef.current.value = 'Cargando...'
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/import/code`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({code:codigo}),
            credentials: 'include',
          })
        if (!response.ok){
          throw new Error('Error al conseguir cartas de examen')
        }			    
        inputRef.current.value = ''
        setUpdateFolders(!updateFolders)
      } catch (error) {
        setErroneo(true)
        inputRef.current.value = codigo
        console.error('Error:', error)
      }
    }

  }

  return (
    <div className='UserMenu'>
      <div className='icon-placeholder'>
        <h2>{name.charAt(0).toUpperCase()}</h2>
        <h2>{surname.charAt(0).toUpperCase()}</h2>
      </div>
      <h4 style={{margin:'0px 0 10px 0', textAlign:'center'}}>{name+' '+surname}</h4>
      <Link to='/settings' onClick={closeUserMenu}>
        Ajustes
      </Link>
      <Link to='/tutorial' onClick={closeUserMenu}>
        Tutorial
      </Link>
      <Link to='/contacto' onClick={closeUserMenu}>
        Contáctanos
      </Link>
      <div className='code_container'>
        {
          isCodigo ? 
          <>
            <input type='text' className={erroneo?'invalid_code-input':''} ref={inputRef} placeholder='Código'/>
            <Icon iconName='xmark' className='xmark-icon' onClick={() => {setErroneo(false),setIsCodigo(false)}} />
            <Icon iconName='check' className='check-icon' onClick={() => {meterCartas()}}/>
          </>
          :
          <a onClick={() => setIsCodigo(true)}>
            Códigos
          </a>
        }
      </div>

      <hr/>
      <a href='https://bilern.com'>
        <div onClick={signOut} style={{marginBottom:'10px'}}>Cerrar sesion</div>
      </a>
    </div>
  )
} 