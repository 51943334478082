import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import OrganizacionCard from './OrganizacionCard'; 
import { ExamPrevDays, ExamTask, Task, UserData } from '@/types/types';
import Spinner from './common/ui/Spinner';

interface HomeEstadisticasBottomLeftProps {
  estudio: (cartasDiasPrevios: any[], modo: number) => void
}

export const HomeEstadisticasBottomLeft = ({ estudio }: HomeEstadisticasBottomLeftProps) => {
  const [allTareas, setAllTareas] = useState<Task[]>([]);
  const [examCards, setExamCards] = useState<any[]>([]);
  const [exams, setExams] = useState<any[]>([]);
  const [diasPrevios, setDiasPrevios] = useState<ExamPrevDays[]>([]);
  const [loading, setLoading] = useState(true);
  const [allCards, setAllCards] = useState<any[]>([]);
  
  useEffect(() => {
    const fetchData = async () => {
      await getTasks();
      await getExamCards();
      setLoading(false);
    };
    fetchData();
  }, []);


  const getTasks = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/task/get_from_user_id`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        }
      );
      if (!response.ok) {
        throw new Error('Error al conseguir las tareas');
      }
      const data = await response.json();
      setAllTareas(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getExamCards = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/exam/get_cards_from_user`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        }
      )
      if(response.status === 401){
        return 
    }
      if (!response.ok) {
        throw new Error('Error al conseguir las tareas');
      }
      const data = await response.json();
      const { cards, exams, diasPrevios } = data;
      setAllCards(cards)
      setExams(exams);
      setDiasPrevios(diasPrevios);

      const cartasFiltradas = cards.flatMap((card: { exam_date: any[]; exam_bool: { [x: string]: any; }; mazo_id: any; mazo_name: any; }) =>
        card.exam_date.map((date, index) => ({
          exam_date: date,
          exam_bool: card.exam_bool[index],
          mazo_id: card.mazo_id,
          mazo_name: card.mazo_name,
        }))
      );
      setExamCards(cartasFiltradas);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Filtrar tareas, cartas de examen y días previos solo para hoy
  const todayString = format(new Date(), 'yyyy-MM-dd');
  const tareasDelDia = allTareas.filter(tarea => tarea.date === todayString).sort((a, b) => a.content.localeCompare(b.content));
  const cartasDelDia = examCards.filter(carta => carta.exam_date === todayString);

  const groupedTasks = cartasDelDia.reduce((acc, carta) => {
    if (!acc[carta.mazo_id]) {
      acc[carta.mazo_id] = {
        name: carta.mazo_name,
        mazo_id: carta.mazo_id,
        total: 0,
        done: 0,
        cards: []
      };
    }
    acc[carta.mazo_id].total += 1;
    if (carta.exam_bool) acc[carta.mazo_id].done += 1;
    return acc;
  }, {} as { [key: string]: ExamTask });

  for (let key in groupedTasks) {
    groupedTasks[key].cards = allCards.filter(card => {
        const dateIndex = card.exam_date.indexOf(todayString);
        return card.mazo_id === key && 
               dateIndex !== -1 && 
               card.exam_bool[dateIndex] === false;
    });
}

  const tareasExamen = Object.values(groupedTasks);
  const examPrevDays = diasPrevios.filter(dia => dia.exam_date === todayString);
  const examAlerts = exams.filter(exam => exam.fecha_fin === todayString).map(exam => exam.name);


  function normalizeToMidnightUTC(date: Date): Date {
    // Ajustar la fecha a la zona horaria local
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return new Date(Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate()));
}

const currentDate = normalizeToMidnightUTC(new Date());
const todayUTC = currentDate.toISOString().split('T')[0]; // Obtener la fecha de hoy en UTC a medianoche en formato 'YYYY-MM-DD'

const examPrevDaysFiltered = examPrevDays.filter(dia => dia.exam_date === todayUTC);

let cartasDiasPrevios =[]
for(let i=0;i<examPrevDaysFiltered.length;i++){
    let totalACoger=examPrevDaysFiltered[i].num_cards-examPrevDaysFiltered[i].num_cartas_estudiadas
    let temp=[]
    let mazos_id = examPrevDaysFiltered[i].mazos_id
    for(let j=0;j<allCards.length;j++){
        if (mazos_id.includes(allCards[j].mazo_id) && allCards[j].final_bool == false && totalACoger>0){
            temp.push(allCards[j]);
            totalACoger--;
        }
    }
    cartasDiasPrevios.push(temp)
}


  return (
    <div > 
      <div 
      style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '95%',
          height: '100%',
          padding: ' 0px 0px 10px 10px',
          overflow: 'hidden',
      }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <div style={{ 
                fontSize: '25px',
                fontWeight: 'bold',
                color: 'var(--color-primary)',
            }}>
            Tareas para hoy
            {!loading && (tareasDelDia.length > 0 || tareasExamen.length > 0 || examPrevDays.length > 0) &&
                ` (${tareasDelDia.length + tareasExamen.length + examPrevDays.length}) `}
            <span style={{ color: 'red', marginLeft: '10px', fontSize: '17px' }}>
                {examAlerts.length > 0 && `Examen de ${examAlerts.join(', Examen de ')}`}
            </span>
            </div>
        </div>
        {!loading &&
        (<div className="scrollable-list-unlimited" style={{
          maxHeight: '300px', marginTop: '10px', overflowY: 'auto', overflowX: 'hidden',
        }}><OrganizacionCard
            tareas={tareasDelDia}
            examCards={tareasExamen}
            examPrev={examPrevDays}
            date={new Date()}
            updateTasks={getTasks}
            cartasDiasPrevios={cartasDiasPrevios}//
            play={(cartasDiasPrevios,modo)=>{estudio(cartasDiasPrevios,modo)}}//
        /></div>)}
      </div>
      {loading && 
      <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '30vh',
          width: '100%',
      }}>
          <Spinner fillAll={false} size='20px'/>
      </div>}
    </div>
  );
  
};
