import { useEffect, useState, useRef } from 'react'
import Icon from '@/components/icons/Icon'
import { Folder } from '../types/types'
import '../styles/FolderMenu.css'
import { useUpdateFolders } from '../context/UpdateFoldersContext';


interface FolderMenuProps {
	inicFolder: Folder | null
	getCurrentFolder: (currentFolder: Folder | null) => void
	hide: () => void
}

export const FolderMenu = ({ inicFolder, getCurrentFolder, hide }: FolderMenuProps) => {

	const inputRef = useRef<HTMLInputElement>(null)
	const [folders, setFolders] = useState<Folder[]>([])
	const [currentFolder, setCurrentFolder] = useState<Folder | null>(inicFolder)
	const [nombresAsigOcupados, setNombresAsigOcupados] = useState<string[]>([]);
	const [isInput, setIsInput] = useState(false)
	const [nombreAsigExiste, setNombreAsigExiste] = useState(true)
	const { updateFolders, setUpdateFolders } = useUpdateFolders();

	useEffect(() => {
		//const localFolders = localStorage.getItem('folders')
		//if (!localFolders || localFolders == '[]' || updateFolders) {
		//if (!updateFolders) {
    		const getFolders = async () => {
				try{
					const response = await fetch(
						`${import.meta.env.VITE_API_URL}/folder/get_from_user`,
						{ 
							method: 'GET',
							credentials: 'include'
						}
					)
					if(!response.ok) {
						throw new Error('Error en la obtencion de las carpetas')
					}
					const data = await response.json()
					let folderAgregar: Folder[] = []
					for(let i=0;i<data.length;i++) {
						folderAgregar.push(data[i])
					}
					setFolders(folderAgregar)
				} catch(error) {
					console.log(error)
				}
			}
    		getFolders()
			setUpdateFolders(false)
		//}
		/*
		else if (localFolders) {
			setFolders(JSON.parse(localFolders) as Folder[])
		}*/
  }, [updateFolders])

  /*
	useEffect(() => {
		if (folders.length > 0)
			localStorage.setItem('folders', JSON.stringify(folders))
	}, [folders])
  */
	useEffect(() => {
		getCurrentFolder(currentFolder)
	}, [currentFolder])

	useEffect(() => {
		if (isInput && inputRef.current) 
			inputRef.current.focus()
	}, [isInput])

	const addNewFolder = async (name: string): Promise<{ id: string, name: string } | false> => {
			setIsInput(false)
			try {
				const response = await fetch(  
				`${import.meta.env.VITE_API_URL}/folder/create`,
				{
				  method: 'POST',
				  headers: {
					  'Content-Type': 'application/json',
				  },
				  body: JSON.stringify({name: name}),
					credentials: 'include'
				})
				if (!response.ok){
					throw new Error('Error en la creacion de la carpeta');
				}			
				return await response.json()
			} catch (error) {
				console.error('Error:', error);
				return false
			}
	}

	const handleAddNewFolder = async () => {
		if (inputRef.current && inputRef.current.value !== '') {
			const folderName = inputRef.current.value;
			addNewFolder(folderName).then((res) => {
					if (res) {
						setFolders([...folders, { id: res.id, name: res.name }])
						setNombreAsigExiste(true)
						setCurrentFolder({ id: res.id, name: res.name })
					}
			})
		}
	}

	const checkFolderNames = async() => {
		setNombresAsigOcupados([])
		let foldersLeer=folders
		for(let i=0;i<foldersLeer.length;i++){
			setNombresAsigOcupados(nombresAsigOcupados => [...nombresAsigOcupados, foldersLeer[i].name])
		} // se añaden de nuevo los nombres de las asignaturas
		setIsInput(true)
	}

	const handleInputKeyDown = (e: React.KeyboardEvent) => {
		if(!nombreAsigExiste){
			if (e.key === 'Enter') {
				handleAddNewFolder()
			}
		}
	}

	const comprobarN = async () => {
		let bandera=0
		for(let i=0;i<nombresAsigOcupados.length;i++){
		  if(inputRef.current?.value==nombresAsigOcupados[i]){
			setNombreAsigExiste(true)
			bandera++
		  }
		}
		if(inputRef.current?.value==''){
			setNombreAsigExiste(true)
		  bandera++
		}
		if (bandera==0){
			setNombreAsigExiste(false)
		}
	}

	return (
		<div className='FolderMenu'>
			<div className='header'>
				<h3>Asignaturas</h3>
				<Icon iconName={window.innerWidth > 800 ? 'caretLeft' : 'caretUp'} onClick={() => hide()} />
			</div>
			{folders.map((folder) => (
				<button key={folder.id} className={currentFolder?.id === folder.id ? 'folder_bt_active' : 'folder_bt'} onClick={() => setCurrentFolder(folder)}>
					<Icon iconName='folder' size='15' />
					<h4 style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginRight: '10px' }}>{folder.name}</h4>
				</button>
			))}
			{isInput ? (
				<div className='input_container'>
					<Icon iconName='folder' size='15' />
					<input type='text' ref={inputRef} onKeyDown={handleInputKeyDown} onChange={comprobarN} style={{border:'none', backgroundColor:'var(--color-background)'}}/>
					<Icon iconName='xmark' className={'xmark-icon'} onClick={() => setIsInput(false)} />
					<Icon iconName='check' isClickable={!nombreAsigExiste} className={nombreAsigExiste ? '' : 'icon-green'} 
						onClick={nombreAsigExiste ? (event) => event.preventDefault() : handleAddNewFolder} 
					/>
				</div>
			) : null}
			<button className='add_bt' onClick={() => checkFolderNames()}>
				<Icon iconName='plus' />
				<h4>Nueva Asignatura</h4>
			</button>
		</div>
	)
}