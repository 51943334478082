import { useState, useEffect } from 'react'
import { Card, FlashCard, OcultarCard, TestCard } from '@/types/cards'
import TiptapEditor from './tiptap/TiptapEditor'

/**
 * @prop card - Carta a editar, si se quieres crear una carta nueva no es necesaria
 * @prop onChangeTestCard - función que devuelve el estado de la carta editada cada vez que se actualiza
 */
interface OcultarCardEditorProps {
  card?: Card
  clean?: boolean
  onChangeCard: (flashCard: OcultarCardInit) => void
}

/**
 * @type OcultarCardInit - tipo correspondiente a la creación de una nueva carta de tipo ocultar
 */
export type OcultarCardInit = {
  text: string
}

/**
 * Componente OcultarCardEditor.
 * Se encarga tanto de la edición y la creación de nuevas cartas de tipo ocultar.
 * Contiene el estado ocultarCard, que representa la carta que se esta editando
 * y que se comparte con el componente padre cada vez que se actualiza
 * @param props de la interfaz FormInputProps
 * @returns componente con la interfaz de edición de una carta de tipo ocultar
 */
export default function OcultarCardEditor({ card, clean=false, onChangeCard }: OcultarCardEditorProps) {

  const [ocultarCard, setOcultarCard] = useState<OcultarCard | OcultarCardInit>(
    card && card.type === 'ocultar' ? (card as OcultarCard) : {
      text: card?.type === 'flashcard' ? (card as FlashCard).pregunta + ' ' + (card as FlashCard).respuesta
      : card?.type === 'test' ? (card as TestCard).pregunta : '',
    } 
  ) 
  useEffect(() => { onChangeCard(ocultarCard) }, [ocultarCard])

  return(
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[55vw] max-w-[1000px]'><div className='pl-[20px] font-[600] text-[20px] -mb-2 -mt-1'>Contenido:</div>
      <TiptapEditor 
        className='h-[50vh]  p-3'
        id='ocultarCardText' 
        initialValue={ocultarCard.text} 
        onChange={(content) => setOcultarCard({ ...ocultarCard, text: content })}
        clean={clean}
      /></div>
    </div>
  )
}
