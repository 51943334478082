import { useEffect, useState } from 'react'
import { TestCard } from '@/types/cards'
import  Latex  from 'react-latex-next'

interface TestCardStudyProps {
  card: TestCard 
  onResponse: (correct: boolean) => void
}
// Que la interfaz que contiene la carta y el onResponse sea comun a todos los componentes de estudio

/**
 * Componente TestCardStudy.
 * Se encarga de el estudio de una carta de tipo test.
 * @param props de la interfaz TestCardStudyProps
 * @returns componente con la interfaz de estudio de una carta de tipo test
 */
export default function TestCardStudy({ card, onResponse }: TestCardStudyProps) {

  const [shuffledAnswers, setShuffledAnswers] = useState<{respuesta: string, index: number}[]>([])
  const [chosenOption, setChosenOption] = useState<number | null>(null) 
  const [revealed, setRevealed] = useState<boolean>(false)
  // falta compartir al padre si se ha equivocado o acertado

  useEffect(() => {
    const shuffled = card.respuestas
    .map((respuesta, index) => ({ respuesta, index }))
    .sort(() => Math.random() - 0.5) // Aleatoriza el array
    setShuffledAnswers(shuffled)

    setChosenOption(null)
    setRevealed(false)
  }, [card])
  
  const handleResponse = (index: number) => {
    if (revealed) return
    setChosenOption(index)
    setRevealed(true)
    onResponse(shuffledAnswers[index].index === card.correcta)

  }

  return(
    <>
      <div className='w-full flex flex-col items-center justify-center'>
        <div className='w-[73vw] max-w-[1000px] max-h-[20vh] mb-3'>
          <h3><Latex>{card.pregunta}</Latex></h3>
        </div>
        <div className='overflow-y-auto mt-8 max-h-[35vh]'>
          {shuffledAnswers.map((respuestaObj, index) => (
            <div className='flex flex-row justify-center items-center' key={index}>
              <div 
                className={
                  revealed && respuestaObj.index === card.correcta ? 
                    'flex justify-center items-center mt-3 w-[75vw] max-w-[1000px] border-2 border-solid border-[var(--color-secondary)] rounded-3xl bg-[var(--color-background)] box-border'
                  : revealed && chosenOption === index && respuestaObj.index !== card.correcta ?
                    'flex justify-center items-center mt-3 w-[75vw] max-w-[1000px] border-2 border-solid border-[var(--color-error)] rounded-3xl bg-[var(--color-background)] box-border'
                  : 
                    'flex justify-center items-center mt-3 w-[75vw] max-w-[1000px] border border-solid rounded-3xl bg-[var(--color-background)] cursor-pointer hover:bg-gray-300 box-border'
                }
                onClick={() => handleResponse(index)}
              >
                <div className='m-2 font-medium'><Latex>{respuestaObj.respuesta}</Latex></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}