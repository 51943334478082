import { useEffect, useState } from 'react'
import '@/styles/Spinner.css'
import Spinner from './Spinner'

const frases: string[] = [
  "Tus neuronas se están activando...",
  "Espera un momento... ¿Realmente quieres estudiar?",
  "¡Tranquilo! La carga no es tan lenta como tu comprensión lectora",
  "Si estás cansado de estudiar, recuerda: ¡es mejor que trabajar!",
  "El aprendizaje es un viaje, no un destino. Disfruta del camino",
  "Distrayéndote con datos curiosos inútiles... espera, ¿qué estás estudiando?",
  "A punto de hacerte sentir como un genio... O al menos, menos confundido",
  "Calentando tus neuronas... Esperemos que no se quemen en el proceso",
  "Cargando... al igual que tus ganas de estudiar hoy",
  "Probando tu paciencia... y tu capacidad de evitar abrir otra pestaña",
  "Justo cuando pensabas que no podías posponer más tus estudios, te damos esta pantalla de carga",
  "Si te quedas dormido mientras esperas, no te preocupes, la aplicación seguirá cargando",
  "¡No te preocupes! No es un error, solo estamos cargando tu conocimiento",
  "¿Qué es el conocimiento? La respuesta está en las flashcards...",
  "Aprende como si fueras a vivir siempre, vive como si fueras a morir mañana",
  "¡Eres un crack! No hay flashcard que se te resista",
  "La risa también es beneficiosa para la memoria. ¡No te olvides de reír!",
  "El sueño es esencial para la memoria. ¡Duerme bien!",
  "Si pudieras leer la mente de tu profesor, probablemente te encontrarías con muchas flashcards",
  "El sueño es enemigo del aprendizaje. ¡Duerme menos y estudia más! (Es broma, duerme bien)",
  "Si te da pereza estudiar con flashcards, es que no eres lo suficientemente inteligente. ¡Es broma! (O no...)",
  "Las primeras flashcards se inventaron en 1895. Uno pensaría que a estas alturas cargarían más rápido",
  "Who's gonna carry the boats and the logs? Tú!, tú cargaras con tu aprendizaje",
  '"Vivir es aprender, y todo lo demás es solo una espera." - Keith Richards"',
  '"La educación es el arma más poderosa que puedes usar para cambiar el mundo." - Nelson Mandela',
  '"El aprendizaje nunca agota la mente." - Leonardo da Vinci',
  "Inyectando 3 TB de malware en tu dispositivo... ¡Es broma! (...no?)",
  '"La raíz del aprendizaje es amarga, pero el fruto es dulce." - Aristóteles',
  '"No tengas miedo de crecer lentamente, solo ten miedo de estar estancado." - Proverbio chino',
  '"Si planeas para un año, siembra arroz. Si planeas para una década, planta árboles. Si planeas para la vida, educa a las personas." - Proverbio chino',
]


export default function LoadingTest() {

  const [random, setRandom] = useState(0)

  useEffect(() => {
    setRandom(Math.floor(Math.random() * frases.length))
  }, [])

  return (
    <div className='loadingTest'>
      <h2>{frases[random]}</h2>
      <Spinner />
    </div>
  )
}