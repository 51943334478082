import { ReactNode } from 'react'

export default function BlancLayout({ children } : { children: ReactNode }) {

  return (
    <div className='w-full min-h-full flex bg-[var(--color-background)]'>
        <div className='w-full md:m-5 bg-white md:rounded-[30px]'>
          {children}
        </div>
    </div>
  )
}