import { useState, useEffect } from 'react'

export function useAuth() {
  
  const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const isTokenUpdateExamsExpired = () => {
    const token = localStorage.getItem('updateExamsToken');
    if (!token) {
      return true
    }
  
    try {
      const tokenData = JSON.parse(atob(token))
      const currentTime = Date.now()
      return currentTime > tokenData.expiresAt
    } catch (error) {
      console.error('Error decoding token:', error)
      return true
    }
  };

  const fetchAuthState = async () => {
    setLoading(true)
    // no se si esto va bien, pues accede pero por el cors da error y ahora con el proxy da 404
    
  try{
    
    const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/auth_state`, {
      method: 'GET',
      credentials: 'include', // BORRAR EN PRODUCCION
    })
    if (!response.ok){
      throw new Error('Error al iniciar sesion')
    }
    const data = await response.json()

    if(data.valor){
      if (isTokenUpdateExamsExpired()) {//isTokenUpdateExamsExpired()
        const secondResponse = await fetch(
          `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
          {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', },
              body: JSON.stringify({}),
              credentials: 'include'
          }
        ) 

        if (!secondResponse.ok && secondResponse.status !== 401){
          console.log(secondResponse)
          throw new Error('Error al actualizar los examenes');
        }	

        const now = new Date();
        const expirationDate = new Date(now);
        expirationDate.setHours(23, 59, 59, 999); // Establecer la hora a las 23:59:59 del mismo día
      
        const expiresIn = expirationDate.getTime() - now.getTime();
        const tokenData = {
          data: 'yourData',
          expiresAt: Date.now() + expiresIn,
        };
        const token = btoa(JSON.stringify(tokenData));
        localStorage.setItem('updateExamsToken', token);
      }
    }
    setIsAuth(data.valor)
  } catch (error) {
      console.error('Error:', error)
  }

    

  }

  useEffect(() => {
    if (isAuth !== undefined)
      setLoading(false)
  }, [isAuth])

  useEffect(() => {
    fetchAuthState();
  }, [])

  return { fetchAuthState, isAuth, loading }

}