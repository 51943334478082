import { useState, ImgHTMLAttributes, ReactNode } from 'react'
import './Image.css'

/**
 * @property {string} src - Image source URL.
 * @property {string} alt - Alternative text for the image, SEO need.
 * @property {string} [width] - Width of the image.
 * @property {string} [height] - Height of the image.
 * @property {string} [className] - Additional CSS classes for the image container.
 * @property {ReactNode} [placeholder] - Element to display while the image is loading.
 */
interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
  width?: string
  height?: string
  className?: string
  placeholder?: ReactNode
}

/**
 * Image component that displays an image applying lazy loading and sets some other props to improve performance,
 * such as mandatory {alt} text to improve SEO, and an optional {placeholder} to display while the image is loading.
 * 
 * @param {ImageProps} props - Props for the Image component, wich includes {...props} as the rest of the
 * ImgHTMLAttributes props. These ImgHTMLAttributes props also includes de HTMLAttributes props (like onLoad).
 * To improve this component see all the props that can be passed to it in the link below:
 * @see {@link https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts#L3250}
 */
export default function Image({ src, alt, width, height, placeholder, className, ...props }: ImageProps) {

  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div                
      style={{ 
        position: 'relative', 
        width: width && width, 
        height: height && height
      }}
      className={className}
    >
      { !isLoaded && 
        <div className='absolute w-full h-full rounded-3xl'>
          { placeholder }
        </div>
      }
      <img
        style={{ 
          visibility: isLoaded ? 'visible' : 'hidden',
          opacity: isLoaded ? 1 : 0.5, 
          transition: 'opacity 0.3s ease-in-out' 
        }}
        onLoad={() => setIsLoaded(true)}
        className='w-full h-full'
        loading='lazy'
        src={src}
        alt={alt}
        {...props}
      />
    </div>
  )
}
