import { useEffect, useState } from 'react'
import { usePomodoro } from '@/context/ClockContext'
import Icon from '@/components/icons/Icon'
import dingSound from '@/assets/positive.mp3'
import './Header.css'
import { Link, useLocation} from 'react-router-dom'

export default function HeaderClock() {

  const [timeLeftClock, setTimeLeftClock] = useState(0)

  const { timeLeft, setTimeLeft, dateLeft, setDateLeft , running, setRunning, started, setStarted } = usePomodoro()

  const location = useLocation()
	const [currentPath, setCurrentPath] = useState('')
  
  useEffect(() => {
		setCurrentPath(location.pathname)
	}, [location.pathname])

  useEffect(() => {
    const interval = setInterval(() => {
      if(started) {
        if(running) { 
          const now = new Date().getTime()
          const elapsed = (now - dateLeft)/ 1000
          const newTimeLeft = Number(timeLeft)*60 - elapsed
          if(newTimeLeft>0) {
           setTimeLeftClock(newTimeLeft/60)
          } else {
            setTimeLeftClock(0)
            const tiempoLocal=localStorage.getItem('tiempoAlEmpezar')
            const pomTdesF=localStorage.getItem('pomTdesF')
            const bellSound = new Audio(dingSound)
            bellSound.play().catch(error => console.error('Error playing sound:', error))
            if(tiempoLocal && pomTdesF === "true") {
              updateUserStats(tiempoLocal)
            }
            clearInterval(interval)
            setTimeLeft(0)
            setRunning(false)
            setStarted(false)
            setDateLeft(0)
            localStorage.removeItem('tiempoAlEmpezar')
            localStorage.removeItem('pomodoroTime')
            localStorage.removeItem('timeLeft')
            localStorage.removeItem('running')
            localStorage.removeItem('pomTdesF')
          }
        } else if(!running) {
          clearInterval(interval)
          setTimeLeftClock(timeLeft)
        }
      } else{
        setTimeLeftClock(0)
        clearInterval(interval)
      }
    }, 100)
    return () => clearInterval(interval)
  }, [started, running, dateLeft, timeLeft])

  const updateUserStats = async (tiempoLocal: any) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/estadisticas/update_stats?minEstudioHoy=${tiempoLocal}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        }
      );
      if (!response.ok) {
        throw new Error('Error al actualizar las estadisticas')
      }
    } catch (error) {
        console.error('Error:', error)
    }
  }

  const formatTime = (minutesVar: number) => {
    const seconds = Number(((minutesVar - Math.floor(minutesVar)) * 60).toFixed(0))
    const minutes = Math.floor(minutesVar)
    if (seconds == 60)
      return `${minutes < 10 ? '0' : ''}${minutes + 1}:00`
    else
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  return (
    <Link 
      to='/organizacion'
      state={{ pagina: 2 }}
      className={currentPath === '/organizacion' ? 'active' : ''}>
    <button className='header-clock'>
        <Icon iconName='clock' size='22'/> 
        <h4>{formatTime(timeLeftClock)}</h4>
    </button>
    </Link>
  )
}