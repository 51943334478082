import * as pdfjsLib from 'pdfjs-dist';
import { TextMarkedContent } from 'pdfjs-dist/types/src/display/api';
//const pdfjs = await import('pdfjs-dist/build/pdf.min.mjs');
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

interface TextItem {
    str: string;
    dir: string;
    width: number;
    height: number;
    transform: number[];
    // Incluir otras propiedades según sea necesario
}

export const Reader = async (file: File) => {
    
    function pdfToText(separator = ' ') {
        // Create an object URL for the file
        const objectURL = URL.createObjectURL(file);

        let pdf = pdfjsLib.getDocument(objectURL);
        
        return pdf.promise.then(function(pdf) {
            let maxPages = pdf._pdfInfo.numPages;
            let countPromises = []; // collecting all page promises
            for (let i = 1; i <= maxPages; i++) {
                let page = pdf.getPage(i);
                countPromises.push(page.then(function(page) {
                    let textContent = page.getTextContent();
                    return textContent.then(function(text) {
                        return text.items.map(function(obj:TextItem | TextMarkedContent) {
                            if (obj.hasOwnProperty('str')){
                                let obj1= obj as TextItem;
                                return obj1.str;
                            }                            
                        }).join(separator); // value page text
                    });
                }).catch(error => {
                    console.error(`Error processing page ${i}`, error);
                    return ''; // Return an empty string or any error indicator for this page
                }));
            };
            // wait for all pages and join text
            return Promise.all(countPromises).then(function(texts) {
                for(let i = 0; i < texts.length; i++){
                    texts[i] = texts[i].replace(/\s+/g, ' ').trim();
                };
                // Revoke the object URL to clean up memory
                URL.revokeObjectURL(objectURL);
                return texts;
            });
        });
    }
    

    const pdfTexts = await pdfToText(); // Make sure pdfToText is an async function or returns a Promise
    return pdfTexts; // This will now wait for pdfTexts to be resolved before returning

}

