import React from 'react';
import FormInput from "@/components/forms/core/FormInput";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {useNavigate} from "react-router-dom";
import {useLogin} from "@/api/auth/login/useLogin";
import * as Yup from "yup";
import Form from "@/components/forms/core/Form";

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .min(2)
        .max(50)
        .required('Required'),
    password: Yup.string()
        .min(6)
        .max(50)
        .required(),
});

function LoginForm() {
    const navigate = useNavigate()
    const {mutateAsync, error} = useLogin({
        onSuccess: () => handleSuccess()
    })

    function handleSuccess() {
        const firstLogin = localStorage.getItem('firstLogin');
        const qrCodeImport = sessionStorage.getItem('qrCodeImport');
        if (firstLogin === 'true') navigate('/tutorial')
        else if (sessionStorage.getItem('qrCodeImport')) navigate(`/codigos/${qrCodeImport}`)
        else navigate('/dashboard')
    }


    return (
        <Form
            validationSchema={loginSchema}
            fetchErrors={error?.error}
            initialValues={{email : "", password : ""}}
            onSubmit={async (values) => { await mutateAsync({email: values.email, password: values.password})}}
            className="text-left flex flex-col gap-4"
        >
            <FormInput 
                className='h-10'
                label="Correo / Nombre de usuario"
                name="email"
            ></FormInput>
            <FormInput 
                className='h-10'
                type="password"
                label="Contraseña"
                name="password"
            ></FormInput>
            <FormSubmitButton className="w-full">Iniciar sesión</FormSubmitButton>
        </Form>
    );
}

export default LoginForm;