import { useEffect, useState } from 'react'
import { Subscription, Plan } from '@/types/types'
import { useNavigate } from 'react-router-dom'
import Icon from '@/components/icons/Icon'

interface ExchangeInfoProps {
  subscription: Subscription | undefined
  plan: Plan | undefined
  tokens: number | undefined
  costInTokens: number
}

const TOKENSPERPAGE = 1000

export default function ExchangeInfo ({ subscription, plan, tokens, costInTokens }: ExchangeInfoProps) {

  const navigate = useNavigate()

  const [freePages, setFreePages] = useState<number | undefined>(undefined)

  useEffect (() => {
    if (subscription) {
      let pages = Math.floor(subscription?.tokens/TOKENSPERPAGE)
      if (subscription.tokens%TOKENSPERPAGE > 0) pages++
      setFreePages(pages)
    }
  }, [costInTokens])

  
  return (
    <div>
      { subscription &&
        <div className='max-w-[225px] h-[40px] bg-white rounded-xl p-[5px] mb-2 border-solid border-3 border-[var(--color-green-secondary)] flex flex-col items-center'>
          <h4 className='text-[var(--color-green-secondary)] font-bold'>Plan {plan?.name}</h4>
          <h5 className='font-normal m-0'>{freePages} página{freePages != 1 && 's'} gratis restante{freePages != 1 && 's'}</h5>
        </div>
      }
      { (subscription==undefined || (tokens!=undefined && tokens >= 0 && subscription.tokens < costInTokens)) &&
        <div className='flex flex-col gap-1 items-start text-right'>
          <div className='flex flex-row gap-2 items-center'>
            <label>{subscription ? 'Coste páginas extra:' : 'Coste de la importación:'}</label>
            <div className='flex flex-row gap-1 items-center'>
              <h3>
                { subscription ? 
                  costInTokens > 0 && `${costInTokens-subscription.tokens}` :
                  costInTokens > 0 && `${costInTokens}`
                }
              </h3>
              <Icon iconName='token' className='text-[var(--color-secondary)]' isClickable={false}/>
            </div>
          </div>
          <div className='flex flex-row gap-2 items-center'>
            <label>Tokens restantes:</label>
            <div className='flex flex-row gap-1 items-center'>
              <div className='flex flex-row items-center gap-1'>
                { tokens === undefined ? 'loading...' : <>
                  <h3 className={tokens < costInTokens ? 'text-[var(--color-error)]': ''}>
                    {subscription ? tokens - (costInTokens - subscription.tokens) : tokens - costInTokens}
                  </h3>
                </>}
              </div>
              <Icon iconName='token' className='text-[var(--color-secondary)]' isClickable={false}/>
            </div>
          </div>
        </div>
      }
      { ((!subscription && (tokens!=undefined && tokens <= costInTokens)) || (subscription && tokens!=undefined && tokens+subscription.tokens < costInTokens)) &&
        <button 
          className='btn btn-primary -mb-5 mt-2'
          onClick={() => navigate('/pricing')}
        >Comprar</button>
      }
    </div>
  )
}
