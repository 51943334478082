import { useState, useEffect } from 'react'
import Checkbox from '@/components/common/ui/Checkbox'
import TiptapEditor from './tiptap/TiptapEditor'
import { TestCard } from '@/types/cards'

/**
 * @prop card - Carta a editar, si se quieres crear una carta nueva no es necesaria
 * @prop onChangeTestCard - función que devuelve el estado de la carta editada cada vez que se actualiza
 */
interface TestCardEditorProps {
  card?: TestCard 
  clean?: boolean
  onChangeCard: (testCard: TestCardInit) => void
}

/**
 * @type TextCardInit - tipo correspondiente a la creación de una nueva carta de tipo test
 */
export type TestCardInit = {
  pregunta: string
  respuestas: string[]
  correcta: number
}

/**
 * Componente TestCardEditor.
 * Se encarga tanto de la edición y la creación de nuevas cartas de tipo test.
 * Contiene el estado testCard, que representa la carta que se esta editando
 * y que se comparte con el componente padre cada vez que se actualiza
 * @param props de la interfaz FormInputProps
 * @returns componente con la interfaz de edición de una carta de tipo test
 */
export default function TestCardEditor({ card, clean=false, onChangeCard }: TestCardEditorProps) {

  // CARD DA NULL CUANDO ES CREAR UNA NUEVA
  const [testCard, setTestCard] = useState<TestCard | TestCardInit>(
    card && card.type === 'test' ? card : {
      pregunta: card?.pregunta || '',
      respuestas: ['', '', '', ''],
      correcta: 0
    }
  ) 
  useEffect(() => { onChangeCard(testCard) }, [testCard])

  const handleOptionChange = (index: number, newValue: string) => {
    const newRespuestas = [...testCard.respuestas]
    newRespuestas[index] = newValue
    setTestCard({ ...testCard, respuestas: newRespuestas })
  }

  return(
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-[77vw] pl-5 max-w-[1000px]'>
        <div className='pl-[20px] font-[600] text-[20px] -mb-2 -mt-1'>Pregunta:</div>
          <TiptapEditor 
            className='h-[15vh] md:h-[26vh]  m-0 p-3'
            id='testPregunta' 
            initialValue={testCard.pregunta}
            onChange={(content) => setTestCard({ ...testCard, pregunta: content })}
            clean={clean}
          />
        <div className='pl-[20px] font-[600] text-[20px] -mb-1'>Opciones:</div>
      </div>
      {testCard.respuestas.map((respuesta, index) => (
        <div key={index} className='flex flex-row justify-center items-center pl-[20px]'>
          <div className='-ml-8'>
            <Checkbox 
              getChecked={() => setTestCard({ ...testCard, correcta: index})} 
              changeChecked={testCard.correcta === index} 
              irreversible={true}
            />
          </div>
          <div className={testCard.correcta !== index ? 'testOption' : 'testOption-correcta'}>
            <TiptapEditor 
              className='w-[75vw] max-w-[1000px] p-3'
              id={`testRespuesta${index}`} 
              initialValue={respuesta}
              onChange={(content) => handleOptionChange(index, content)}
              clean={clean}
            />
          </div>
        </div>
      ))}
    </div>
  )
}