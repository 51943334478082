import { useEffect } from 'react'
import { useEditors } from '@/context/EditorsContext'
import { useEditor, EditorContent } from '@tiptap/react'
import Text from '@tiptap/extension-text'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import Code from '@tiptap/extension-code'
import Ocultar from './ocultar'
import './tiptap.css'

const extensions = [Text, Document, Paragraph, Bold, Italic, Underline, Strike, Code, Ocultar]

interface TiptapProps {
  id: string
  initialValue: string
  className?: string
  clean?: boolean
  onChange?: (content: string) => void
}

// El tiptap tiene un className .tiptap con el que cambiar el css
export default function TiptapEditor({ id, initialValue, className, clean=false, onChange }: TiptapProps) {

  const { addEditor, setSelectedEditorId } = useEditors()

  const editor = useEditor({
    extensions: extensions,
    content: initialValue,
    onUpdate: ({ editor }) => { 
      if (onChange) onChange(editor.getHTML()) 
    }
  })

  useEffect(() => {
    if (editor) addEditor(id, editor)
    return () => editor?.destroy()
  }, [editor])

  useEffect(() => {
    if (clean && editor) editor.commands.setContent('')
  }, [clean])

  return (
    <div 
      className={className ? className+' tiptapWrapper': 'tiptapWrapper'}
      onClick={() => setSelectedEditorId(id)}
    >
      <EditorContent editor={editor} />
    </div>
  )
}
