import { SetStateAction, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

interface OrganizacionMenuProps {
    buttonChange:(selectedButton: number) => void
    tiempoPomodoro: (tiempoPomodoro: number) => void;
    tiempoDescanso: (tiempoDescanso: number) => void;
}


export default function OrganizacionMenu({buttonChange,tiempoPomodoro, tiempoDescanso}: OrganizacionMenuProps){

    const location = useLocation()
    const navigate = useNavigate()
    const { pagina } = location.state || {}
    
    const [selectedButton, setSelectedButton] = useState<number>(pagina?parseInt(pagina):1);
    const pomodoroButtonRef = useRef(null);
    const tareasButtonRef = useRef(null);
    const [buttonWidth2, setButtonWidth2] = useState(0);
    const [buttonWidth1, setButtonWidth1] = useState(0);

    const [pomodoroTime, setPomodoroTime] = useState('25.00'); // Valor por defecto de 25 minutos
    const [descansoTime, setDescansoTime] = useState('5.00'); // Valor por defecto de 5 minutos
    const [isPomodoroFocused, setIsPomodoroFocused] = useState(false);
    const [isDescansoFocused, setIsDescansoFocused] = useState(false);

    useEffect(() => {
        if (pagina !== null && pagina !== undefined) {
          setSelectedButton(parseInt(pagina))
          navigate(location.pathname, { state: { ...location.state, pagina: null }, replace: true })
        }
      }, [pagina, history, location])


    useEffect(() => {
        if (pomodoroButtonRef.current) {
            setButtonWidth2((pomodoroButtonRef.current as HTMLButtonElement).offsetWidth);
        }
    }, [pomodoroButtonRef.current]);

    useEffect(() => {
        if (tareasButtonRef.current) {
            setButtonWidth1((tareasButtonRef.current as HTMLButtonElement).offsetWidth);
        }
    }, [tareasButtonRef.current]);

    useEffect(() => {
        if(selectedButton){
            buttonChange(selectedButton)
        }
    }, [selectedButton]);


    const handleButtonClick = (buttonIndex: SetStateAction<number>) => {
        setSelectedButton(buttonIndex);
    };

    return (
        <>
            
            <div className='flex justify-between flex-col md:flex-row'>
                <div style={{display: 'flex',flexDirection: 'column',}}>
                    <div className="text-[30px] font-bold pt-3 pb-0 mb:pl-8 pl-5 text-[var(--color-text)]">
                        Panel de Organización
                    </div>
                    <div style={{ paddingLeft: '15px',fontSize: '18px' }}>
                        <button
                            ref={tareasButtonRef}
                            onClick={() => handleButtonClick(1)}
                            style={{ 
                                color: selectedButton === 1 ? 'var(--color-secondary)' : 'var(--color-text)',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                transition: 'color 0.5s ease'
                            }}
                        >
                            Tareas Pendientes
                        </button>
                        <button
                            ref={pomodoroButtonRef}
                            onClick={() => handleButtonClick(2)}
                            style={{ 
                                color: selectedButton === 2 ? 'var(--color-secondary)' : 'var(--color-text)',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                transition: 'color 0.5s ease'
                            }}
                        >
                            Pomodoro
                        </button> 
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start',paddingLeft: '15px' }}>
                        <div
                            style={{
                                height: '1.5px',
                                backgroundColor: selectedButton == 1 ? 'var(--color-secondary)' : '#ddd',
                                width: `${buttonWidth1}px`,
                                transition: 'background-color 0.5s ease',
                                zIndex:1
                            }}
                        />
                        <div
                            style={{
                                height: '1.5px',
                                backgroundColor: selectedButton == 2 ? 'var(--color-secondary)' : '#ddd',
                                width: `${buttonWidth2}px`,
                                transition: 'background-color 0.5s ease',
                                zIndex:1
                            }}
                        />
                    </div>
                </div>
                <div className=" h-[1.5px] md:h-0 md:w-0 bg-[#ddd] mt-[-1.5px] ml-[15px] w-[88%]"/>
                { selectedButton == 2 && 
                    <div className="flex md:justify-between md:mr-[8vw] justify-center mt-5 md:mt-0 mx-2">
                    <div className='flex justify-center flex-col items-center mr-6 md:mr-12'>
                        <label htmlFor="tiempoPomodoro" style={{ fontWeight: 'bold', color: isPomodoroFocused ? 'var(--color-text)' : 'var(--color-text-light)'  }}>
                            Tiempo de Pomodoro
                        </label>
                        <input
                            type="text"
                            id="tiempoPomodoro"
                            value={pomodoroTime}
                            onChange={(e) => {
                                setPomodoroTime(e.target.value);
                            }}
                            onFocus={() => setIsPomodoroFocused(true)}
                            onBlur={(e) => {
                                setIsPomodoroFocused(false);
                                const value = parseFloat(e.target.value);
                                if (isNaN(value) || value <= 0) {
                                    setPomodoroTime('25.00'); 
                                    tiempoPomodoro(25);
                                } else if (value > 999) {
                                    setPomodoroTime('999.00'); 
                                    tiempoPomodoro(999);
                                }else {
                                    tiempoPomodoro(value);
                                }
                            }}
                            style={{ 
                                width: '140px', 
                                height: '50px', 
                                marginTop: '5px', 
                                fontSize: '25px', 
                                textAlign: 'center', 
                                borderColor: isPomodoroFocused ? 'var(--color-text-light)' : '#ccc',
                                color: isPomodoroFocused ? 'var(--color-text)' : 'var(--color-text-light)' 
                            }} 
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <label htmlFor="tiempoDescanso" style={{ fontWeight: 'bold', color: isDescansoFocused ? 'var(--color-text)' : 'var(--color-text-light)'  }}>
                            Tiempo de Descanso
                        </label>
                        <input
                            type="text"
                            id="tiempoDescanso"
                            value={descansoTime}
                            onChange={(e) => {
                                setDescansoTime(e.target.value);
                            }}
                            onFocus={() => setIsDescansoFocused(true)}
                            onBlur={(e) => {
                                setIsDescansoFocused(false);
                                const value = parseFloat(e.target.value);
                                if (isNaN(value) || value <= 0) {
                                    setDescansoTime('5.00'); // Set to a default positive value
                                    tiempoDescanso(5);
                                } else if (value > 999) {
                                    setDescansoTime('999.00'); 
                                    tiempoDescanso(999);
                                }else {
                                    tiempoDescanso(value);
                                }
                            }}
                            style={{ 
                                width: '140px', 
                                height: '50px', 
                                marginTop: '5px', 
                                fontSize: '25px', 
                                textAlign: 'center', 
                                borderColor: isDescansoFocused ? 'var(--color-text-light)' : '#ccc',
                                color: isDescansoFocused ? 'var(--color-text)' : 'var(--color-text-light)' 
                            }} 
                        />
                    </div>
                </div>}
            </div>
            <div className=" md:h-[1.5px] bg-[#ddd] mt-[-1.5px] ml-[15px] w-[90%]"/>
        </>
    )
    
}
