import { createContext, useContext, useState, ReactNode } from 'react'

/**
 * Creacion del contexto de actualización de folders
 * @type UpdateFoldersContextType - Tipo de dato que contiene el contexto
 * @property updateFolders - Indica si se debe actualizar la lista de folders
 * @property setUpdateFolders - Función para cambiar el valor de 'updateFolders'
 * @see FolderMenu.tsx - Componente que actuliza la lista de folders
 */
type UpdateFoldersContextType = {
  updateFolders: boolean
  setUpdateFolders: (update: boolean) => void
}
const UpdateFoldersContext = createContext<UpdateFoldersContextType | undefined>(undefined)

/**
 * Proveedor del contexto de actualización de folders
 * @param children - Componentes con acceso al contexto
 */
interface UpdateFoldersProviderProps {
  children: ReactNode
}
export const UpdateFoldersProvider = ({ children }: UpdateFoldersProviderProps) => {
  const [updateFolders, setUpdateFolders] = useState(false)
  return (
    <UpdateFoldersContext.Provider value={{ updateFolders, setUpdateFolders }}>
      {children}
    </UpdateFoldersContext.Provider>
  )
}

/**
 * Hook para acceder al contexto de actualización de folders
 * @returns contexto de tipo 'UpdateFoldersContextType'
 */
export const useUpdateFolders = () => {
  const context = useContext(UpdateFoldersContext)
  if (!context) throw new Error('useUpdateFolders hook is called outside the UpdateFoldersProvider component')
  return context
}

export default UpdateFoldersContext