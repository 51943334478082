import { Plan, Subscription,  UserData } from '@/types/types';
import PricingCardHome from './PricingCardHome';
import { useNavigate } from 'react-router-dom'
import { getDaysDiff } from '@/utils/dateFunctions'

interface HomeEstadisticasBottomTopRight {
    user: UserData | null;
    currentPlan: Plan 
    subscription: Subscription | null;
}

export const HomeEstadisticasTopRight = ({ user, currentPlan, subscription }: HomeEstadisticasBottomTopRight) => {
    const navigate = useNavigate()
    
  return (
    <div className='flex flex-col items-center justify-center'>
              <div className='flex flex-row items-center justify-center'>
                <div>
                  <div className='w-[6rem] h-[6rem] rounded-full bg-[var(--color-medium)] text-lg text-white my-2.5 mx-3 flex items-center justify-center'>
                    <h2>{user?.nombre.charAt(0).toUpperCase()}</h2>
                    <h2>{user?.apellidos.charAt(0).toUpperCase()}</h2>
                  </div>
                </div>
                <div className='w-2/3'>
                <h3 className='my-0 mx-auto text-center text-[var(--color-secondary)] text-3xl'>
                {`${user?.nombre} ${user?.apellidos}`}
                  </h3>
                  <h4 className='my-0 mx-auto text-center text-[var(--color-medium)]'>
                    {`Estudiante de ${user?.titulacion} en ${user?.centro_estudios}`}
                  </h4>
                </div>
              </div>
              <div className='w-full mt-5 flex flex-col items-center'>
                <div className='w-4/5 max-w-[450px] h-full'>
                  <PricingCardHome 
                    name={currentPlan.name} 
                    duration={currentPlan.duration} 
                    price={currentPlan.price} 
                    current={true}
                    renew={subscription == null ? true : subscription?.renew}
                    daysToExpire={
                      subscription != null && subscription?.renew == false ? 
                          getDaysDiff(new Date(subscription!.end_date), new Date()) : 
                          undefined
                    }
                    callToAction={'Plan Actual'}
                    features={currentPlan.features} 
                    height='auto'
                  />
                </div>
                <button 
                  className='mt-6 px-5 py-3 font-semibold text-2xl'
                  onClick={() => navigate('/pricing')}
                >
                  Cambiar Plan
                </button>
              </div>
            </div>
  );
  
};
