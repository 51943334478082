import { useNavigate } from 'react-router-dom'
import greenlogo from '@/assets/greenlogo.svg'
import SEO from '@/components/SEO'
import '../styles/Error404.css'

/**
 * Página de error 404, se muestra cuando el usuario intenta acceder a una página que no existe,
 * y permite volver a la página anterior (navigate(-1)).
 * Está página no es indexada por los motores de búsqueda.
 */
export default function Error404(){

    const navigate = useNavigate()

    return (<>
        <SEO 
            title='Error 404 - Bilern' 
            meta={<meta name='robots' />}
        />
        <div className='error'>
            <img src={greenlogo} alt='greenlogo'/>
            <h2>Error 404: No deberías estar aquí...</h2>
            <button onClick={() => navigate(-1)}>Volver</button>
        </div>
    </>)

}

