import { useEffect, useState } from 'react'
import FormInputRow from '@/components/common/ui/FormInputRow'
import FormInput from '@/components/common/ui/FormInput'
import Textarea from '@/components/common/ui/Textarea'
import * as validaciones from '../utils/validaciones'
import '../styles/ContactForm.css'

export const ContactForm = () => {

	const [name, setName] = useState<String>('')
	const [surname, setSurname] = useState<String>('')
	const [email, setEmail] = useState<String>('')
	const [message, setMessage] = useState<String>('')
	const [submitted, setSubmitted] = useState(false)
	const [statusMessage, setStatusMessage] = useState('')

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false)
				setStatusMessage('')
			}, 5000)
		}
	}, [submitted])

	const submitMessage = async (event: React.FormEvent) => {
		event.preventDefault()
		if (name !== undefined && surname !== undefined && email !== undefined && message !== undefined) {
			let statesToCheck = [name, surname, email, message]
			const results: boolean[] = []
			statesToCheck.forEach((state) => results.push(state == '' ? false : true))
			if (results.every((result) => result == true)) {
				try {
					const response = await fetch(
						`${import.meta.env.VITE_API_URL}/feedback/store`, 
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({ 'nombre': name, 'apellidos': surname, 'email': email, 'message': message }),
						}
					)
					if (!response.ok)
						throw new Error('Error en el envio del correo')
					} catch (error) {
						console.error('Error:', error)
				}
				setSubmitted(true)
				setName('')
				setSurname('')
				setEmail('')
				setMessage('')
				setStatusMessage('Feedback recibido! Gracias por tu mensaje!')
			} else {
				setStatusMessage('Por favor, rellena todos los campos')
			}
		}
	}

	return (
		<form className='w-full xl:w-1/3 max-w-2xl flex flex-col m-12 ml-20'>
			<FormInputRow>
				<FormInput type='text' name='name' constraint={validaciones.checkNameFormat} getInput={setName} clear={submitted}>
					Nombre:
				</FormInput>
				<FormInput type='text' name='surname' constraint={validaciones.checkNameFormat} getInput={setSurname} clear={submitted}>
					Apellidos:
				</FormInput>
			</FormInputRow>
			<FormInput type='email' name='email' constraint={validaciones.checkEmailFormat} getInput={setEmail} clear={submitted}>
				Correo Electrónico:
			</FormInput>
			<Textarea height={'h-48'} getText={setMessage} clear={submitted}>
				Mensaje:
			</Textarea>
			<div className='w-full flex justify-center items-center pt-5 pb-5'>
				<button 
					className='w-60 bg-primary-color'
					onClick={(e) => submitMessage(e)}
				>Enviar</button>
			</div>
			<h4 className={submitted ? 'h-4 text-secondary-color font-bold': 'h-4 text-error-color font-bold'}>
				{statusMessage}
			</h4>
		</form>
	)
}