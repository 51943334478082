import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserData } from '@/types/types'
import DefaultLayout from '@/components/layout/DefaultLayout'
import SectionLayout from '@/components/layout/SectionLayout'
import { Subscription, Plan } from '@/types/types'
import { HomeEstadisticasTopLeft } from '@/components/HomeEstadisticasTopLeft'
import { HomeEstadisticasBottomLeft } from '@/components/HomeEstadisticasBottomLeft'
import { HomeEstadisticasTopRight } from '@/components/HomeEstadisticasTopRight'
import Study from '@/components/pages/study/Study'
import { Card } from '@/types/cards'

export default function Home() {

  const navigate = useNavigate()
	const [user, setUser] = useState<UserData | null>(null)
  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const [currentPlan, setCurrentPlan] = useState<Plan>({
    id: '',
    name: 'Gratuito',
    price: 0,
    duration: 1,
    features: [
      "Creación de flashcards de forma manual",
      "Organización y Pomodoro",
      "Checklist de Tareas"
    ]
  })

  const [inExam, setInExam] = useState<boolean>(false)
  const [cardsExam, setCardsExam] = useState<Card[] | null>(null)
  const [modoExam, setModoExam] = useState<number | null>(null)

	
  // TODO EN VEZ DE ESTA MIERDA GUARDAR EL USUARIO EN EL LOCALSTORAGE
  useEffect(() => {
    const fetchData = async() => {
      let response = null
      try{
        response = await fetch(
          `${import.meta.env.VITE_API_URL}/user/data`, 
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', },
            credentials: 'include',
          }
        )
        if(!response.ok){
          throw new Error('Error al obtener los datos del usuario')
        }
        const userData = await response.json()
        setUser(userData)
        response = await fetch(
          `${import.meta.env.VITE_API_URL}/user/subscription`, 
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', },
            credentials: 'include',
          }
        )
        if(!response.ok){
          throw new Error('Error al obtener los datos del usuario')
        }
        const subData = await response.json()
        if (subData !== null) setSubscription(subData)
        response = await fetch(
          `${import.meta.env.VITE_API_URL}/user/subscription_plan`, 
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', }, 
            credentials: 'include',
          }
        )
        if(!response.ok){
          throw new Error('Error al obtener los datos del usuario')
        }
        const planData = await response.json()
        if (planData !== null) setCurrentPlan(planData)
      } catch(error){
        console.error('Error:', error)
      }
    }
		fetchData()
	}, [])

  return (
    <DefaultLayout>
      { inExam ?
        <Study 
          cartas={cardsExam}
          modo={modoExam} 
          examen={null} time={null}
          onClickReturn={()=>setInExam(false)}
        />
      :
        <>
          <h2 className='w-full mt-5 flex justify-center md:justify-start md:pl-5 text-text-color text-2xl md:text-3xl'>
            ¡Bienvenido de nuevo {user?.nombre}!
          </h2>
            <div className='flex flex-col lg:flex-row'>
              <div className='w-full lg:w-3/5 flex flex-col'>
              <div className='bg-[var(--color-background)] rounded-xl p-3 m-5 flex-grow h-auto lg:h-[80vh]'>
                <HomeEstadisticasTopLeft user={user} />
              </div>
              <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', height:'100%'}}>
              <SectionLayout width='100%'>
                <HomeEstadisticasBottomLeft estudio={(tarjetas, modo) => { setCardsExam(tarjetas); setModoExam(modo); setInExam(true)}}/>
              </SectionLayout>
              </div>
            </div>
            <div className='w-full lg:w-2/5 flex flex-col'>
              <SectionLayout>
                <HomeEstadisticasTopRight
                  user={user}
                  currentPlan={currentPlan}
                  subscription={subscription}
                />
              </SectionLayout>
            </div>
          </div>
        </>
      }
    </DefaultLayout>
  )
   

}