import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import FormInput from '@/components/common/ui/FormInput'
import FormInputRow from '@/components/common/ui/FormInputRow'
import { Datalist } from '@/components/common/ui/Datalist'
import { PasswordCheckInputs } from '@/components/common/ui/PasswordCheckInputs'
import * as validaciones from '@/utils/validaciones'
import '@/styles/Register.css'
import landingImage from '../assets/landingImage.svg'
import { set } from 'date-fns'

export default function Register() {

	const navigate = useNavigate()
	const [currentStep, setCurrentStep] = useState(1)

	const [name, setName] = useState('')
	const [surname, setSurname] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [username, setUsername] = useState('')
	const [birthday, setBirthday] = useState('')
	const [location, setLocation] = useState('')
	const [tipoEstudios, setTipoEstudios] = useState('grado')
	const [centroEstudios, setCentroEstudios] = useState('')
	const [titulacion, setTitulacion] = useState('')
	const [objetivo, setObjetivo] = useState('')
	const [terminosAceptados, setTerminosAceptados] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [campoMailValido, setCampoMailValido] = useState(false)
	const [campoUserValido, setCampoUserValido] = useState(false)

	const submitUserForm = async () => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/auth/register`, 
				{
					method: 'POST',
					headers: {
					'Content-Type': 'application/json',
					},
					body: JSON.stringify(
						{
							correo: email, 
							password: password, 
							usuario: username, 
							nombre: name,
							apellidos: surname, 
							nacimiento: birthday, 
							ubicacion: location, 
							tipo_estudios: tipoEstudios,
							centro_estudios: centroEstudios, 
							titulacion: titulacion, 
							objetivo: objetivo
						}
					),
					credentials: 'include',
			  }
			)
			if (!response.ok){
			  throw new Error('Error al registrar usuario')
			}			
			localStorage.setItem('firstLogin', 'true')
			setCurrentStep(3)
		  } catch (error) {
				console.error('Error:', error)
		  }
	}

	const handleSetEmail = async (input: string) => {
		setEmail(input)
		return true
	}

	const handleSetUsername = async (input: string) => {
		setUsername(input)
		return true
	}

	const handleSetBirthday = async (input: string) => {
			const [day, month, year] = input.split("/");
			const newFormatBirthday = `${year}-${month}-${day}`
			setBirthday(newFormatBirthday)
			return true
	}

	const handleNextStep = async () => {
		let statesToCheck = [name, surname, email, username, birthday, password]
		const results: boolean[] = []
		statesToCheck.forEach((state) => results.push(state == '' ? false : true))
		if (results.every((result) => result == true)){
			if(campoMailValido && campoUserValido){
				setErrorMessage('')
				setCurrentStep(2)
			}
		}
		else {
			setErrorMessage('Debes completar todos los campos')
		}
	}

	const handleRedirect = () => {
		navigate('/login')
	}

	return (
		<div className='Register'>
			<img className='landing-img' src={landingImage}/>
			<div className='Register-container'>
				<div className='Register-progress'>
					<span className={currentStep === 1 ? 'active_span' : undefined} />
					<span className={currentStep === 2 ? 'active_span' : undefined} />
					<span className={currentStep === 3 ? 'active_span' : undefined} />
					<hr />
				</div>
				{currentStep === 1 ? (
					<>
						{window.innerHeight > 700 && <div style={{color:'var(--color-secondary)', fontWeight:'500', fontSize:'1.5em', marginBottom:'5px'}}>Cuéntanos quién eres...</div>}
						<div className='form-container'>	
							<form>
								<FormInputRow>
									<FormInput type='text' name='name' defaultValue={name} constraint={validaciones.checkNameFormat} getInput={setName}>
										Nombre:
									</FormInput>
									<FormInput type='text' name='surname' defaultValue={surname} constraint={validaciones.checkNameFormat} getInput={setSurname}>
										Apellidos:
									</FormInput>
								</FormInputRow>
								<FormInput type='email' name='email' defaultValue={email} constraint={validaciones.checkNewEmail} debounceDelay={100} getInput={handleSetEmail} sendValid={(value)=>setCampoMailValido(value)}>
									Correo electrónico:
								</FormInput>
								<div className='nonstrict-fields-message'>
									* Subdominios de universidades (como @estudiantes.uva.es) podrían no funcionar 
								</div>
								<FormInputRow>
									<FormInput type='text' name='username' defaultValue={username} constraint={validaciones.checkNewUsername} debounceDelay={100} getInput={handleSetUsername} sendValid={(value)=>setCampoUserValido(value)}>
										Nombre de Usuario:
									</FormInput>
									<FormInput type='text' name='birthday' defaultValue={birthday} placeholder='dd/mm/aaaa' constraint={validaciones.checkBirthdayFormat} getInput={handleSetBirthday}>
										Fecha de Nacimiento:
									</FormInput>
								</FormInputRow>
								<PasswordCheckInputs getPassword={setPassword}/>
							</form>
							<button onClick={handleNextStep}>Continuar</button>
						</div>
					</>
				) : currentStep === 2 ? (
					<>
						{window.innerHeight > 700 && <div style={{color:'var(--color-secondary)', fontWeight:'500', fontSize:'1.5em', marginBottom:'5px'}}>Déjanos perfeccionar el algoritmo...</div>}
						<div className='form-container'>
							<form>
								<div className='divided-row' style={{marginTop: window.innerHeight > 700 ? '25px' : '15px'}}>
									<Datalist id='localidadesDataList' dataOp='poblaciones' getSelected={setLocation}>
										¿De dónde eres? *
									</Datalist>
									<div className='input-container'>
										<label>¿Qué estudias?</label>
										<select name='tipo estudios' defaultValue={tipoEstudios} onChange={(e) => setTipoEstudios(e.target.value)}>
											<option value='grado'>Estudiante de grado</option>
											<option value='oposicion'>Opositor</option>
											<option value='fp'>FP / Bachillerato</option>
											<option value='otros'>Otros</option>
										</select>
									</div>
								</div>
								{tipoEstudios == 'grado' ? (
									<FormInputRow>
										<Datalist id='gradosUniversitariosDataList' dataOp='gradosUniversitarios' getSelected={setTitulacion}>
											¿Qué grado? *
										</Datalist>
										<Datalist id='universidadesDataList' dataOp='universidades' getSelected={setCentroEstudios}>
											¿En qué universidad? *
										</Datalist>
									</FormInputRow>
								) : tipoEstudios == 'oposicion' ? (
									<FormInputRow>
										<FormInput type='text' name='oposicion' defaultValue={titulacion} constraint={validaciones.checkNameFormat} getInput={setTitulacion}>
											¿Qué oposición? *
										</FormInput>
										<FormInput type='text' name='academia' defaultValue={centroEstudios} constraint={validaciones.checkNameFormat} getInput={setCentroEstudios}>
											¿Tienes academia? (Nombre) *
										</FormInput>
									</FormInputRow>
								) : tipoEstudios == 'fp' ? (
									<FormInputRow>
										<FormInput type='text' name='modalidad' defaultValue={titulacion} constraint={validaciones.checkNameFormat} getInput={setTitulacion}>
											¿Modalidad? (Ciencias, FP de Automoción...): *
										</FormInput>
										<FormInput type='text' name='instituto' defaultValue={centroEstudios} constraint={validaciones.checkNameFormat} getInput={setCentroEstudios}>
											¿En qué instituto? *
										</FormInput>
									</FormInputRow>
								) : tipoEstudios == 'otros' ? (
									<FormInputRow>
										<FormInput type='text' name='estudios' defaultValue={titulacion} constraint={validaciones.checkNameFormat} getInput={setTitulacion}>
											¿Puedes especificar tus estudios? *
										</FormInput>
										<FormInput type='text' name='centro' defaultValue={centroEstudios} constraint={validaciones.checkNameFormat} getInput={setCentroEstudios}>
											¿En qué universidad?/Otros *
										</FormInput>
									</FormInputRow>
								) : null}
								<div className='input-container'>
									<label>Cuéntanos que te gustaria conseguir con Bilern: *</label>
									<textarea style={{height: window.innerHeight > 650 ? '150px' : '100px'}} name='objetivo' onBlur={(e) => setObjetivo(e.target.value)}/>
								</div>
								<div className='nonstrict-fields-message'>
									* Estos campos no son estrictamente necesarios, pero nos ayudan a mejorar tu experiencia en Bilern. 
									Si no eres de España, o tu localidad/grado/universidad no aparece en la lista, por favor especifícanoslo
									en este último campo. 
								</div>
								<div className='terminos_condiciones'>
								<input type="checkbox" onChange={(e) => setTerminosAceptados(e.target.checked)} />
									He leído y acepto los <b onClick={()=>navigate('/terminos')}>términos y condiciones</b>
								</div>
							</form>
							<div className='button-container'>
								<button onClick={() => setCurrentStep(1)}>Volver</button>
								<button onClick={submitUserForm} disabled={!terminosAceptados}>Confirmar</button>
							</div>
						</div>
					</>
				) : currentStep === 3 ? (
					<div className='verification-text'>
						<div>
							<div style={{color:'var(--color-secondary)', fontWeight:'700', fontSize:'2.3em'}}>Revisa tu correo</div>
							<div style={{ fontWeight:'500', fontSize:'1.4em', marginBottom:'10px'}}>
								Te hemos enviado un correo de confirmación a <b>{email}</b></div>
						</div>
						<button onClick={handleRedirect}>Listo</button>
					</div>
				) : null}
				<h4 className={'h-4 text-error-color font-bold'}>
					{errorMessage}
				</h4>
				<p>
					Ya tienes cuenta?{' '}
					<Link to='/Login'>
						<strong>Iniciar Sesión</strong>
					</Link>
				</p>
			</div>
		</div>
	)
}