import { useState, useEffect, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '../../icons/Icon'
import logo from '@/assets/whitelogo.svg'
import './Header.css'

interface HeaderMobileProps {
  rightNode?: ReactNode
  navBar?: ReactNode
}

export default function HeaderMobile({ navBar, rightNode }: HeaderMobileProps) {

  const [navOpen, setNavOpen] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // Cerrar el menú cuando la ruta cambie
    setNavOpen(false);
  }, [location])

  return (
    <>
      <header>
        <div className='header-left'>
          <Icon iconName='bars' size='25' onClick={() => setNavOpen(!navOpen)} />
        </div>
        <div className='header-center'>
          <img src={logo} alt='logo' onClick={() => navigate('/home')} className='cursor-pointer'/>
        </div>
        <div className='header-right'>
          {rightNode}
        </div>
      </header>
      { navOpen &&
        <div className='navBar-mobile'>
          {navBar}
        </div>
      }
    </>
  )
}
