import React from 'react';
import {useField} from "formik";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string
}

function FormInput({ label, ...props} : InputProps) {
    const [field, meta] = useField(props.name ?? "");

    return (
        <div className="flex flex-col gap-2">
            <label className="font-semibold text-gray-600 !text-sm">
                {label}
            </label>
            <input {...field} {...props} />
            {meta.touched && meta.error ? (
                <p className="!text-xs text-red-500">{meta.error}</p>
            ) : null}
        </div>
    );
}

export default FormInput;