import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface PomodoroContextType {
  timeLeft: number;
  setTimeLeft: (time: number) => void;
  dateLeft: number; 
  setDateLeft: (date: number) => void;
  running: boolean;
  setRunning: (running: boolean) => void;
  started: boolean;
  setStarted: (started: boolean) => void;
}

const PomodoroContext = createContext<PomodoroContextType | undefined>(undefined);

interface PomodoroProviderProps {
  children: ReactNode;
}

export const PomodoroProvider = ({ children }: PomodoroProviderProps) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [dateLeft, setDateLeft] = useState(0);
  const [running, setRunning] = useState(false);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const pomodoroTime = localStorage.getItem('pomodoroTime');
    const storedTimeLeft = localStorage.getItem('timeLeft');
    const running = localStorage.getItem('running');

    if (pomodoroTime && storedTimeLeft) {
      if(running == 'true') {
      setDateLeft(Number(pomodoroTime));
      setTimeLeft(Number(storedTimeLeft));
      setRunning(true);
      setStarted(true);
      } else {
        setDateLeft(Number(pomodoroTime));
        setTimeLeft(Number(storedTimeLeft));
        setRunning(false);
        setStarted(true);
      }
    } 
  }, []);

  return (
    <PomodoroContext.Provider value={{ timeLeft, setTimeLeft, dateLeft, setDateLeft, running, setRunning, started, setStarted }}>
      {children}
    </PomodoroContext.Provider>
  );
};

export const usePomodoro = () => {
  const context = useContext(PomodoroContext);
  if (!context) throw new Error('usePomodoro hook is called outside the PomodoroProvider component');
  return context;
};

export default PomodoroContext;
