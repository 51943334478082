import { ExamPrevDays, ExamTask, Task } from "@/types/types";
import { SetStateAction, useEffect, useState } from "react"
import TaskCard from "./TaskCard";
import { subHours, format } from 'date-fns'; // Importa las funciones necesarias de date-fns
import Icon from "./icons/Icon";
import ExamTaskCard from "./ExamTaskCard";
import ExamPrevCard from "./ExamPrevCard";

interface OrganizacionCardProps {
    tareas: Task[];
    examCards: any[];
    examPrev: ExamPrevDays[];
    cartasDiasPrevios: any[];
    date: Date;
    updateTasks: () => void;
    play: (cartasDiasPrevios: any[], modo: number) => void
}

export default function OrganizacionCard ({tareas, examCards, examPrev, cartasDiasPrevios, date, updateTasks, play}:OrganizacionCardProps) {
    const [newTask, setNewTask] = useState<string>('');
    const [examTasks, setExamTasks] = useState<ExamTask[]>()

    useEffect(() => {
        setExamTasks(examCards);
    }, [examCards]);

    const today = new Date();
    const formattedDate = format(date, 'yyyy-MM-dd');
    const formattedToday = format(today, 'yyyy-MM-dd');

    let boolToday: number;
    if (formattedDate < formattedToday) {
        boolToday = -1;
    } else if (formattedDate === formattedToday) {
        boolToday = 0;
    } else {
        boolToday = 1;
    }
    
    const handleAddTask = async () => {
        let contenido = newTask
            if(contenido!='') {
            setNewTask('')
            const formattedDate = format(date, 'yyyy-MM-dd');
            const response = await fetch(  
                `${import.meta.env.VITE_API_URL}/task/create`,
                {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({date: formattedDate, content: contenido}),
                    credentials: 'include'
                })
            if(!response.ok){
                throw new Error('Error en agregar nuevo mazo');
            }
            updateTasks()
        }
    };

    const handleKeyDown = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            handleAddTask();
        }
    };



    return (
        <div style={{
            width: '90%',
            height: 'auto',
            borderRadius: 'var(--border-radius)',
            backgroundColor: 'var(--color-background)',
            padding:'10px 10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            flexWrap: 'wrap'
        }}>

        {examPrev && examPrev.map((exam, index) => (
            <ExamPrevCard taskPrevExam={exam} boolToday={boolToday} cartasDiasPrevios={cartasDiasPrevios[index]} playExam={(cartasDiasPrevios)=>{play(cartasDiasPrevios,2)}}/>
        ))}

        {examTasks && examTasks.map((examTask, index) => (
            <ExamTaskCard task={examTask} boolToday={boolToday} playExam={(cartasExamTask)=>{play(cartasExamTask,1)}}/>
        ))}
        
        {tareas && tareas.map((tarea, index) => (
            <TaskCard task={tarea} updateTask={()=>updateTasks()}/>
        ))}  
        <div className="flex flex-row items-center w-full lg:w-[38vw] h-auto m-[10px_-3px_10px_22px]">
            <Icon iconName='circleBlank' size='20' isClickable={false} />
            <input
                type="text"
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={handleAddTask}
                placeholder="Escribe una tarea"
                style={{ height:'auto',margin: '0px 0px 0px 17px', border: '0px solid transparent', backgroundColor: 'transparent'}}
            />
            </div>
        </div>
    )
}


