import { useState } from 'react'
import Checkbox from './Checkbox'

interface RadioGroupProps {
	options: { label: string; value: string }[]
  getSelected: (value: string | null) => void
}

export default function RadioGroup({ options, getSelected }: RadioGroupProps) {

  const [selected, setSelected] = useState<string | null>(null)

  const handleChange = (checked: boolean, value: string) => {
    if (checked) {
      setSelected(value)
      getSelected(value)
    } else {
      setSelected(null)
      getSelected(null)
    }
  }
 
	return (
		<div className='flex flex-col'>
      {options.map((option, index) => (
        <Checkbox 
          key={index}
          label={option.label}
          value={option.value}
          changeChecked={selected === option.value}
          getChecked={(cheked) => handleChange(cheked, option.value)}
        />
      ))}
    </div>
	)
}