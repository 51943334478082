import { useEffect, useState } from 'react'
import Icon from '@/components/icons/Icon'
import { Bar } from 'react-chartjs-2';
import { subDays } from 'date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2'
import { UserData } from '@/types/types';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface HomeEstadisticasTopLeftProps {
    user: UserData | null
}

export const HomeEstadisticasTopLeft = ({ user }: HomeEstadisticasTopLeftProps) => {

    const [rachaDays, setRachaDays ] = useState(0)
    const [totalPomodoro, setTotalPomodoro ] = useState(0)
    const [sevenDaysData, setSevenDaysData ] = useState<number[]>([])
    const [datosTarjetas, setDatosTarjetas] = useState<number[]>([])

    function normalizeToMidnightUTC(date: Date): Date {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
      }
    
      const currentDate = normalizeToMidnightUTC(new Date());

    const getLastSevenDays = () => {
        const days = [];
        const dayAbbreviations = ['D', 'L', 'M', 'X', 'J', 'V', 'S']; // Abreviaciones manuales
        for (let i = 6; i >= 0; i--) {
            const day = subDays(currentDate, i);
            const dayOfWeek = day.getUTCDay(); // Obtiene el día de la semana (0-6)
            days.push(dayAbbreviations[dayOfWeek]);
        }
        return days;
    };

    const data = {
        labels: getLastSevenDays(),
        datasets: [
            {
                label: 'Actividad',
                data: sevenDaysData, // Reemplaza estos datos con los datos reales
                backgroundColor: '#77C89D',
                borderWidth: 0,
                borderRadius: 10,
                borderSkipped: false,
            },
        ],
    };

    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Desactiva la leyenda
            },
            title: {
                display: false, // Desactiva el título
            },
            tooltip: {
                enabled: false, // Desactiva los tooltips
            },
        },
        interaction: {
            mode: undefined, // Desactiva la interacción al pasar el cursor
        },
        scales: {
            x: {
                grid: {
                    display: false, // Elimina la cuadrícula del eje X
                },
                ticks: {
                    font: {
                        size: 13, // Ajusta el tamaño de la fuente
                        weight: 500, // Hace el texto más grueso
                    },
                },
                border: {
                    display: true,
                    width: 2, // Hace el eje X más grueso
                },
            },
            y: {
                grid: {
                    display: false, // Elimina la cuadrícula del eje Y
                },
                ticks: {
                    stepSize: 1,
                    maxTicksLimit: 8,
                    font: {
                        size: 13, // Ajusta el tamaño de la fuente
                        weight: 500, // Hace el texto más grueso
                    },
                    callback: function(tickValue: string | number) {
                        const value = Number(tickValue);
                        // Convierte el valor a minutos si es menor a 1 hora (60 minutos)
                        if (value < 60) {
                            return (value) + ' min'; // Convierte de horas a minutos
                        } else {
                            return Math.floor(value / 60) + ' h ' + (value % 60 !== 0 ? Math.round(value % 60) + ' min' : ''); // Muestra el valor en horas si es mayor o igual a 1 hora
                        }
                    },
                },
                border: {
                    display: true,
                    width: 2, // Hace el eje Y más grueso
                },
                min: 0,
                suggestedMax: 5, 
            },
        }
    };


    const pieData = {
        labels: ['Aprendidas', 'Repasando', 'Por estudiar'],
        datasets: [
            {
                data: datosTarjetas,
                backgroundColor: [
                    '#49c985',
                    '#F7D26E',
                    '#ff7973'
                ],
                borderColor:'#ededed',
                hoverOffset: 4
            }
        ]
    };

    const optionsPie = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Desactiva la leyenda
            },
            title: {
                display: false, // Desactiva el título
            },
        },
    };

    
    const CustomLegend = ({ data }: any) => {
        const total = data.datasets[0].data.reduce((acc: number, value: number) => acc + value, 0);
        return (
            <div>
                {data.labels.map((label: string, index: number) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <div style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: data.datasets[0].backgroundColor[index],
                            marginRight: '10px'
                        }}></div>
                        <div>{label}: {data.datasets[0].data[index]}</div>
                    </div>
                ))}
            </div>
        );
    }

    useEffect(() => {
        const getStreak = async () => {
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/estadisticas/get_stats`, 
                      {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                      }
                    )
                if (!response.ok) {
                    throw new Error('Error al obtener las estadisticas');
                }
                const data = await response.json()
                setRachaDays(data.racha)
                setTotalPomodoro(data.minEstudioTot)
                setSevenDaysData(data.minEstudioHoy)

                const response2 = await fetch(
                    `${import.meta.env.VITE_API_URL}/estadisticas/get_pie_stats`, 
                      {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                      }
                    )
                if (!response2.ok) {
                    throw new Error('Error al obtener las estadisticas del pie chart');
                }
                const data2 = await response2.json()
                setDatosTarjetas(data2.numero)
            } catch (error) {
                console.error('Error:', error)
            }
        }
        getStreak()
    }, [])


	return (
        <div className='w-full h-full flex flex-col md:flex-row justify-between p-0 m-0 overflow-hidden'>
            <div className='flex flex-col'>
                <div className='w-full text-left text-2xl font-bold text-primary-color ml-3 mb-2'>
                    Estadísticas
                </div>
                <div className={`flex flex-col sm:flex-row md:flex-col justify-start items-start sm:items-start h-full p-2 mt-0 gap-3 sm:gap-5 mb-5 sm:mb-0 pl-3 sm:pl-0 text-2xl `}>
                <div className='flex justify-center items-end m-0'>
                        <Icon iconName='flame' isClickable={false} size={'50'} className='text-[#FFC90E]'/>
                        <div className='flex flex-col justify-center items-start md:ml-5'> 
                            <h2 className='!text-xl lg:text-base font-bold'>
                                Racha <br/>
                                <b className='!text-5xl lg:text-3xl text-text-color mr-1'>{rachaDays}</b>
                                {rachaDays != 1 ? 'dias' : 'día'}
                            </h2>
                        </div>
                    </div>
                    <div className='flex justify-center items-end md:items-center m-0'> 
                        <Icon iconName='clock' isClickable={false} size={'40'} className='text-secondary-color ml-2 mb-2'/>
                        <div className='flex flex-col justify-center items-start ml-2 md:ml-5'> 
                            <h2 className='!text-lg md:text-xl lg:text-base font-bold'>
                                Tiempo <br/> concentrado <br/>
                                <b className='!text-5xl lg:text-3xl text-text-color mr-1'>
                                    {Math.round(totalPomodoro) >= 60 ? (Math.floor(totalPomodoro/60)) : Math.round(totalPomodoro)}
                                </b>
                                {Math.round(totalPomodoro) >= 60 ? (Math.floor(totalPomodoro/60) == 1 ? 'hora' : 'horas') : Math.round(totalPomodoro) == 1 ? 'min' : 'min'}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-full p-2 flex justify-center items-center'>
                <div className='h-auto md:h-38 lg:h-28 w-2/5 md:w-full flex flex-row md:flex-col justify-center gap-3 text-center'>
                    <div className='flex-shrink-0 max-w-[160px] md:max-w-[10vw] 2xl:max-w-[13vw] md:w-auto'>
                        <Pie data={pieData} options={optionsPie} />
                    </div>
                    <div className='flex flex-col'>
                        <div className='font-semibold mb-2'>Tarjetas totales</div>
                        <CustomLegend data={pieData} />
                    </div>
                </div>
            </div>
            <div className='h-full flex flex-col justify-center items-center p-2 md:w-[40%]' > 
                <div className='flex-shrink-0 md:max-w-4/5 '>
                    <Bar data={data} options={optionsBar} className='w-11/12 2xl:min-w-[22vw] ' />
                    <h4 className='font-normal text-center'>Tiempo de Pomodoro esta semana</h4>
                </div>
            </div>
        </div>
	)
}