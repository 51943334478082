import { useState, useEffect } from 'react'
import { FlashCard } from '@/types/cards'
import TiptapEditor from './tiptap/TiptapEditor'

/**
 * @prop card - Carta a editar, si se quieres crear una carta nueva no es necesaria
 * @prop onChangeTestCard - función que devuelve el estado de la carta editada cada vez que se actualiza
 */
interface FlashCardEditorProps {
  card?: FlashCard
  clean?: boolean
  onChangeCard: (flashCard: FlashCardInit) => void
}

/**
 * @type TextCardInit - tipo correspondiente a la creación de una nueva carta de tipo test
 */
export type FlashCardInit = {
  pregunta: string
  respuesta: string
}

/**
 * Componente TestCardEditor.
 * Se encarga tanto de la edición y la creación de nuevas cartas de tipo test.
 * Contiene el estado testCard, que representa la carta que se esta editando
 * y que se comparte con el componente padre cada vez que se actualiza
 * @param props de la interfaz FormInputProps
 * @returns componente con la interfaz de edición de una carta de tipo test
 */
export default function FlashCardEditor({ card, clean=false, onChangeCard }: FlashCardEditorProps) {

  const [flashCard, setFlashCard] = useState<FlashCard | FlashCardInit>(
    card && card.type === 'flashcard' ? (card) : {
      pregunta: card ? card.pregunta : '',
      respuesta: card ? card.respuesta : '',
    } 
  ) 
  useEffect(() => { onChangeCard(flashCard) }, [flashCard])

  return(
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[55vw] max-w-[1000px]'><div className='pl-[20px] font-[600] text-[20px] -mb-2 -mt-1'>Pregunta:</div>
        <TiptapEditor 
          className='h-[25vh] md:h-[30vh]  mb-0 p-3'
          id='flashCardPregunta' 
          initialValue={flashCard.pregunta} 
          onChange={(content) => setFlashCard({ ...flashCard, pregunta: content })}
          clean={clean}
        />
      </div>
      <div className='w-full md:w-[55vw] max-w-[1000px]'><div className='pl-[20px] font-[600] text-[20px] -mb-2 -mt-1'>Respuesta:</div>
        <TiptapEditor 
          className='h-[25vh] md:h-[30vh] mb-0 p-3'
          id='flashCardRespuesta' 
          initialValue={flashCard.respuesta} 
          onChange={(content) => setFlashCard({ ...flashCard, respuesta: content })}
          clean={clean}
        />
      </div>
    </div>
  )
}