import { createContext, useContext, useState, ReactNode } from 'react'
import { Editor } from '@tiptap/react'

interface EditorsContextType {
  editors: { [key: string]: Editor | null }
  addEditor: (key: string, editor: Editor) => void
  selectedEditorId: string | null
  setSelectedEditorId: (id: string) => void
  getSelectedEditor: () => Editor | null
}

const EditorsContext = createContext<EditorsContextType | undefined>(undefined)

export const useEditors = () => {
  const context = useContext(EditorsContext)
  if (!context) {
    throw new Error('useEditors must be used within an EditorProvider')
  }
  return context
}

interface EditorsProviderProps {
  children: ReactNode;
}

// MIRAR A VER SI ES MEJOR UN SIMPLE ESTADO DE SELECTEDEDITOR Y QUE YA PILLEN ESE
export const EditorsProvider = ({ children }: EditorsProviderProps) => {

  const [editors, setEditors] = useState<{ [key: string]: Editor | null }>({})
  const [selectedEditorId, setSelectedEditorId] = useState<string | null>(null)

  const addEditor = (key: string, editor: Editor) => {
    setEditors((prevEditors) => ({ ...prevEditors, [key]: editor }))
  }

  const getSelectedEditor = (): Editor | null => {
    return editors[selectedEditorId!]
  }

  return (
    <EditorsContext.Provider value={{ editors, addEditor, selectedEditorId, setSelectedEditorId, getSelectedEditor }}>
      {children}
    </EditorsContext.Provider>
  )
}

export default EditorsContext