import { Helmet } from 'react-helmet'

interface SEOProps {
  title: string, // Title of the page
  description?: string, // Description of the page (less than 150 characters)
  url?: string, // URL of the page
  keywords?: string, // Keywords for the page (comma separated, no more than 10)
  image?: string, // URL of the image for the Open Graph tags
  type?: string // Open Graph type (website, article, book, profile, etc.)
  twitterCard?: string, // Twitter card type (summary, summary_large_image, app, player)
  twitterCreator?: string, // Twitter user account for the content creator
  meta?: JSX.Element // Extra meta tags
}

export default function SEO ({
  title,
  description,
  url,
  keywords='bilern, flashcards, memorización, estudio',
  image, // TODO: Add default image
  type='website',
  twitterCard='summary_large_image',
  twitterCreator,
  meta
}: SEOProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='theme-color' content='#f0f0f0' />
      <link rel='manifest' href='/manifest.json' />
      {/* Open Graph tags */}
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      {image && <meta property='og:image' content={image} />}
      {url && <meta property='og:url' content={url} />}
      <meta property='og:site_name' content='Bilern' />
      <meta property='og:type' content={type} />
      <meta property='og:locale' content='es_ES' />
      {/* Twitter Card Tags */}
      <meta name='twitter:site' content='@bilernteam' />
      {twitterCard && <meta name='twitter:card' content={twitterCard} />}
      {twitterCreator && <meta name='twitter:creator' content={twitterCreator} />}
      {image && <meta name='twitter:image' content={image} />}
      {/* Extra Meta Tags */}
      {meta}
    </Helmet>
  )
}

// faltan por paner todos los links de iconos en diferentes tamaños (favicon, apple-touch-icon, etc)

// Y por hacer las imagenes para las redes sociales y meterlas a public
