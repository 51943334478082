import { Reader } from '@/components/ImportarGallery'

/**
 * @type {ImportFile}
 * @property {File} file - The file object
 * @property {string} name - The file name with extension
 * @property {string[]} content - The content, where each page is an element of the array
 * @property {string} url - The file url in base64
 */
export type ImportFile = {
  file: File
  name: string
  content: string[]
  url: string
}

export async function getImportFileFromRawFile(rawFile: File): Promise<ImportFile> {
  if (rawFile) {
    let fileContent: string[] = []
    await Reader(rawFile).then((result) => {
      result.forEach((line) => fileContent.push(line))
    })
    return { 
      file: rawFile, 
      name: rawFile.name, 
      content: fileContent!, 
      url: URL.createObjectURL(rawFile)
    }
  }
  throw new Error('Invalid file provided')
}
