import HeaderMazos from '@/components/common/header/HeaderApp'
import Header from '@/components/common/header/Header'
import { useAuth } from '@/context/auth/useAuth'
import { useNavigate } from 'react-router-dom'

export default function Tutorial() {

  const auth = useAuth()
  const isAuth = auth?.isAuth
  const loading = auth?.loading
  const navigate = useNavigate()

  return (
    <>
      { !loading && isAuth ? <HeaderMazos/> : <Header/>}
      <div className="Tutorial" style={{
        marginTop: '8vh',
        width: '100vw',
        height: '92vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <iframe 
          width='861'
          height='484'
          src='https://www.youtube.com/embed/vNSuawlNlRM?si=X3CQPIdBXo7BkICn?autoplay=1&rel=0&controls=0'
          title='Bilern tutorial video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' 
          referrerPolicy='strict-origin-when-cross-origin' 
          allowFullScreen={true}
          style={{border: '2px solid var(--color-secondary)', borderRadius: '30px'}}
        />
        <button 
          onClick={() => navigate('/dashboard')}
          style={{position: 'absolute', bottom: '5vh', fontSize: '1.5em'}}
        >
          Ir a Bilern
        </button>
      </div>
    </>
  )
}