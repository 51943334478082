import { ReactNode } from 'react'
import '@/styles/DashboardLayout.css'

export default function DefaultLayout({ children } : { children: ReactNode }) {

  return (
    <div className='Dashboard'>
        <div className='content'>
          {children}
        </div>
    </div>
  )
}
