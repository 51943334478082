import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/context/auth/useAuth'
import useWindowSize from '@/hooks/useWindowSize'
import Icon from '@/components/icons/Icon'
import Header from './Header'
import { UserMenu } from './UserMenu'
import HeaderClock from './HeaderClock'
import Navbar from './Navbar'
import './Header.css'


export default function HeaderMazos() {

	const navigate = useNavigate()
	const { width } = useWindowSize()

	const [openUserMenu, setOpenUserMenu] = useState(false)
	const [username, setUsername] = useState("")
	const [name, setName] = useState("")
	const [surname, setSurname] = useState("")

  const auth = useAuth()

  useEffect(() => {
		if (!auth.loading && auth.isAuth) {
			const getUsername = async () => {
				try{
					const response = await fetch (
						`${import.meta.env.VITE_API_URL}/user/data`, 
						{
							method: 'GET',
							headers: { 'Content-Type': 'application/json', },
							credentials: 'include',
						}
					)
					if(!response.ok) {
						throw new Error('Error al obtener los datos del usuario')
					}
					const data = await response.json()
					setUsername(data.usuario)
					setName(data.nombre)
					setSurname(data.apellidos)
				} catch(error) {
					console.log(error)
				}
			}
			getUsername()
		}
	}, [auth.loading])

	const signOut = async () => {
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/auth/signout`, 
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json', },
					credentials: 'include',
				}
			)
			if (!response.ok) {
				throw new Error('Error al cerrar sesión')
			}
		} catch (error) {
			console.error(error)
		}
	}

	const handleSignOut = async () => {
		await signOut()
		auth.fetchAuthState()
		localStorage.clear()
		navigate('/')
	}

  	return (
		<Header 
			navBar={<Navbar />}
			rightNode={
				<>
					{ !auth.loading || auth.isAuth ?
						<>
							<HeaderClock />
							<div className='userBox' onClick={() => setOpenUserMenu(!openUserMenu)}>
								<Icon iconName='user' size='35'/>
								{ width > 990 && <>
									<h4>{username}</h4>
									{ openUserMenu ?
										<Icon iconName='angleUp' size='20' /> :
										<Icon iconName='angleDown' size='20' />
									}
								</>}
							</div>
						</>
					:
						<div className='userBox' style={{cursor: 'default'}}>
							<Icon iconName='user' size='35' isClickable={false} />
							{ width > 990 &&
								<h4>Cargando...</h4>
							}
						</div>
					}
					{/* <Icon iconName='bars' size='25' className='bars-icon' onClick={() => setOpenUserMenu(!openUserMenu)}/>*/}
					{ openUserMenu && 
						<UserMenu 
							signOut={handleSignOut} 
							name={name}
							surname={surname}
							closeUserMenu={()=>setOpenUserMenu(false)}
						/>
					}
				</>
			}
		/>
  )
}
