import { ReactNode } from 'react'
import { useSubscription } from './useSubscription'
import Popup from '@/components/common/ui/Popup'
import LockedContentMessage from '@/components/LockedContentMessage'

/**
 * Este componente usa useSubscription para comprobar la autenticación del usuario de manera que si el usuario
 * ya se ha cargado (loading es false) y no está autenticado (user es null) redirecciona a /login,
 * en caso contrario si el usuario está autenticado devuelve el children
 * @property {ReactNode} children componente de uso autenticado
 * @returns children si el usuario está autenticado
 */
export default function SubscriptionRestrictedRoute({ children }: { children: ReactNode }) {

  // TODO AÑADIR PROP PARA EL TIPO DE SUBSCRIPCION Y ESO

  const FREEPLANID = 'ef0d1a2c-1075-4576-ac9f-276bbb25296c'

  const sub = useSubscription()
  const planId = sub?.subscriptionPlanId
  const loading = sub?.loading

  // mirar si hacer algo en el loading
  return !loading && planId == FREEPLANID ?
    <>
      {children} 
      <Popup 
        className='min-w-72 w-auto md:w-1/2'
        open={true} closeIcon={false} closeOnBlur={false} backdropBlur={5}
      >
        <LockedContentMessage />
      </Popup>
    </>
  : children

}
