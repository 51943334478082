import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from '@/pages/Landing'
import Home from '@/pages/Home'
import Biblioteca from '@/pages/Biblioteca'
import Error404 from '@/pages/Error404'
import Login from '@/pages/Login'
import Pricing from '@/pages/Pricing'
import Register from '@/pages/Register'
import Settings from '@/pages/Settings'
import Terminos from '@/pages/Terminos'
import Tutorial from '@/pages/Tutorial'
import Examenes from '@/pages/Examenes'
import Contacto from '@/pages/Contacto'
import Success from '@/pages/Success'
import Organizacion from '@/pages/Organizacion'
import ImportFromQr from '@/pages/ImportFromQr'
import AuthenticatedRoute from '@/context/auth/AuthenticatedRoute'
import NonAuthenticatedRoute from '@/context/auth/NonAuthenticatedRoute'
import SubscriptionRestrictedRoute from './context/auth/SubscriptionRestrictedRoute'
import { UpdateFoldersProvider } from '@/context/UpdateFoldersContext'
import { PomodoroProvider } from '@/context/ClockContext'

// TEMPORAL PARA QUE ESTADISTICAS Y SEGUIMIENTO SE VEAN CON HEADER
import HeaderMazos from '@/components/common/header/HeaderApp'
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "@/api/client";

export default function App() {

	return (
		<Suspense fallback={<h1>Cargando Bilern...</h1>}>
			<QueryClientProvider client={queryClient}>
				<UpdateFoldersProvider>
				<PomodoroProvider>
				<Routes>
					<Route path='/' element={<NonAuthenticatedRoute><Landing /></NonAuthenticatedRoute>} />
					<Route path='/home' element={<AuthenticatedRoute><HeaderMazos /><Home /></AuthenticatedRoute>} />
					<Route path='/dashboard' element={<AuthenticatedRoute><HeaderMazos /><Biblioteca /></AuthenticatedRoute>} />
					<Route path='/settings' element={<AuthenticatedRoute><HeaderMazos /><Settings /></AuthenticatedRoute>} />
					<Route path='/login' element={<NonAuthenticatedRoute><Login /></NonAuthenticatedRoute>} />
					<Route path='/register' element={<NonAuthenticatedRoute><Register /></NonAuthenticatedRoute>} />
					<Route path='/examenes' element={<AuthenticatedRoute><SubscriptionRestrictedRoute><HeaderMazos /><Examenes /></SubscriptionRestrictedRoute></AuthenticatedRoute>} />
					<Route path='/organizacion' element={<AuthenticatedRoute><HeaderMazos /><Organizacion /></AuthenticatedRoute>} />
					<Route path='/tutorial' element={<Tutorial />} />
					<Route path='/terminos' element={<Terminos />} />
					<Route path='/pricing' element={<Pricing />} />
					<Route path='/contacto' element={<AuthenticatedRoute><HeaderMazos /><Contacto /></AuthenticatedRoute>} />
					<Route path='/success' element={<AuthenticatedRoute><Success /></AuthenticatedRoute>} />
					<Route path='/codigos/:code' element={<ImportFromQr />} />
					<Route path='*' element={<Error404/>} />
				</Routes>
				</PomodoroProvider>

				</UpdateFoldersProvider>
			</QueryClientProvider>
		</Suspense>
	)
}
