import { ReactNode } from 'react'
import '@/styles/DashboardLayout.css'

interface DashboardLayoutProps {
  isMenu?: boolean
  menu?: ReactNode
  children: ReactNode
}

export default function DashboardLayout({ isMenu, menu, children }: DashboardLayoutProps) {

  return (
    <div className='Dashboard'>
      {isMenu ?
        <>  
          <div className='menu'>
            {menu}
          </div>
          <div className='content_side overflow-hidden'>
            {children}
          </div>
        </>
        :
        <div className='content overflow-hidden'>
          {children}
        </div>
      }
    </div>
  )
}