import Icon from '@/components/icons/Icon';
import { useState } from 'react';

interface InformationProps {
  size?: string
  information?: string
  width?: string
  length?: string
}

export default function Information({size='18px', information='', width='200px'}: InformationProps) {

    const [isHovered, setIsHovered] = useState(false);


    return (
    <div className="relative inline-block flex items-center justify-center h-full">
      <div
        className="flex items-center"
        style={{ color: isHovered ? 'var(--color-primary)' : '#bbb' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Icon iconName="info" size={size} />
      </div>
      <div
        className={`absolute left-full ml-3 p-2 rounded z-10 text-black text-sm font-normal max-w-[300px] w-auto whitespace-normal transition-opacity duration-300 ease-in-out ${
          isHovered ? 'opacity-100' : 'opacity-0'
        }`}
        style={{ minWidth: width, backgroundColor: 'var(--color-section)' }}
      >
        {information}
      </div>
    </div>
      );
}