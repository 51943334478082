import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Plan } from '@/types/types'
import SEO from '@/components/SEO'
import BlancLayout from '@/components/layout/BlancLayout'
import Spinner from '@/components/common/ui/Spinner'
import { GoBackArrow } from '@/components/common/ui/GoBackArrow'

/**
 * @interface CheckoutData representa los datos de la compra que se obtienen desde el servidor
 * @property {string} planId - ID del plan comprado, solo si la compra ha sido de una subscripción
 * @property {number} tokens - Cantidad de tokens comprados, solo si la compra ha sido de tokens
 */
interface CheckoutData {
  status: string
  amount_total: number
  currency: string
  metadata: {
    userId: string
    planId?: string
    tokens?: number
  }
}

export default function Success() {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id')

  const [loading, setLoading] = useState(true)
  const [checkoutData, setCheckoutData] = useState<CheckoutData | undefined>(undefined)
  const [boughtPlan, setBoughtPlan] = useState<Plan | undefined>(undefined)
  const [totalTokens, setTotalTokens] = useState<number | undefined>(undefined)

  const fetchCheckoutData = async (): Promise<CheckoutData | undefined> => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/pay/verify_checkout_session?session_id=${sessionId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', },
        }
      )
      if (!response.ok) throw new Error('Error al obtener los datos de la compra')
      const data = await response.json()
      return data as CheckoutData
    } catch (error) {
      navigate('/404')
    }
  }

  const fetchBoughtPlan = async(): Promise<Plan | undefined> => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/pay/plans`, 
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', },
        }
      )
      if (!response.ok) throw new Error('Error al obtener los planes')
      const data = await response.json()
      return data.plans.find((plan: Plan) => plan.id === checkoutData?.metadata.planId)
    } catch (error) {
      throw new Error('Error al obtener los planes')
    }
  }  
  
  const fetchTotalTokens = async (): Promise<number | undefined> => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/user/data`, 
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', },
          credentials: 'include'
        }
      )
      if (!response.ok) throw new Error('Error al obtener los tokens')
      const data = await response.json()
      return data.tokens
    } catch (error) {
      throw new Error('Error al obtener los datos del usuario')
    }
  }

  useEffect(() => {
    async function fetchData() {
      const data: CheckoutData | undefined = await fetchCheckoutData()
      setCheckoutData(data)
    }
    async function fetchPlan() {
      const planData = await fetchBoughtPlan()
      setBoughtPlan(planData)
      setLoading(false)
    }
    async function fetchTokens() {
      const tokens = await fetchTotalTokens()
      setTotalTokens(tokens)
      setLoading(false)
    }
    if (!sessionId) navigate('/404')
    if (!checkoutData) fetchData()
    else if (checkoutData.metadata.planId) fetchPlan()
    else if (checkoutData.metadata.tokens) fetchTokens()
  }, [sessionId, checkoutData])

  if (loading) return <BlancLayout><Spinner /></BlancLayout>
  return (<>
    <SEO title='¡Compra completada! - Bilern' />
    <BlancLayout>
      <GoBackArrow 
        className='hidden md:block'
        onClick={() => navigate('/pricing')} 
      />
      <div className='h-full flex flex-col items-center justify-center gap-5'>
        { checkoutData?.status == 'complete' && checkoutData?.metadata.planId && boughtPlan ?
          <div className='m-5 p-5 max-w-[1000px] flex flex-col gap-3'>
            <h1 className='text-[var(--color-secondary)]'>
              ¡Acabas de dar el primer paso para petarlo en tus examenes!
            </h1>
            <h4 className='font-normal'>
              { checkoutData.amount_total > 0 ?
                `Su compra del plan ${boughtPlan.name},
                por un total de ${checkoutData.amount_total/100} ${checkoutData.currency.toUpperCase()}, 
                ha sido tramitada correctamente.` :
                `La subscripción se cobrará una vez terminado el periodo de prueba,
                siempre que esta no haya sido cancelada anteriormente.`
              }
            </h4>
            <h3 className='font-semibold'>Ahora eres parte del Plan {boughtPlan.name}:
              <h2 className='text-[var(--color-primary)]'>
                {boughtPlan.price/100}€/
                {boughtPlan.duration === 1 ? 'mes' : `${boughtPlan.duration} meses`}
              </h2>
            </h3>
          </div>
        : checkoutData?.status == 'complete' && checkoutData?.metadata.tokens && totalTokens ?
          <div className='m-5 p-5 max-w-[1000px] flex flex-col gap-3'>
            <h1 className='text-[var(--color-secondary)]'>
              ¡Haz que tus tokens hagan magia con nuestra Inteligencia Artificial!
            </h1>
            <h3 className='font-normal'>
              Su compra de {checkoutData.metadata.tokens} tokens,
              por un total de {checkoutData.amount_total/100} {checkoutData.currency.toUpperCase()}, 
              ha sido tramitada correctamente.
            </h3>
            <h3 className='font-semibold'>Actualmente tienes un total de:</h3>
            <h2 className='text-[var(--color-primary)]'>{totalTokens} tokens</h2>
          </div>
        :
          <div>
            <h3 className='text-[var(--color-error)]'>Algo ha fallado, pruebe a recargar la página o contacte con soporte</h3>
          </div>
        }
        <div className='w-2/3 flex flex-row justify-center md:justify-between gap-5'>
          <button 
            className='w-[200px] rounded-full bg-[var(--color-background)] text-xl text-[var(--color-text)] hover:bg-[var(--color-secondary)] hover:text-white'
            onClick={() => navigate('/home')}>
            Home
          </button>
          <button 
            className='hidden md:block w-[200px] rounded-full bg-[var(--color-background)] text-xl text-[var(--color-text)] hover:bg-[var(--color-secondary)] hover:text-white'
            onClick={() => navigate('/dashboard')}>
            Biblioteca
          </button>
          <button 
            className='hidden md:block w-[200px] rounded-full bg-[var(--color-background)] text-xl text-[var(--color-text)] hover:bg-[var(--color-secondary)] hover:text-white'
            onClick={() => navigate('/examenes')}>
            Examenes
          </button>
          <button 
            className='hidden md:block w-[200px] rounded-full bg-[var(--color-background)] text-xl text-[var(--color-text)] hover:bg-[var(--color-secondary)] hover:text-white'
            onClick={() => navigate('/organizacion')}>
            Organizacion
          </button>
        </div>
      </div>
    </BlancLayout>
  </>)
}
