import DashboardLayout from '@/components/layout/DashboardLayout'
import Header from '@/components/common/header/Header'
import SEO from '@/components/SEO'
import '../styles/Terminos.css'

/**
 * Términos y condiciones de uso de Bilern.
 */
export default function Terminos() {
    return (<>
        <SEO 
            title='Términos y Condiciones - Bilern' 
            description='Lee los términos y condiciones de uso para Bilern. Aquí encontrarás información sobre nuestras políticas, derechos y responsabilidades al utilizar nuestra aplicación.'
        />
        <Header />
        <DashboardLayout>
            <div className='texto'>
            <h1 className='mt-2 mb-5'>Términos y condiciones</h1>
                <h2>Términos y Condiciones de Uso para Bilern</h2>
                <p className="my-1.5">Fecha de Entrada en Vigencia: 20 de octubre de 2024</p>
                <p className="my-1.5">Bienvenido a Bilern, una aplicación diseñada para mejorar tu experiencia de aprendizaje a través de la creación y uso de flashcards de estudio. Al registrarte y usar Bilern, aceptas estos términos y condiciones (en adelante, los "Términos"). Por favor, lee estos Términos cuidadosamente antes de utilizar nuestra aplicación.</p>
                
                <h3>1. Aceptación de los Términos</h3>
                <p className="my-1.5">Al crear una cuenta en Bilern, confirmas que has leído, entendido y aceptado los presentes Términos y nuestra Política de Privacidad.</p>
                
                <h3>2. Información Recopilada</h3>
                <p className="my-1.5">Para ofrecerte y mejorar nuestros servicios, necesitamos recopilar y procesar cierta información personal. Al utilizar Bilern, aceptas que recopilemos y usemos tu información personal de acuerdo con estos Términos, incluyendo, pero no limitado a:</p>
                <ul>
                    <li>Correo electrónico</li>
                    <li>Nombre de usuario</li>
                    <li>Nombre y apellidos</li>
                    <li>Fecha de nacimiento</li>
                    <li>Fecha de creación de cuenta</li>
                    <li>Ubicación</li>
                    <li>Tipo y centro de estudios cursados</li>
                    <li>Titulación</li>
                    <li>Objetivo que se pretende conseguir con la aplicación</li>
                </ul>
                <p className="my-1.5">Además, al crear flashcards y mazos dentro de Bilern, nos autorizas a recopilar y almacenar:</p>
                <ul>
                    <li>Contenido de las flashcards (preguntas y respuestas)</li>
                    <li>Parámetros de estudio de cada flashcard (tiempo de estudio, si la respuesta fue correcta o no)</li>
                </ul>
                <p className="my-1.5"></p>

                <h3>3. Uso de la Información</h3>
                <p className="my-1.5">El propósito de recopilar esta información es mejorar tu experiencia de usuario y la eficacia de Bilern. Usaremos estos datos para:</p>
                <ul>
                <li>Personalizar tu experiencia de aprendizaje.</li>
                <li>Analizar cómo se utilizan nuestras herramientas de estudio para implementar mejoras.</li>
                <li>Desarrollar nuevas funciones y servicios que respondan a las necesidades de nuestros usuarios.</li>
                </ul>
                <p className="my-1.5">Nos comprometemos a no vender ni compartir tu información personal con terceros sin tu consentimiento explícito, excepto en los casos que estén detallados en nuestra Política de Privacidad o cuando lo requiera la ley.</p>
                
                <h3>4. Derechos del Usuario</h3>
                <p className="my-1.5">Tienes derecho a acceder, corregir o eliminar tu información personal en cualquier momento. Si deseas ejercer estos derechos, por favor, contacta con nuestro equipo de soporte a través de contacto@bilern.com</p>
                
                <h3>5. Limitación de Responsabilidad</h3>
                <p className="my-1.5">Bilern no será responsable por cualquier daño indirecto, incidental, especial, consecuente o punitivo, o por pérdidas o daños de cualquier tipo, incluyendo pero no limitado a la pérdida de beneficios, ingresos, datos o uso de datos, que surjan de o estén relacionados con el uso o la incapacidad de usar la aplicación, incluso si Bilern ha sido advertido de la posibilidad de tales daños. Nuestra responsabilidad en cualquier circunstancia estará limitada al máximo permitido por la ley vigente.</p>

                <h3>6. Suscripciones y Política de Pago</h3>
                <p className="my-1.5">Bilern ofrece suscripciones de pago que permiten el acceso a funcionalidades avanzadas. Las suscripciones pueden ser mensuales, anuales o de otros tipos, según lo indicado en nuestra plataforma. Al suscribirte a uno de nuestros planes, aceptas los siguientes términos relacionados con los pagos y renovaciones:</p>

                <h4>6.1. Tarifas de Suscripción</h4>
                <p className="my-1.5">Las tarifas aplicables a las suscripciones se mostrarán claramente antes de completar el proceso de pago. Todos los pagos deben realizarse a través de los métodos de pago que ofrecemos en la plataforma.</p>

                <h4>6.2. Renovaciones Automáticas</h4>
                <p className="my-1.5">Las suscripciones se renovarán automáticamente al final del periodo correspondiente (mensual, anual, etc.), a menos que canceles la renovación automática antes de la fecha de renovación. Puedes gestionar o cancelar la renovación automática en cualquier momento a través de la configuración de tu cuenta.</p>

                <h4>6.3. Política de No Reembolsos</h4>
                <p className="my-1.5">Todos los pagos de suscripción a Bilern son definitivos y no reembolsables. Esto incluye suscripciones parciales o completas, tanto si son mensuales como anuales. Una vez que se haya realizado el pago de la suscripción, no se emitirá ningún reembolso, reembolso prorrateado o crédito por ningún motivo, incluyendo la cancelación antes de que finalice el periodo de suscripción.</p>

                <h4>6.4. Cancelación de Suscripciones</h4>
                <p className="my-1.5">Puedes cancelar tu suscripción en cualquier momento, pero seguirás teniendo acceso a las funciones premium de Bilern hasta el final del periodo de facturación actual. La cancelación de la suscripción impedirá la renovación automática, pero no generará un reembolso por el periodo ya pagado.</p>

                <h4>6.5. Modificación de Tarifas</h4>
                <p className="my-1.5">Nos reservamos el derecho de modificar las tarifas de suscripción en cualquier momento. Si realizamos cambios en nuestras tarifas, te notificaremos con antelación para que puedas decidir si deseas continuar con tu suscripción al nuevo precio. Los cambios de tarifas no afectarán a las suscripciones que ya estén en curso hasta el momento de su renovación.</p>

                <h4>6.6. Proveedor de Pago</h4>
                <p className="my-1.5">Utilizamos Stripe como proveedor de pagos para gestionar las suscripciones. Stripe puede almacenar información relacionada con tus métodos de pago, como tarjetas de crédito, de acuerdo con sus propios términos y políticas de privacidad. Nosotros no almacenamos directamente información de tarjetas de crédito en nuestros servidores.</p>
                <p className="my-1.5">Al utilizar Bilern y suscribirte a uno de nuestros planes de pago, reconoces y aceptas que tus datos financieros y de pago pueden ser procesados por Stripe y otros proveedores de servicios según sea necesario para completar las transacciones, conforme a sus respectivas políticas de privacidad.</p>
                
                <h4>6.7. Uso Razonable de Inteligencia Artificial</h4>
                <p className="my-1.5">
                Para mantener el correcto funcionamiento de la plataforma y evitar un uso abusivo, Bilern establece límites de uso razonable para el procesamiento de texto mediante inteligencia artificial. Estos límites son de hasta 500 páginas en el plan mensual, 3000 páginas en el plan cuatrimestral y 4000 páginas en el plan opositor. El cálculo del número de páginas procesadas se realiza en función de la longitud del texto importado, tomando como referencia páginas completas de texto escrito. De esta forma, cualquier fracción de página se contabiliza de acuerdo con su longitud real y no se redondea al equivalente de una página entera, permitiendo procesar un mayor número de páginas si no están completamente llenas. Si se requiere un volumen de procesamiento superior a estos límites, el usuario puede adquirir tokens adicionales que permiten importar contenido adicional. Estos tokens no son reembolsables bajo ninguna circunstancia.
                </p>



                <h3>7. Seguridad de la Información</h3>
                <p className="my-1.5">La seguridad de tu información es una prioridad para nosotros. Para proteger tus datos, utilizamos Supabase, una base de datos externa que implementa medidas de seguridad avanzadas. Sin embargo, ningún método de transmisión por Internet o de almacenamiento electrónico es 100% seguro. Por lo tanto, aunque nos esforzamos por usar medios comercialmente aceptables para proteger tu información personal, no podemos garantizar su seguridad absoluta.</p>
                <p className="my-1.5">En el caso improbable de que nuestra base de datos, alojada por Supabase, sufra un ataque o brecha de seguridad que resulte en la divulgación no autorizada de tu información, te informaremos conforme a la normativa aplicable, pero Bilern no será responsable por los daños o la divulgación de datos resultante de dichos incidentes de seguridad que estén fuera de nuestro control directo.</p>
                <p className="my-1.5">Recomendamos encarecidamente a los usuarios que tomen precauciones al manejar y compartir información personal y que utilicen contraseñas robustas para sus cuentas en Bilern.</p>

                <h3>8. Política de Cookies</h3>
                <p className="my-1.5">Utilizamos cookies y tecnologías similares para mejorar tu experiencia en Bilern. Las cookies nos permiten reconocer tu dispositivo, personalizar el contenido y analizar el tráfico en nuestra plataforma.</p>

                <h4>Tipos de cookies que utilizamos:</h4>
                <ul>
                <li>Cookies esenciales: Necesarias para el funcionamiento básico de la plataforma.</li>
                <li>Cookies de análisis: Para ayudarnos a entender cómo usas nuestra plataforma y mejorarla.</li>
                </ul>

                <p className="my-1.5">Puedes gestionar tus preferencias de cookies en cualquier momento a través de las configuraciones de tu navegador. Al usar Bilern, aceptas el uso de cookies.</p>

                    
                <h3>9. Uso de Servicios de Terceros</h3>
                <p className="my-1.5">En Bilern, utilizamos diversos servicios de terceros para enriquecer tu experiencia en nuestra plataforma. Esto incluye la incorporación de contenidos multimedia, como videos de YouTube, para proporcionarte una experiencia más dinámica e informativa.</p>

                <p className="my-1.5">Nuestra plataforma incluye videos que están alojados en YouTube. Esto nos permite presentarte contenido relevante directamente en nuestra página. Al interactuar con estos videos (por ejemplo, al reproducirlos), es posible que se recopilen datos sobre ti por parte de YouTube, conforme a su política de privacidad. Nosotros no controlamos la recopilación o el uso de tu información por parte de servicios de terceros como YouTube. Por lo tanto, te recomendamos revisar las políticas de privacidad de estos servicios para entender mejor cómo manejan tu información.</p>
                <p className="my-1.5">YouTube es un servicio proporcionado por Google LLC o sus afiliados. Para obtener más información sobre las prácticas de privacidad de Google, incluido YouTube, por favor visita <a href="https://policies.google.com/privacy">Política de Privacidad de Google</a>.</p>

                <h4>Recopilación de Datos por Parte de Terceros</h4>
                <p className="my-1.5">El uso de servicios de terceros en nuestra plataforma puede implicar la recopilación de datos personales, como tu dirección IP, tipo de navegador, sistema operativo, y la interacción específica que tengas con el contenido incrustado. Estos datos son recopilados directamente por los proveedores de terceros y están sujetos a sus términos y políticas de privacidad. Nosotros no tenemos acceso ni control sobre los cookies u otros métodos de seguimiento que estos terceros puedan usar.</p>

                <h4>Consentimiento y Control</h4>
                <p className="my-1.5">Al utilizar nuestra plataforma, reconoces y aceptas que la interacción con estos contenidos de terceros puede resultar en la recopilación de datos personales por parte de los servicios de terceros mencionados. Si no deseas que se recolecten tus datos, te recomendamos no interactuar con estos contenidos.</p>
                <p className="my-1.5">Para usuarios de la Unión Europea, este proceso se enmarca dentro del Reglamento General de Protección de Datos (RGPD). Estamos comprometidos con la protección de tu privacidad y tus derechos de protección de datos. Si tienes alguna pregunta o solicitud relacionada con tus datos personales, por favor contáctanos a través de contacto@bilern.com</p>

                <h3>10. Agradecimientos</h3>
                <p className="my-1.5">En Bilern, utilizamos iconos proporcionados por <a href="https://fontawesome.com/" target="_blank">FontAwesome</a> bajo su licencia gratuita. Estos iconos ayudan a mejorar la interfaz de usuario, haciéndola más intuitiva y visualmente atractiva. Agradecemos sinceramente a Fonticons, Inc. por hacer disponibles estos recursos y apoyar a la comunidad de desarrolladores y diseñadores.</p>
                <p className="my-1.5">Para más información sobre los términos de uso de los iconos de FontAwesome, por favor, visita su sitio web oficial.</p>

                <h3>11. Cambios a los Términos</h3>
                <p className="my-1.5">Nos reservamos el derecho de modificar estos Términos en cualquier momento. Publicaremos los Términos modificados en la aplicación, y estos entrarán en vigor inmediatamente después de su publicación. Tu uso continuado de Bilern después de cualquier cambio significa que aceptas los nuevos Términos.</p>
                
                <h3>12. Contacto</h3>
                <p className="my-1.5">Si tienes preguntas sobre estos Términos, por favor, contacta con nosotros a través de contacto@bilern.com</p>
                <p className="my-1.5">Agradecemos tu elección de Bilern para tu desarrollo y mejora en el estudio. Estamos comprometidos en ofrecerte una experiencia de aprendizaje única y efectiva.</p>
            </div>
        </DashboardLayout>
    </>)
}
