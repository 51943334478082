import { useState, useEffect } from 'react'
import { GoBackArrow} from './common/ui/GoBackArrow'
import { FloatingPlayButton } from './common/ui/FloatingPlayButton'
import { FlashcardCard } from './FlashcardCard'
import ImportPopup from '@/components/pages/importWithAI/ImportPopup'
import Spinner from './common/ui/Spinner'
import { Folder, Mazo } from '../types/types'
import { Card } from '../types/cards'
import '../styles/Mazos.css'
import Icon from './icons/Icon'

interface FlashcardsListProps {
	folder: Folder | null
	mazo: Mazo | null
	onClickAddFlashcard: () => void
	onClickEditFlashcard:  (flashcard: Card) => void
	onClickReturn: () => void
	startExam: (flashcards: Card[], modoEstudiar: number) => void
}

export const FlashcardsList = ({ folder, mazo, onClickAddFlashcard, onClickReturn, onClickEditFlashcard, startExam }: FlashcardsListProps) => {

	const [importPopupOpen, setImportPopupOpen] = useState<boolean>(false)

	const [loading, setLoading] = useState(true)
	const [flashcards, setFlashcards] = useState<Card[]>([])
	const [repasar, setRepasar] = useState(true)
	const [numEstudiar, setNumEstudiar] = useState(0)
	const [modoEstudiar, setModoEstudiar] = useState(0)

	useEffect(() => {
		setLoading(true)
		fetchFlashcards()
	}, [mazo])

	const fetchFlashcards = async () => {
		if(mazo){
			try {
				const response = await fetch(
					`${import.meta.env.VITE_API_URL}/card/get_for_study?mazoId=${mazo.id}&FCList=${true}`, 
					{
						method: 'GET',
						headers: { 'Content-Type': 'application/json', },
          	credentials: 'include'
					}
				)
				if (!response.ok){
					throw new Error('Error al conseguir las flashcards');
				}			


				const data = await response.json()
				if (data.modoEstudio == 4) {
					setRepasar(false)
					setNumEstudiar(data.numEstudiar)
					setModoEstudiar(4)
				} else if (data.modoEstudio == 5) {
					setRepasar(true)
					setNumEstudiar(0)
					setModoEstudiar(5)
				} else if (data.modoEstudio == 2) {
					setRepasar(false)
					let tarjetas=0
					let numero=data.numEstudiar
					for(let i=0; i<data.Array.length; i++){
					  if(data.Array[i].final_bool==false && numero>0){
						tarjetas++
						numero--
					  }
					}
					setNumEstudiar(Math.min(data.numEstudiar,tarjetas))

					setModoEstudiar(2)
				  } else if (data.modoEstudio == 1) {
					setRepasar(false)
					setNumEstudiar(data.numEstudiar)
					setModoEstudiar(1)
				  }
				setFlashcards(data.Array)
				setLoading(false)

			} catch (error) {
				console.error('Error:', error);
				return
			}
		}
	}

	const empezarExamen = () => {
		if(modoEstudiar == 2){
			let tarjetas=[]
			let numero=numEstudiar
			for(let i=0; i<flashcards.length; i++){
				if(flashcards[i].final_bool==false && numero>0){
				tarjetas.push(flashcards[i])
				numero--
				}
			}
			startExam(tarjetas, modoEstudiar)
		} else if(modoEstudiar == 1){
			let tarjetas: Card[]=[]
			function obtenerFechaActual() {
			  const fecha = new Date();
			  const año = fecha.getFullYear();
			  const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // getMonth() devuelve de 0 a 11, por eso sumamos 1
			  const dia = ('0' + fecha.getDate()).slice(-2);
			
			  return `${año}-${mes}-${dia}`;
			}
			let hoy = obtenerFechaActual();
			flashcards.forEach(flashcard => {
			  flashcard.exam_date.forEach((date, index) => {
				if (date === hoy && !flashcard.exam_bool[index]) {
				  tarjetas.push(flashcard)
				}
			  });
			});
	  
			startExam(tarjetas, modoEstudiar)
		} else if(numEstudiar > 0){
			let FCEstudiar = flashcards.slice(0, numEstudiar);
			startExam(FCEstudiar, modoEstudiar)
		} else{
			startExam(flashcards, modoEstudiar)
		}
	}

	const deleteFlashcard = async (flashcard: Card) => {
		const updatedFlashcards = flashcards.filter((fc) => fc.id !== flashcard.id);
		setFlashcards(updatedFlashcards);
	  };

	return (
		<>
			{importPopupOpen && <ImportPopup 
				mazoId={mazo!.id}
				open={importPopupOpen} 
				onClose={(exitRefresh) => exitRefresh ? onClickReturn() : setImportPopupOpen(false)}
			/>}
			<div className='flex flex-col sm:flex-row justify-between items-center pt-5'>
				<div className='w-80 sm:w-auto flex flex-col sm:flex-row justify-center sm:justify-start'>
					<GoBackArrow title={mazo?.name} subtitle={folder?.name} onClick={onClickReturn} className='w-80 ml-auto'/>
					{(modoEstudiar == 2 || modoEstudiar == 1) && 
					<div style={{display:'flex', margin:'20px 0 0 62px', color:'var(--color-medium)', alignItems:'center', fontWeight:'500'}}>
          				<Icon iconName='scroll' size='40' isClickable={false} /><div style={{marginLeft:'10px', fontSize:'20px'}}>Tarjetas de Examen pendientes hoy</div>
					</div>}
				</div>
				<div className='w-full sm:w-auto flex flex-col xl:flex-row gap-5 items-center mx-5'>
					<div className='text-textlight-color text-lg font-semibold'>
						{
							flashcards.length == 0 ? 'No hay tarjetas en este tema' 
							: numEstudiar == 1 ? 
								numEstudiar+' tarjeta pendiente hoy ' 
							: numEstudiar > 0 ? 
								numEstudiar+' tarjetas pendientes hoy '
							: 'Estudio terminado por hoy'
						}
					</div>
					<div className='flex flex-col lg:flex-row gap-3 justify-center items-center'>
						<button 
							className='w-52 bg-[var(--color-primary)] text-white' 
							onClick={() => setImportPopupOpen(true)}
						>
							Crear tarjetas con IA
						</button>
						<button 
							className='w-52'
							onClick={onClickAddFlashcard}
						>
							Añadir tarjetas a mano
						</button>
					</div>
				</div>
			</div>
			{ !loading ?
				<>
					<div className='grid grid-cols-[repeat(auto-fill,minmax(290px,1fr))] gap-5 justify-items-center mt-5 md:mt-8 mb-24 p-5'>
						{flashcards.map((flashcard, index) => (
							<FlashcardCard key={flashcard.id} index={index} flashcard={flashcard} onDeleteFlashcard={(flashcard)=>deleteFlashcard(flashcard)} onEditFlashcard={(flashcardEdit) => onClickEditFlashcard(flashcardEdit)}/>
						))}
					</div>
					{ flashcards.length !== 0 &&
					<div  className="button-text">
						<FloatingPlayButton text={(repasar==true?'Repasar ':'Estudiar ')+mazo?.name} onClickPlay={() => empezarExamen()}/>
					</div>
					}
				</> : 
				<Spinner />
			}
		</>
	)
}
