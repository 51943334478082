import { ReactNode } from 'react'

interface SectionLayoutProps {
  width?: string
  height?: string
  children: ReactNode
}

export default function SectionLayout({ width, height, children }: SectionLayoutProps) {

  return (
    <section 
      style={{ width: width, height: height }}
      className='bg-[var(--color-background)] rounded-xl p-3 m-5 flex-grow'
    >
      {children}
    </section>
  )
}