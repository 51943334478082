import { ExamTask } from '@/types/types'
import { useEffect, useState } from 'react'
import Icon from './icons/Icon'


interface ExamTaskCardProps {
    task: ExamTask
    boolToday: number
    playExam: (cartasDiasPrevios: any[]) => void
}

export default function ExamTaskCard ({task, boolToday, playExam}:ExamTaskCardProps) {

    const isDone = task.done == task.total
    
    
    return (
        <div className="w-full lg:w-[38vw] h-auto rounded-[var(--border-radius)] bg-[var(--color-background)] flex font-medium items-start overflow-hidden m-[10px]">

            {(boolToday!=0 || isDone) && <div style={{marginLeft:'12px'}}></div>}
            {!isDone?(<Icon iconName='circleBlank' size='20' isClickable={false} />):(<div style={{color:'var(--color-secondary)' , marginRight:'10px'}}>
                <Icon iconName='checkCircle' size='20' isClickable={false} /></div>)}
            
            {boolToday==0 ? <div style={{
                color: 'var(--color-text-light)',
                cursor: 'pointer',
                marginLeft: '3px',}}
            onMouseEnter={(e) => e.currentTarget.style.color = 'var(--color-purple)'}
            onMouseLeave={(e) => e.currentTarget.style.color = 'var(--color-text-light)'}
            >
                {!isDone && (
                <Icon iconName='circlePlay' size='20' 
                onClick={() => {
                    if (!isDone) {
                        playExam(task.cards);
                    }
                }}
                isClickable={!isDone}/>
            )}

            </div>:
            <div style={{marginRight:'11px'}}></div>}
            <div style={{
                textDecoration: isDone ? 'line-through' : 'none',
                textDecorationThickness: '1.2px',
                marginLeft: '10px',
                marginTop: '-3px', 
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {boolToday !== 1 ? (
                    <>{task.done}/{task.total} Tarjetas - {task.name}</>
                ):(<>{task.total} Tarjetas - {task.name}</>)}
            </div>
        </div>
    )
}
