import { useEffect, useState } from 'react'
import Popup from './common/ui/Popup'
import { Card } from '@/types/cards'

interface ExamSimulacroProps {
    simulacroOptBool: boolean
    cerrarSimulacro: () => void
    empezarSimulacro: (cards:Card[], time: number) => void
    datos: any
}

export default function ExamSimulacro ({simulacroOptBool, cerrarSimulacro, empezarSimulacro, datos}:ExamSimulacroProps) {
    
    const [selectedButtons, setSelectedButtons] = useState<{ [key: string]: boolean }>({
        nuevos: false,
        aprendiendo: false,
        aprendidos: false,
    });
    const [selectedConceptButtons, setSelectedConceptButtons] = useState<{ [key: string]: boolean }>({
        flashcard: false,
        ocultar: false,
        test: false,
    });
    const [timeValue, setTimeValue] = useState('');
    const [cardCountValue, setCardCountValue] = useState('');
    const [botonActivo, setBotonActivo] = useState(false);
    const [showNoCardsMessage, setShowNoCardsMessage] = useState(false);
    const [numCartasPosibles, setNumCartasPosibles] = useState(0);

    const toggleButton = (button: string) => {
        setSelectedButtons(prevState => ({
            ...prevState,
            [button]: !prevState[button],
        }));
    };

    const toggleConceptButton = (button: string) => {
        setSelectedConceptButtons(prevState => ({
            ...prevState,
            [button]: !prevState[button],
        }));
    };



    useEffect(() => {
        let cartasFinales=[]
        let cardApoyo=[]
        let cards=datos.arrayRepaso
        for(let i=0;i<cards.length;i++){
            let cardData= cards[i].aciertos
            if(cardData && cardData.length>0){
                if(selectedButtons.aprendidos && cardData[cardData.length-1] && cardData[cardData.length-2]){
                    cardApoyo.push(cards[i])
                }
                else if(selectedButtons.aprendiendo && cardData.length>0 && (cardData.length==1 || cardData.length>1 && (!cardData[cardData.length-1] || !cardData[cardData.length-2]))){
                    cardApoyo.push(cards[i])
                }
            } else{
                if(selectedButtons.nuevos){
                    cardApoyo.push(cards[i])
                }
            }
        }

        for(let i=0;i<cardApoyo.length;i++){
            if(selectedConceptButtons.flashcard && cardApoyo[i].type=='flashcard'){
                cartasFinales.push(cardApoyo[i])
            }
            else if(selectedConceptButtons.ocultar && cardApoyo[i].type=='ocultar'){
                cartasFinales.push(cardApoyo[i])
            }
            else if(selectedConceptButtons.test && cardApoyo[i].type=='test'){
                cartasFinales.push(cardApoyo[i])
            }
        }
        setNumCartasPosibles(cartasFinales.length)

        if(cartasFinales.length>0){
            setShowNoCardsMessage(false)
        }
    }, [selectedConceptButtons, selectedButtons]);


    const handleEmpezarSimulacro = () => {
        let cartasFinales=[]
        let cardApoyo=[]
        let cards=datos.arrayRepaso
        for(let i=0;i<cards.length;i++){
            let cardData= cards[i].aciertos
            if(cardData && cardData.length>0){
                if(selectedButtons.aprendidos && cardData[cardData.length-1] && cardData[cardData.length-2]){
                    cardApoyo.push(cards[i])
                }
                else if(selectedButtons.aprendiendo && cardData.length>0 && (cardData.length==1 || cardData.length>1 && (!cardData[cardData.length-1] || !cardData[cardData.length-2]))){
                    cardApoyo.push(cards[i])
                }
            } else{
                if(selectedButtons.nuevos){
                    cardApoyo.push(cards[i])
                }
            }
        }

        for(let i=0;i<cardApoyo.length;i++){
            if(selectedConceptButtons.flashcard && cardApoyo[i].type=='flashcard'){
                cartasFinales.push(cardApoyo[i])
            }
            else if(selectedConceptButtons.ocultar && cardApoyo[i].type=='ocultar'){
                cartasFinales.push(cardApoyo[i])
            }
            else if(selectedConceptButtons.test && cardApoyo[i].type=='test'){
                cartasFinales.push(cardApoyo[i])
            }
        }

        if(cartasFinales.length>0){
            for (let i = cartasFinales.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [cartasFinales[i], cartasFinales[j]] = [cartasFinales[j], cartasFinales[i]];
            }
        
            if(parseInt(cardCountValue)>cartasFinales.length){
                empezarSimulacro(cartasFinales,Number(timeValue))
            } else{
                empezarSimulacro(cartasFinales.slice(0,parseInt(cardCountValue)),Number(timeValue))
            }
        } else{
            setShowNoCardsMessage(true);
        }
    }



    useEffect(() => {
        setBotonActivo(parseInt(timeValue)> 0 && parseInt(cardCountValue)> 0)
    }, [timeValue, cardCountValue]);

    return (
        <>
    <Popup open={simulacroOptBool} onClose={() => cerrarSimulacro()} width="600px">
        <div className="flex flex-col justify-center p-[10px] items-center">
            <div className="text-[25px] font-bold text-[var(--color-primary)]">Creación de simulacro</div>
            {/* Primera fila */}
            <div className="mt-[30px] text-left font-medium w-[100%] sm:w-[90%]">
                <div>¿Qué tarjetas quieres que se pregunte?</div>
                <div className="flex justify-center sm:justify-between flex-wrap sm:flex-nowrap">
                    <button
                        className={`mr-[10px] mt-[10px]  ${selectedButtons.nuevos ? 'bg-[var(--color-green-secondary)] text-white border-white hover:bg-[var(--color-green-secondary)] hover:text-white' : 'bg-white text-[var(--color-text)] border-[var(--color-text)] hover:bg-white hover:border-[var(--color-text)]'} border p-[7px_15px] h-full w-[130px]`}
                        onClick={() => toggleButton('nuevos')}
                    >
                        Por aprender
                    </button>
                    <button
                        className={`mr-[10px] mt-[10px]  ${selectedButtons.aprendiendo ? 'bg-[var(--color-green-secondary)] text-white border-white hover:bg-[var(--color-green-secondary)] hover:text-white' : 'bg-white text-[var(--color-text)] border-[var(--color-text)] hover:bg-white hover:border-[var(--color-text)]'} border p-[7px_15px] h-full w-[130px]`}
                        onClick={() => toggleButton('aprendiendo')}
                    >
                        Aprendiendo
                    </button>
                    <button
                        className={`${
                            selectedButtons.aprendidos
                                ? 'bg-[var(--color-green-secondary)] text-white border-white hover:bg-[var(--color-green-secondary)] hover:text-white'
                                : 'bg-white text-[var(--color-text)] border-[var(--color-text)] hover:bg-white hover:border-[var(--color-text)]'
                        } border p-[7px_15px] h-full w-[130px] mt-[10px]  `}
                        onClick={() => toggleButton('aprendidos')}
                    >
                        Aprendidas
                    </button>
                    </div>
                </div>

                {/* Segunda fila */}
                <div className="mt-[20px] text-left font-medium w-[100%] sm:w-[90%]">
                    <div>¿Qué tipo de tarjetas quieres?</div>
                    <div className="flex mt-[10px] justify-center sm:justify-between">
                        <button
                            className={`mr-[10px] ${
                                selectedConceptButtons.flashcard
                                    ? 'bg-[var(--color-green-secondary)] text-white border-white hover:bg-[var(--color-green-secondary)] hover:text-white'
                                    : 'bg-white text-[var(--color-text)] border-[var(--color-text)] hover:bg-white hover:border-[var(--color-text)]'
                            } border p-[7px_15px] h-full w-[130px]`}
                            onClick={() => toggleConceptButton('flashcard')}
                        >
                            Flashcard
                        </button>
                        <button
                            className={`mr-[10px] ${
                                selectedConceptButtons.ocultar
                                    ? 'bg-[var(--color-green-secondary)] text-white border-white hover:bg-[var(--color-green-secondary)] hover:text-white'
                                    : 'bg-white text-[var(--color-text)] border-[var(--color-text)] hover:bg-white hover:border-[var(--color-text)]'
                            } border p-[7px_15px] h-full w-[130px]`}
                            onClick={() => toggleConceptButton('ocultar')}
                        >
                            Ocultar
                        </button>
                        <button
                            className={`${
                                selectedConceptButtons.test
                                    ? 'bg-[var(--color-green-secondary)] text-white border-white hover:bg-[var(--color-green-secondary)] hover:text-white'
                                    : 'bg-white text-[var(--color-text)] border-[var(--color-text)] hover:bg-white hover:border-[var(--color-text)]'
                            } border p-[7px_15px] h-full w-[130px]`}
                            onClick={() => toggleConceptButton('test')}
                        >
                            Test
                        </button>
                    </div>
                </div>

                {/* Tercera fila */}
                <div className="flex sm:justify-between justify-around mt-[20px] w-[100%] sm:w-[90%] text-left font-medium">
                    <div className="flex flex-col text-center items-center">
                        <div className='sm:mb-0 mb-5'>¿Cuánto tiempo quieres tener?</div>
                        <div className="flex items-center">
                            <input type="text" placeholder="30" className="sm:w-[130px] w-[100px] text-center mt-[5px] mr-[5px]"
                                onInput={(event) => {
                                    const input = event.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                    setTimeValue(input.value.replace(/[^0-9]/g, ''));

                                }}
                                onBlur={(event) => {
                                    const input = event.target as HTMLInputElement;
                                    if (input.value === '' || parseInt(input.value) < 1) {
                                        input.value = '1';
                                        setTimeValue('1');
                                    }
                                }}
                            />
                            <div style={{fontWeight:'400', marginTop:'3px'}}>min</div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center text-center">
                        <div className="flex sm:flex-row flex-col">
                            ¿Cuántas tarjetas?
                            <div className="font-normal ml-[10px]">
                                Núm. máx: {numCartasPosibles}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="text"
                                placeholder="100"
                                className="sm:w-[130px] w-[100px] text-center mt-[5px] mr-[5px]"
                                onInput={(event) => {
                                    const input = event.target as HTMLInputElement;
                                    input.value = input.value.replace(/[^0-9]/g, '');
                                    setCardCountValue(input.value.replace(/[^0-9]/g, ''));
                                }}
                                onBlur={(event) => {
                                    const input = event.target as HTMLInputElement;
                                    if (input.value === '' || parseInt(input.value) < 1) {
                                        input.value = '1';
                                        setCardCountValue('1');
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                    <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', marginTop:'20px'}}>
                        {showNoCardsMessage && (<div style={{fontSize: '15px', fontWeight:'500', color:'var(--color-delete)'}}>
                            No se han encontrado cartas que cumplan los requisitos
                        </div>)}
                        <button 
                            onClick={() => handleEmpezarSimulacro()} 
                            style={{ 
                                backgroundColor: botonActivo?'var(--color-medium)':'var(--color-text-light)', 
                                pointerEvents: botonActivo ? 'auto' : 'none',
                                marginTop:'10px'
                            }}
                            disabled={!botonActivo}

                        >
                        Empezar simulacro
                        </button>
                    </div>
                </div>

            </Popup>
        </>
    )
}
