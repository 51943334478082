import { useState, useEffect, useRef } from 'react'
import Icon from '@/components/icons/Icon'
import { MazoCard } from './MazoCard'
import Spinner from './common/ui/Spinner'
import { Folder, Mazo } from '../types/types'
import '../styles/Mazos.css'
import { Card } from '@/types/cards'

interface MazosGalleryProps {
	folder: Folder | null
	onClickMazo: (mazo: Mazo | null) => void
	startExam: (mazo: Mazo, cartas: Card[], modoEstudiar: number) => void
	updateFolder: (esnombre: boolean) => void
}

export const MazosGallery = ({ folder, onClickMazo, startExam, updateFolder }: MazosGalleryProps) => {
	
	const inputRef = useRef<HTMLInputElement>(null)
	const [mazos, setMazos] = useState<Mazo[]>([])
	const [isInput, setIsInput] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loadingCards, setLoadingCards] = useState(false)
	const [nombreExiste, setNombreExiste] = useState(true)
	const [nombreAsigExiste, setNombreAsigExiste] = useState(false)
	const [nombresOcupados, setNombresOcupados] = useState<string[]>([])
	const [nombresAsigOcupados, setNombresAsigOcupados] = useState<string[]>([])
	const [isOptions, setIsOptions] = useState(false)
	const [isOnEdit, setIsOnEdit] = useState(false)
	const [isOnDelete, setIsOnDelete] = useState(false)
	const [isOnForget, setIsOnForget] = useState(false)

	const nameInputRef = useRef<HTMLInputElement>(null)

	const abortControllerRef = useRef<AbortController | null>(null);

	const fetchMazos = async () => {
	  setLoading(true);
	  setIsInput(false);
	
	  if (folder) {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort()
			}
		
			const abortController = new AbortController();
			abortControllerRef.current = abortController; 
		
			try {
				const response = await fetch(
					`${import.meta.env.VITE_API_URL}/mazo/get_from_folder?folderId=${folder.id}`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				if (!response.ok) {
				throw new Error('Error en la actualizacion de la asignatura');
				}
				const data = await response.json();
				let mazosAgregar: Mazo[] = [];
				for (let i = 0; i < data.length; i++) {
				mazosAgregar.push(data[i]);
				}
				setMazos(mazosAgregar);
				setLoadingCards(true);
				setLoading(false);
			} catch (error) {
				if ((error as Error).name != 'AbortError') {
					console.error('Error:', error);
				}
			}
	  }
	};

	useEffect(() => {
		setIsOptions(false)
		setIsOnDelete(false)
		setIsOnForget(false)
		setIsOnEdit(false)
		setNombreAsigExiste(false)
		setNombreExiste(true)
		fetchMazos()
	}, [folder])

	useEffect(() => {
		if (isOnEdit && nameInputRef.current) {
			nameInputRef.current.focus()
		} else if (!isOnEdit) {
			setNombresAsigOcupados([])
		}
	}, [isOnEdit]);

	useEffect(() => {
		if (isInput && inputRef.current) {
		  inputRef.current.focus()
		} else if (!isInput) {
			setNombresOcupados([])
		}
	  }, [isInput])
	  /*
	  useEffect(() => {
		const fetchNumForMazos = async () => {
		  if(loadingCards) {
			let newMazos = [...mazos];
			try{
			  const response = await fetch(
					`${import.meta.env.VITE_API_URL}/mazo/num_flashcards`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({mazos: mazos}),
			  	}
				)
			  if(!response.ok){
					throw new Error('Error al obtener el número de flashcards de los mazos')
			  }
			  const data = await response.json()
			  newMazos = data
			} catch(error) {
			  console.log(error);
			}
			if (JSON.stringify(mazos) !== JSON.stringify(newMazos)) { 
			  setMazos(newMazos);
			}
		  }
		};
		fetchNumForMazos();
	  }, [loading]);
	  */

	const comprobarNMazo = async () => {
		let bandera=0
		for(let i=0;i<nombresOcupados.length;i++){
		  if(inputRef.current?.value==nombresOcupados[i]){
			setNombreExiste(true)
			bandera++
		  }
		}
		if(inputRef.current?.value==''){
		  setNombreExiste(true)
		  bandera++
		}
		if (bandera==0){
		  setNombreExiste(false)
		}
	}

	const comprobarNAsig = async () => {
		let bandera=0
		for(let i=0;i<nombresAsigOcupados.length;i++){
		  if(nameInputRef.current?.value==nombresAsigOcupados[i]){
			setNombreAsigExiste(true)
			bandera++
		  }
		}
		if(nameInputRef.current?.value==''){
			setNombreAsigExiste(true)
		  bandera++
		}
		if (bandera==0){ 
			setNombreAsigExiste(false) 
		}
	}

	const MirarNombresMazoOc = async () => {
		if(folder){
			try {
				const response = await fetch( 
					`${import.meta.env.VITE_API_URL}/mazo/get_from_folder?folderId=${encodeURIComponent(folder.id)}`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				if (!response.ok){
					throw new Error('Error en la actualizacion de la asignatura');
				}			
				const data = await response.json()
				for(let i=0;i<data.length;i++){
					nombresOcupados.push(data[i].name)
				}
			} catch (error) {
				console.error('Error:', error);
				return
			}
		}
	}

	const MirarNombresAsigOc = async () => {
		if(folder){
			try{
				const response = await fetch( 
					`${import.meta.env.VITE_API_URL}/folder/get_from_user`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
					}
					)
				if(!response.ok){
					throw new Error()
				}
				const data = await response.json()
				let folderAgregar: string[] = []
				for(let i=0;i<data.length;i++){
					if(data[i].name!=folder.name){
						folderAgregar.push(data[i].name)
					}
				}
				setNombresAsigOcupados(folderAgregar)
				
			} catch(error) {
				console.log(error)
			}
		}
	}

	
	const getNewMazo = async () => {
		if (inputRef.current) {
			if(folder){
				const name = inputRef.current.value
				setNombreExiste(true)	
				setIsInput(false)
				try{
					const response = await fetch(
						`${import.meta.env.VITE_API_URL}/mazo/create`, 
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({name: name, asignatura_id: folder.id}),
					  }
					)
					if(!response.ok){
						throw new Error('Error en agregar nuevo mazo');
					}

					fetchMazos()
				} catch(error) {
					console.log(error)
				}
			}
		}
	}
	
	const handleInputKeyDown = (e: React.KeyboardEvent) => {
		if(!nombreExiste){
			if (e.key === 'Enter') {
				getNewMazo()
			}
		}
	}

	const CambiarNombreAsignatura = async () => {
		let valor = nameInputRef.current?.value ?? '';
		setIsOnEdit(false)
		setIsOptions(false)
		if (nameInputRef.current) {
			if (folder) {
				const name = nameInputRef.current?.value
				try {
					const response = await fetch(
						`${import.meta.env.VITE_API_URL}/folder/renameFolder?newName=${encodeURIComponent(name)}&folderId=${encodeURIComponent(folder.id)}`,
						{
							method: 'PATCH',
							headers: {
								'Content-Type': 'application/json',
							}
						})
					if (!response.ok){
						throw new Error('Error en la actualizacion de la asignatura')
					}			
					/*
					const localFolders = localStorage.getItem('folders')
					let newLocalFolders = JSON.parse(localFolders || '[]') as Folder[]
					newLocalFolders = newLocalFolders.map(localFolder => 
						localFolder.id === folder?.id ? {...localFolder, name: valor} : localFolder
					)
					localStorage.setItem('folders', JSON.stringify(newLocalFolders))
					*/
					updateFolder(true)
					folder.name = valor
				} catch (error) {
					console.error('Error:', error)
				}
			}
		}
	}

	const handleDeleteAsignatura = async (event: React.MouseEvent) => {
		event.stopPropagation()
		setIsOptions(false)
		setIsOnDelete(false) 
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/folder/delete?folderId=${encodeURIComponent(folder!.id)}`, 
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include'
			  }
			)
			if (!response.ok){
				throw new Error('Error al eliminar asignatura');
			}			
			/*
			localStorage.removeItem('lastFolder')
			const localFolders = localStorage.getItem('folders')
			let newLocalFolders = JSON.parse(localFolders || '[]') as Folder[]
			newLocalFolders = newLocalFolders.filter(localFolder => localFolder.id !== folder?.id)
			localStorage.setItem('folders', JSON.stringify(newLocalFolders))
			*/
			updateFolder(false)
			folder=null
		} catch (error) {
			console.error('Error:', error);
			return
		}
	}

	const handleForgetAsignatura = async (event: React.MouseEvent) => {
		event.stopPropagation()
		setIsOptions(false)
		setIsOnForget(false)
		try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/folder/forget?folderId=${encodeURIComponent(folder!.id)}`,
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			if (!response.ok){
				throw new Error('Error en la actualizacion de la asignatura')
			}			
			fetchMazos()
		} catch (error) {
			console.error('Error:', error)
		}
	}

	
	if (folder) return (
		<div className='MazosGallery'>
			<div className='mazos_header'>
				{isOnEdit ?
					<input className='input' ref={nameInputRef} onChange={comprobarNAsig} type='text' defaultValue={folder.name} style={{border:'none', backgroundColor:'var(--color-background)', marginRight:'40px'}}
						onKeyDown = {(e) => {
							if(!nombreAsigExiste){
								if (e.key === 'Enter' ) {
									e.preventDefault();
									CambiarNombreAsignatura()
								}
							}
						}} onClick={(e) => e.stopPropagation()}
					/> 
					:
					<h2  style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',marginRight:'40px' }}>{folder?.name}</h2>
				}
				{isOptions ?
					<div className='options_header'>
						{isOnDelete?
							<>
								<h4 className="h4Hover" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',	maxWidth: '35vw' }}>¿Estás seguro de que deseas eliminar {folder?.name}</h4><h4>?</h4>
								<div className='mazo_container_si_no_asignatura'>
									<button className='mal' onClick={() => setIsOnDelete(false)}>
										No
									</button>
									<button className='bien' onClick={handleDeleteAsignatura}>
										Si
									</button>
								</div>
							</> 
							: 
							<>
								{isOnForget?
									<>
										<h4 className="h4Hover" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',	maxWidth: '35vw' }}>¿Deseas eliminar tu progreso con {folder?.name}</h4><h4>?</h4>
										<div className='mazo_container_si_no_asignatura'>
										<button className='mal' onClick={() => setIsOnForget(false)}>
											No
										</button>
										<button className='bien' onClick={handleForgetAsignatura}>
											Si
										</button>
										</div>
									</>
									:
									<>
										{isOnEdit ?
											<>
												<div className='mazo_container_si_no_asignatura_rename'>
												<button className='mal-asignatura' onClick={()=>{setIsOnEdit(false);setNombreAsigExiste(false)}}>
													Cancelar
												</button>
												<button className='bien' disabled={nombreAsigExiste} onClick={CambiarNombreAsignatura}>
													Cambiar nombre
												</button>
												</div>
											</>
											:
											<>
												<div className='mazo_option_borrar' onClick={() => setIsOnDelete(true)}>
													<Icon iconName='trash' />
													<label>Eliminar Asignatura</label>
												</div>
												<div className='mazo_option_renombrar' onClick={()=>{setIsOnEdit(true); MirarNombresAsigOc()}}>
													<Icon iconName='edit' />
													<label>Renombrar</label>
												</div>
												<div className='mazo_option_olvidar' onClick={() => setIsOnForget(true)}>
													<Icon iconName='redo' />
													<label>Olvidar Asignatura</label>
												</div>
											</>
										}
									</>
								}
							</>
						}
						{ !isOnDelete && !isOnForget && !isOnEdit &&
							<Icon iconName='xmark' size='30' onClick={() => {setIsOptions(false);setIsOnDelete(false);setIsOnForget(false);setIsOnEdit(false);setNombreAsigExiste(false)}} className='xmarkIcon'/>
						}
					</div>
					: <Icon iconName='options' size='30' onClick={() => setIsOptions(true)} />
    		}
			</div>
			{ !loading ?
				<div className='mazos_display'>
					{mazos.map((mazo, index) => (
						<MazoCard 
							key={index}
							mazo={mazo}
							folder={folder}
							reload={fetchMazos}
							onClickMazo={onClickMazo}
							onClickPlay={(cards,modoEstudiar) => startExam(mazo, cards, modoEstudiar)} // Va mal pues la page Mazos no tiene en currentMazo este mazo 
						/>
					))}
					{isInput ? (
					<div className='new_mazo_container'>
						<input placeholder='Nombre del tema' type='text' ref={inputRef} onChange={comprobarNMazo}
						onKeyDown={handleInputKeyDown} style={{backgroundColor:'var(--color-background)', border:'none'}}/>
						
						<div className='mazo_container_si_no'>
						<button className='mal' onClick={() => { setIsInput(false)}}>
							No                
						</button>
						<button className='bien' disabled={nombreExiste} onClick={getNewMazo}>
							Si
						</button>
						</div>
					</div>
					) : null}
					{folder !== null && !loading &&

						<button className='add_bt' onClick={()=>{setIsInput(true); MirarNombresMazoOc()}}>
							<Icon iconName='plus' />
							<h4>Nuevo Tema</h4>
						</button>

					}
				</div> :
				<Spinner />
			}
		</div>
	)
}