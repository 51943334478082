import { CardType } from '@/types/cards'
import { Editor } from '@tiptap/react'
import { useEffect, useState } from 'react'

interface ToolkitProps {
  editor: Editor | null
  cardType: CardType
}

export default function Toolkit({ editor, cardType }: ToolkitProps) {
  const [isBoldActive, setIsBoldActive] = useState(false)
  const [isItalicActive, setIsItalicActive] = useState(false)
  const [isUnderlineActive, setIsUnderlineActive] = useState(false)
  const [isStrikeActive, setIsStrikeActive] = useState(false)
  const [isCodeActive, setIsCodeActive] = useState(false)

  useEffect(() => {
    if (editor) {
      const updateActiveStates = () => {
        setIsBoldActive(editor.isActive('bold'))
        setIsItalicActive(editor.isActive('italic'))
        setIsUnderlineActive(editor.isActive('underline'))
        setIsStrikeActive(editor.isActive('strike'))
        setIsCodeActive(editor.isActive('code'))
      }

      editor.on('update', updateActiveStates)
      editor.on('selectionUpdate', updateActiveStates)

      // Limpieza de eventos cuando el componente se desmonta
      return () => {
        editor.off('update', updateActiveStates)
        editor.off('selectionUpdate', updateActiveStates)
      }
    }
  }, [editor])

  const toolkitButtons = [
    { label: <b className='text-[var(--color-text)]'>N</b>, command: () => editor!.chain().focus().toggleBold().run(), isActive: isBoldActive },
    { label: <i className='font-mono'>I</i>, command: () => editor!.chain().focus().toggleItalic().run(), isActive: isItalicActive },
    { label: <u>S</u>, command: () => editor!.chain().focus().toggleUnderline().run(), isActive: isUnderlineActive },
    { label: <s>T</s>, command: () => editor!.chain().focus().toggleStrike().run(), isActive: isStrikeActive },
    { label: <b className='text-[var(--color-text)]'>{'<>'}</b>, command: () => editor!.chain().focus().toggleCode().run(), isActive: isCodeActive },
  ]

  return (
    <div className='flex flex-row gap-3'>
      {toolkitButtons.map(({ label, command, isActive }, index) => (
        <button 
          key={index}
          className={`w-9 h-9 text-[var(--color-text)] rounded-lg p-0 focus:text-white ${isActive ? 'bg-[var(--color-medium)]' : 'bg-gray-200'}`}
          onClick={command}
        >
          {label}
        </button>
      ))}
      {cardType === 'ocultar' && (
        <button className='h-9 bg-gray-200 text-[var(--color-text)] m-0 p-1' onClick={() => editor!.chain().focus().toggleOcultar().run() }>
          Ocultar
        </button>
      )}
    </div>
  )
}
