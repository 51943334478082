import { useState, useEffect } from 'react'
import { FolderMenu } from '../components/FolderMenu'
import { MazosGallery } from '../components/MazosGallery'
import { FlashcardsList } from '../components/FlashcardsList'
import { CardEditor } from '../components/pages/editor/CardEditor'
import Study from '../components/pages/study/Study'
import Icon from '@/components/icons/Icon'
import DashboardLayout from '@/components/layout/DashboardLayout'
import { Folder, Mazo } from '@/types/types'
import { Card } from '@/types/cards'
import { useUpdateFolders } from '@/context/UpdateFoldersContext'
import { EditorsProvider } from '@/context/EditorsContext'
import '@/styles/Mazos.css'	

export default function Biblioteca() {

	const [loadingInicFolder, setLoadingInicFolder] = useState(true)
	const [isMenu, setIsMenu] = useState(true)
	const [currentFolder, setCurrentFolder] = useState<Folder | null>(null)
	const [currentMazo, setCurrentMazo] = useState<Mazo | null>(null)
	//const [testMazo, setTestMazo] = useState<Mazo | null>(null)
	const [view, setView] = useState<React.ReactNode>(null)
	const [inExam, setInExam] = useState(false)
	const [cartaEditar, setCartaEditar] = useState<Card | undefined>(undefined)
	const [entrarEditar, setEntrarEditar] = useState(false)
	const [saleDeExamen, setSaleDeExamen] = useState(false)
	const { updateFolders, setUpdateFolders } = useUpdateFolders();
	const [cards, setCards] = useState<Card[] | null>(null) 
	const [modoEstudiar, setModoEstudiar] = useState(0)

	const views = {
		MAZOSGALLERY: (
			<MazosGallery
				folder={currentFolder}
				onClickMazo={(mazo) => setCurrentMazo(mazo)}
				startExam={async (mazo: Mazo, cards: Card[], modoEstudiar: number) => {setModoEstudiar(modoEstudiar);setCards(cards);setCurrentMazo(mazo)}}
				// Mazos Gallery usa su propio updateFolders en vez del contexto
				updateFolder={(isCurrent: boolean) => {setUpdateFolders(true); if(!isCurrent) {setCurrentFolder(null)}}}
			/>
		),
		FLASHCARDSLIST: (
			<FlashcardsList 
				folder={currentFolder}
				mazo={currentMazo}
				onClickAddFlashcard={() => startAdd()} 
				onClickEditFlashcard={(flashcardEdit: Card) => {setCartaEditar(flashcardEdit)}} 
				onClickReturn={() => setCurrentMazo(null)}
				startExam={(cards: Card[], modoEstudiar: number) => {setModoEstudiar(modoEstudiar);setCards(cards)}}
			/>
		),
		ADDFLASHCARD: (
			<EditorsProvider>
				<CardEditor 
					mazo={currentMazo}
					onClickReturn={() => finEdit()}
					folder={currentFolder}
					cartaEditar={cartaEditar}
				/>
			</EditorsProvider>
		),
		EXAM: (
			<Study 
			  cartas={cards}
			  modo={modoEstudiar}
				time={null}
				onClickReturn={() => {
					setSaleDeExamen(false)
					setIsMenu(true)
					setCurrentMazo(null)
					setView(views.MAZOSGALLERY)
					setInExam(false)
				}}
			/>
		),
	}


	useEffect(() => {
		/*
		const lastFolderItem = localStorage.getItem('lastFolder');
		if (lastFolderItem) {
				setCurrentFolder(JSON.parse(lastFolderItem))
		}*/
		setLoadingInicFolder(false)
	}, [])

	useEffect(() => {
		if (currentFolder) {
			setView(views.MAZOSGALLERY)
			//localStorage.setItem('lastFolder', JSON.stringify({ id: currentFolder.id, name: currentFolder.name }));
		}
	}, [currentFolder])

	useEffect(() => {
		if(cartaEditar!=null){
			setView(views.ADDFLASHCARD)
		} else if (entrarEditar && cartaEditar==null){
			setEntrarEditar(false)
			setView(views.ADDFLASHCARD)
		}
	}, [cartaEditar,entrarEditar])

	useEffect(() => {
		if(!saleDeExamen){
			setCartaEditar(undefined)
			if (currentMazo) {
				setView(views.FLASHCARDSLIST)
			} else {
				setView(views.MAZOSGALLERY)
			}
		} else setSaleDeExamen(false)
	}, [currentMazo, updateFolders])

	useEffect(() => {
		if (cards !== null) {
			setSaleDeExamen(true)
			setView(views.EXAM);
			setIsMenu(false);
			setInExam(true);
		}
	}, [cards])

	const finEdit = async () => {
		setCartaEditar(undefined)
		setView(views.FLASHCARDSLIST)
	}

	const startAdd = async () => {
		setCartaEditar(undefined)
		setEntrarEditar(true)
	}

	if (!loadingInicFolder) return (
		<DashboardLayout 
			isMenu={isMenu} 
			menu={
				<FolderMenu 
					inicFolder={currentFolder} 
					getCurrentFolder={setCurrentFolder} 
					hide={() => setIsMenu(false)} 
				/>
			}
		>
			<>
				{	!inExam && !isMenu && 
					<Icon iconName={window.innerWidth > 800 ? 'caretRight' : 'caretDown'} onClick={() => setIsMenu(true)} className='caretRight' />
				}
				{view}
				{currentFolder? null : 
				<div className='flex h-full justify-center text-center items-center font-semibold text-gray-400 text-2xl mt-10 md:mt-0 p-3'>
					Crea o selecciona una asignatura
                </div>}
			</>
		</DashboardLayout>	
	)
}
