import { Task } from '@/types/types'
import { useEffect, useState } from 'react'
import Icon from './icons/Icon'


interface TaskCardProps {
    task: Task
    updateTask: () => void

}

export default function TaskCard ({task, updateTask}:TaskCardProps) {

    const[isDone, setIsDone] = useState<boolean>(task.completed)

    useEffect(() => {
        setIsDone(task.completed)
    }, [task.completed])


    const editarTarea = async (valor: boolean) => {
        const estado = valor
        try{
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/task/update_task?id=${task.id}&completed=${estado}`,
                {
                    method: 'PATCH',    
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                })
            if (!response.ok) {
                throw new Error('Error al actualizar la tarea')
            }
            else {
                updateTask()
            }
        }
        catch (error) {
            console.error('Error:', error)
        }
    }

    const eliminarTarea = async () => {
        try{
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/task/delete?id=${task!.id}`, 
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
              }
            )
            if (!response.ok) {
                throw new Error('Error al eliminar la tarea')
            }
            else {
                updateTask()
            }
        }
        catch (error) {
            console.error('Error:', error)
        }
    }

    
    return (
        <div className="w-full lg:w-[38vw] h-auto rounded-[var(--border-radius)] bg-[var(--color-background)] flex font-medium items-start overflow-hidden m-[10px]">


            {!isDone?(<Icon iconName='circleBlank' size='20' onClick={()=>editarTarea(true)} />):(<div style={{color:'var(--color-secondary'}}>
                <Icon iconName='checkCircle' size='20' onClick={()=>editarTarea(false)} /></div>)}
            
            <div style={{
                color: 'var(--color-text-light)',
                cursor: 'pointer',
                marginLeft: '3px',}}
            onMouseEnter={(e) => e.currentTarget.style.color = 'var(--color-delete)'}
            onMouseLeave={(e) => e.currentTarget.style.color = 'var(--color-text-light)'}
            >
                <Icon iconName='squareXMark' size='20' onClick={() => eliminarTarea()} />
            </div>         

            <div style={{
                textDecoration: isDone ? 'line-through' : 'none',
                textDecorationThickness: '1.2px',
                marginLeft: '10px',
                marginTop: '-3px',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {task.content}
            </div>
        </div>
    )
}
