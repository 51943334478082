import { useState } from 'react'
import { MethodCard } from '../components/MethodCard'
import { ContactForm } from '../components/ContactForm'
import SocialIcon from '@/components/icons/SocialIcon'
import { animateScroll as scroll } from 'react-scroll'
import { useEffect } from 'react'
import landingImage from '../assets/landingImage.svg'
import methodImage1 from '../assets/methodImage1.svg'
import methodImage2 from '../assets/methodImage2.svg'
import methodImage3 from '../assets/methodImage3.svg'
import methodImage4 from '../assets/methodImage4.png'
import pantallaMazos from '../assets/pantallaMazos.jpg'
import pantallaPregunta from '../assets/pantallaPregunta.jpg'
import pantallaRespuesta from '../assets/pantallaRespuesta.jpg'
import HeaderLanding from '../components/common/header/HeaderLanding'
import Image from '@/components/common/ui/Image'
import '../styles/Landing.css'
import { useNavigate } from 'react-router-dom'

export type ScrollTarget = 'inicio' | 'metodo' | 'interfaz' | 'contacto';

export default function Landing() {

	const navigate = useNavigate()

	const [scrollTarget, setScrollTarget] = useState<ScrollTarget>('inicio')

	const vh = window.innerHeight
	const sectionPx: Record<ScrollTarget, number> = {
		'inicio': 0,
		'metodo': vh,
		'interfaz': 2 * vh,
		'contacto': 3 * vh,
	}

	useEffect(() => {
		if (scrollTarget) {
			scroll.scrollTo(sectionPx[scrollTarget], {
				duration: 100,
			})
		}
	}, [scrollTarget])

	return (
		<>
			<HeaderLanding getScrollTarget={(target: ScrollTarget) => setScrollTarget(target)} />
			<div className='Landing'>
				<section className='inicio'>
					<div className='info'>
						<div className='maintext'>
							<h1>
								Aprende <b>más</b> rápido, estudia de forma <b>más</b> eficiente
							</h1>
						</div>
						<h3>Memoriza tu temario de forma rápida, fácil e inteligente y prepárate para el éxito educativo con nuestro método científicamente probado.</h3>
						<button onClick={() => navigate('/register')}>¡REGÍSTRATE YA!</button>
					</div>
					<Image className='landing-img' height='500px' src={landingImage} alt='El concepto de usar Bilern' />
				</section>
				<section className='metodo'>
					<MethodCard name='Método' image={methodImage1}>
						En nuestro algoritmo hemos adaptado el método de repetición espaciada probado científicamente por Piotr Wozniak en 1994 para hackear la curva de olvido.
					</MethodCard>
					<MethodCard name='Planificación' image={methodImage2}>
						Practica según tu calendario y llegarás a tus examenes con todos los conceptos aprendidos, la memoria no volverá a ser ningún problema.
					</MethodCard>
					<MethodCard name='Inteligencia Artificial' image={methodImage3}>
						No pierdas el tiempo añadiendo tarjetas de forma manual. Con un simple análisis podrás añadir nuevas flashcards a partir de tus apuntes utilizando IA adaptada a ti.
					</MethodCard>
					<MethodCard name='Research' image={methodImage4}>
						Con vuestro feedback pretendemos conocer más sobre la memoria humana, mejorar nuestro método y revolucionar la memorización.
					</MethodCard>
				</section>
				<section className='interfaz'>
					<div className='app-screenshots'>
						<img className='screenshot1' src={pantallaMazos} />
						<img className='screenshot2' src={pantallaPregunta} />
						<img className='screenshot3' src={pantallaRespuesta} />
					</div>
					<div className='info-interfaz'>
						<div className='maintext'>
							<h1>
								Solo pre<b>ocúpate</b> de aprender
							</h1>
						</div>
						<h2>Nuestra interfaz ofrece un diseño sencillo, accesible a todo el mundo, y dispuesto a hacer que el estudio sea más fácil.</h2>
					</div>
				</section>
				<section className='contacto'>
					<div className='info'>
						<div className='maintext'>
							<h1>
								Nos encanta tu <b>Feed</b>back
							</h1>
						</div>
						<h2>Si crees que podemos mejorar algo...</h2>
						<h2>
							<b>Somos todo oídos</b>
						</h2>
						<h2>Si quieres colaborar con nosotros...</h2>
						<h2>
							<b>Seamos equipo</b>
						</h2>
						<h2>Si quieres saber más de nosotros...</h2>
						<h2>
							<b>No dudes en conocernos</b>
						</h2>
					</div>
					<ContactForm />
				</section>
			</div>
			<footer>
				<h4>Bilern 2024</h4>
				<div className='footer-icons'>
					<SocialIcon social='instagram' size={'25'} color='#fff' />
					<SocialIcon social='facebook' size={'25'} color='#fff' />
					<SocialIcon social='linkedin' size={'25'} color='#fff' />
				</div>
			</footer>
		</>
	)
}