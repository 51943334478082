import { useState } from 'react'
import Icon from '@/components/icons/Icon'
import { Card, OcultarCard } from '../types/cards'
import '../styles/Mazos.css'
import  Latex  from 'react-latex-next'

interface FlashcardCardProps {
  index: number
  flashcard: Card
  onDeleteFlashcard: (flashcard: Card) => void
  onEditFlashcard: (flashcard: Card) => void
}

// TODO ESTO DE FLASHCARD HAY QUE CAMBIARLE EL NOMBRE QUE AHORA NO SON TODAS FLASHCARDS, O CREAR UN NUEVO NOMBRE PARA EL tIPO FLASHCARD
export const FlashcardCard = ({ index, flashcard, onDeleteFlashcard, onEditFlashcard }: FlashcardCardProps) => {

  const [isOptions, setIsOptions] = useState(false)
  const [isOnDelete, setIsOnDelete] = useState(false)

  const handleDeleteCard = async (event: React.MouseEvent) => {
    event.stopPropagation()
    try {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/card/delete?cardId=${flashcard.id}&cardType=${flashcard.type}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', },
			  }
      )
			if (!response.ok){
				throw new Error('Error al eliminar asignatura');
			}			
			setIsOptions(false)
      setIsOnDelete(false)
      await new Promise(resolve => setTimeout(resolve, 10))
      onDeleteFlashcard(flashcard)

		} catch (error) {
			console.error('Error:', error);
			return
		}
  }

  const handleOpenOptions = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOptions(true)
    setIsOnDelete(false)
  }

  const handleCloseOptions = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOptions(false)
    setIsOnDelete(false)
  }

	return (
    <>
      {isOptions ?
        (isOnDelete ?
          <div key={index} className='mazo_container_options'>
            <div className='mazo_container_left_options'>
              <div className='mazo_container_eliminar_options'>
                <h4 className="h4Hover" >¿Estás seguro de que deseas eliminar esta flashcard?</h4>
                <Icon iconName='xmark' size='30' onClick={handleCloseOptions} className='xmark-icon' />
              </div>
              <div className='mazo_container_si_no'>
                <button className='mal' onClick={()=>setIsOnDelete(false)}>
                  No
                </button>
                <button className='bien' onClick={handleDeleteCard}>
                  Si
                </button>
              </div>
            </div>
          </div>
        :
          <div key={index} className='fcprev_container_options'>
            <div  className='mazo_container_right_options'>
              <div className='mazo_option_borrar_carta' onClick={()=>setIsOnDelete(true)}>
                <Icon iconName='trash' />
                <label style={{ marginLeft: '10px' }}>Eliminar carta</label>
              </div>
              <div className='mazo_option_renombrar_carta' onClick={()=>onEditFlashcard(flashcard)}>
                <Icon iconName='edit' />
                <label style={{ marginLeft: '10px' }}>Editar</label>
              </div>
            </div>
            <div className='mazo_container_right'>
              <Icon iconName='xmark' size='30' onClick={handleCloseOptions} className='xmark-icon' />
            </div>
          </div>
        )
      :
        <div key={index} className='fcprev_container'>
          <Icon iconName='options' size='25' onClick={handleOpenOptions} />
          <div className='fcprev_text'>
            <Latex>
              { flashcard.type === 'ocultar' ? 
                (flashcard as OcultarCard).text :
                (flashcard as Exclude<Card, OcultarCard>).pregunta
              }
            </Latex>
          </div>
        </div>
      }
    </>
	)
}