import { useEffect, useState } from 'react'
import { Exam, Folder, Mazo } from '@/types/types'
import React from 'react'
import '../styles/TemarioExamen.css'
import Icon from './icons/Icon'

interface TemarioExamenProps {
    mazos: Mazo[][]
    folders: Folder[]
    nombresObtenidos: boolean
    siguientePaso: (paso: number) => void
    exit: () => void
    sendSelected: (selected: Mazo[]) => void
    examUpdate: Exam | null
}

export default function TemarioExamen({ mazos, folders, nombresObtenidos, siguientePaso, exit, sendSelected, examUpdate }: TemarioExamenProps) {

    const [botonCrearDesactivado, setBotonCrearDesactivado] = useState<boolean>(true)
    const [expandedFolders, setExpandedFolders] = useState<{ [key: number]: boolean }>({})
    const [selectedMazoCheckboxes, setSelectedMazoCheckboxes] = useState<{ [key: string]: boolean }>({})
    const [selectedFolderCheckboxes, setSelectedFolderCheckboxes] = useState<{ [key: string]: boolean }>({})
    const [mazosSeleccionados, setMazosSeleccionados] = useState<Mazo[]>([])

    const toggleFolder = (index: number) => {
        setExpandedFolders(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }))
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, folderIndex?: number) => {
        const { value, checked } = event.target;

        if (folderIndex !== undefined) {
            // Folder logic
            setSelectedFolderCheckboxes(prevState => {
                const newState = { ...prevState, [value]: checked };
                mazos[folderIndex].forEach(mazo => {
                    setSelectedMazoCheckboxes(prevMazoState => ({ ...prevMazoState, [mazo.id]: checked }));
                });
                return newState;
            });
        } else {
            // Mazo logic
            setSelectedMazoCheckboxes(prevState => ({
                ...prevState,
                [value]: checked
            }));
        }
    }

    useEffect(() => {
        if (examUpdate) {
            const selectedMazoCheckboxes: { [key: string]: boolean } = {};
            const selectedFolderCheckboxes: { [key: string]: boolean } = {};

            examUpdate.id_mazos.forEach(id => {
                selectedMazoCheckboxes[id] = true;
            });

            mazos.forEach((mazoArray, index) => {
                if (mazoArray.every(mazo => selectedMazoCheckboxes[mazo.id])) {
                    selectedFolderCheckboxes[folders[index].id] = true;
                }
            });

            setSelectedMazoCheckboxes(selectedMazoCheckboxes);
            setSelectedFolderCheckboxes(selectedFolderCheckboxes);
            const selectedMazos = mazos.flat().filter(mazo => examUpdate.id_mazos.includes(mazo.id));
            setMazosSeleccionados(selectedMazos);
        }
    }, [examUpdate, mazos, folders]);

    // UseEffect para actualizar mazosSeleccionados cuando cambie selectedMazoCheckboxes
    useEffect(() => {
        const selectedMazos = Object.keys(selectedMazoCheckboxes)
            .filter(key => selectedMazoCheckboxes[key])
            .map(id => {
                for (const mazoArray of mazos) {
                    const mazo = mazoArray.find(m => m.id === id)
                    if (mazo) return mazo
                }
                return null
            })
            .filter((mazo): mazo is Mazo => mazo !== null);

        setMazosSeleccionados(selectedMazos);
    }, [selectedMazoCheckboxes, mazos]);

    useEffect(() => {
        const isAnyCheckboxChecked = Object.values(selectedMazoCheckboxes).some(checked => checked) 
        setBotonCrearDesactivado(!isAnyCheckboxChecked);
    }, [selectedMazoCheckboxes, selectedFolderCheckboxes])

    return (
        <>
            <h3 className='flex justify-center mb-5 mr-4 px-3'>
                Selecciona el contenido del examen
            </h3>
            <ul className="scrollable-list h-72 mb:w-72">
                {nombresObtenidos && mazos.map((mazoArray, i) => (
                    <React.Fragment key={`fragment-${i}`}>
                        <li key={`folder-${i}`} className="flex items-center justify-start mt-[10px]">
                            <button onClick={() => toggleFolder(i)} className="border-none cursor-pointer p-0 text-black bg-transparent hover:bg-transparent mr-[10px]">                                
                                {expandedFolders[i] ? <Icon iconName='angleDown'/> : <Icon iconName='angleRight'/>}
                            </button>
                            <div className="flex items-center font-bold w-[93%]">
                                <label className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[85%]">{folders[i].name}</label>
                                <input type="checkbox" className="custom-checkbox ml-[10px] w-[20px] h-[20px]" value={folders[i].id} onChange={(e) => handleCheckboxChange(e, i)} checked={selectedFolderCheckboxes[folders[i].id] || false}/>
                            </div>
                        </li>
                        {expandedFolders[i] && mazoArray.map((mazo, j) => (
                            <li key={`mazo-${i}-${j}`} className="flex items-center justify-start pl-[20px] mt-[5px] font-bold">
                                <input type="checkbox" className="custom-checkbox mr-[10px] w-[20px] h-[20px]" value={mazo.id} onChange={handleCheckboxChange} checked={selectedMazoCheckboxes[mazo.id] || false}/>
                                <label className="whitespace-nowrap overflow-hidden text-ellipsis">{mazo.name}</label>
                            </li>
                        ))}
                    </React.Fragment>
                ))}
            </ul>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', marginTop: '30px', marginBottom: '10px' }}>
                            <button
                                onClick={exit}
                                style={{
                                    backgroundColor: 'var(--color-delete)',
                                    color: 'white',
                                    padding: '10px 20px',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-error)'}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-delete)'}
                            >
                                Cancelar
                            </button>
                            <button
                                style={{
                                    backgroundColor: botonCrearDesactivado ? 'var(--color-text-light)' : 'var(--color-secondary)',
                                    color: 'white',
                                    padding: '10px 20px',
                                    border: 'none',
                                    cursor: botonCrearDesactivado ? 'default' : 'pointer'
                                }}
                                onMouseEnter={(e) => {
                                    if (!botonCrearDesactivado) {
                                        e.currentTarget.style.backgroundColor = 'var(--color-medium)';
                                    }
                                } }
                                onMouseLeave={(e) => {
                                    if (!botonCrearDesactivado) {
                                        e.currentTarget.style.backgroundColor = 'var(--color-secondary)';
                                    }
                                } }
                                disabled={botonCrearDesactivado}
                                onClick={() => {sendSelected(mazosSeleccionados);siguientePaso(2)}}
                            >
                                Continuar
                            </button>
                        </div>
        </>
    );
}
