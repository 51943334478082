import { useState, useRef, useEffect } from 'react'
//import '@/styles/Importar.css'
import { encoding_for_model } from 'tiktoken'
import { ImportFile, getImportFileFromRawFile } from './importFile'
import { Subscription, Plan } from '@/types/types'
import { CardType } from '@/types/cards'
import FileDragAndDrop from './FileDragAndDrop'
import ExchangeInfo from './ExchangeInfo'
import Icon from '@/components/icons/Icon'
import Popup from '@/components/common/ui/Popup'

interface ImportPopupProps {
  open: boolean
  onClose: (refrescar: boolean) => void
  mazoId: string
}

type ImportStep = 'loading' | 'waiting' | 'processing' | 'done' | 'error'
//type CardAmount = 'many' | 'few'
type ExcludedCardType = Exclude<CardType, 'ocultar'> // Tipo temporal hasta que se programe la importacion de cartas de tipo 'ocultar'

// Todo pasar el mazo al que hacer la importacion como prop
export default function ImportPopup ({ open, onClose,  mazoId }: ImportPopupProps) {

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<ImportFile | null>(null)
  const [step, setStep] = useState<ImportStep>('loading')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

  // De momento no se dara la opción de pedir la cantidad de tarjetas a importar:
  //const [cardAmount, setCardAmount] = useState<CardAmount>('few')
  const [startPage, setStartPage] = useState<number>(0)
  const [endPage, setEndPage] = useState<number>(0)
  const [cardType, setCardType] = useState<ExcludedCardType>('flashcard')
  const [costInTokens, setCostInTokens] = useState<number>(0)

  const [currentSubscription, setCurrentSubscription] = useState<Subscription | undefined>(undefined)
  const [currentTokens, setCurrentTokens] = useState<number | undefined>(undefined)
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>(undefined)
  const [exitRefresh, setExitRefresh] = useState<boolean>(false)

  const fetchCurrentSubscription = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/subscription`, 
      { 
        method: 'GET',
        headers: { 'Content-Type': 'application/json', },
        credentials: 'include' 
      }
    )
    if (!response.ok) {
      throw new Error('Error al obtener la subscripción del usuario')
    }
    const sub = await response.json()
    if (sub) setCurrentSubscription(sub)
  }

  const fetchCurrentTokens = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/data`, 
      { 
        method: 'GET',
        headers: { 'Content-Type': 'application/json', },
        credentials: 'include' 
      }
    )
    if (!response.ok) {
      throw new Error('Error al obtener el número de tokens')
    }
    setCurrentTokens((await response.json()).tokens)
  }

  const fetchCurrentPlan = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/subscription_plan`, 
      { 
        method: 'GET',
        headers: { 'Content-Type': 'application/json', },
        credentials: 'include' 
      }
    )
    if (!response.ok) {
      throw new Error('Error al obtener el plan del usuario')
    }
    setCurrentPlan(await response.json())
  }
  
  useEffect(() => { const fetchData = async () => {
    try {
      await Promise.all([
        fetchCurrentSubscription(),
        fetchCurrentTokens(),
        fetchCurrentPlan()
      ])
      setStep('waiting')
    } catch (error) {
      setStep('error')
      console.error('Error:', error)
    }
  }; fetchData() }, [])

  const calculateTokens = async (start: number, end: number) => {
    if (!file) return
    const enc = encoding_for_model('gpt-3.5-turbo') // pasar a .env
    let totalTokens = 0
    for (let i = start - 1; i < end; i++) {
      const tokens = enc.encode(file.content[i])
      totalTokens += tokens.length
    }
    enc.free()
    setCostInTokens(totalTokens)
  }

  useEffect(() => {
    if (!file) { 
      setCostInTokens(0)
      if (hiddenFileInput.current) hiddenFileInput.current.value = ''
    } else {
      setStartPage(1)
      setEndPage(file.content.length)
      calculateTokens(1, file.content.length)
    }
  }, [file])


  const sendImportRequest = async () => {
    if (!file) return
    setStep('processing')
    const sendContent = file.content.slice(startPage - 1, endPage).join('')
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/import/ai`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', },
          body: JSON.stringify({
            mazoId: mazoId,
            content: sendContent, 
            cardType: cardType
          }),
          credentials: 'include'
        }
      )
      if (!response.ok) {
        setStep('error')
        throw new Error('Error al importar tarjetas')
      }
      const responseSort = await fetch(
        `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({}),
            credentials: 'include'
      }
      )
      if (!responseSort.ok){
          throw new Error('Error al reordenar tarjetas');
      }		
      setExitRefresh(true)
      setStep('done')
    } catch (error) {
      setStep('error')
      console.error('Error:', error)
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) {
      const rawFile = files[0]
      const importFile = await getImportFileFromRawFile(rawFile)
      setFile(importFile)
    }
  }

  const handleStartPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!file)
      setStartPage(0)
    else {
      let newValue = Math.min(Math.max(1, parseInt(e.target.value)), file!.content.length)
      setStartPage(newValue)
      calculateTokens(newValue, endPage)
    }
  }

  const handleEndPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!file)
        setEndPage(0)
      else {
        let newValue = Math.min(Math.max(1,parseInt(e.target.value)), file!.content.length)
        setEndPage(newValue)
        calculateTokens(startPage, newValue)
      }
  }

  useEffect(() => {
    if (!file || step === 'loading' || step === 'processing' || startPage > endPage || startPage == 0 || endPage == 0)
      setIsButtonDisabled(true)
    else if (currentSubscription === undefined && (!currentTokens || currentTokens && currentTokens < costInTokens))
      setIsButtonDisabled(true)
    else if (currentSubscription !== undefined && (!currentTokens && currentSubscription.tokens < costInTokens || currentTokens && currentSubscription.tokens+currentTokens < costInTokens))
      setIsButtonDisabled(true)
    else
      setIsButtonDisabled(false)
  }, [startPage, endPage, file, step])

  if (step !== 'done') return (
    <Popup 
      className='mt-5 max-h-[500px] md:max-h-full min-w-80 md:min-w-[600px] max-w-2xl'
      width='50vw' 
      height='78vh' 
      open={open} 
      onClose={()=>onClose(exitRefresh)}
      closeOnBlur={false}
    > 
      <div style={{ width: '100%', height: '100%', overflowY: 'auto'}}>
      <div className='flex flex-col md:flex-row md:scrollable-list' style={{ width: '95%', height: '100%' }}>
          <div className='w-full md:w-2/5 m-3 md:pl-5 flex flex-col justify-between items-start'>
            <div className='w-full'>
              <h3 >Generación automática</h3>
              <input
                type='file'
                onChange={handleFileChange}
                ref={hiddenFileInput}
                style={{ position: 'absolute', visibility: 'hidden'}}
                accept='application/pdf'
              />
              <h3 className='text-[var(--color-secondary)] w-full pt-1'
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}>{
                file ? file.name :
                <>&nbsp;</>
              }</h3>
            </div>
            <div className='w-full h-full flex flex-col gap-4 m-3 ml-0 pt-1'>
              <div className='w-3/4'>
                <label>
                  Tipo de tarjetas a importar:
                </label>
                <select 
                  className='h-10'
                  onChange={e => setCardType(e.target.value as ExcludedCardType)}
                >
                  <option value={'flashcard'}>Flashcards</option>
                  {/*<option value={'ocultar'}>Ocultar Palabra</option>*/}
                  <option value={'test'}>Tipo Test (Beta)</option>
                </select>
              </div>
              <div className='flex justify-start gap-1'>
                <label >Desde la página: </label>
                <input
                  className='w-12'
                  id='paginaInicio'
                  type='number'
                  value={startPage}
                  onChange={handleStartPageChange}
                />
              </div>
              <div className='flex justify-start gap-1'>
                <label>Hasta la página:</label>
                <input
                  className='w-12'
                  type='number'
                  value={endPage}
                  onChange={handleEndPageChange}
                />
              </div>
              {file && <ExchangeInfo 
                subscription={currentSubscription}
                plan={currentPlan}
                tokens={currentTokens}
                costInTokens={costInTokens}
              />}
              <div>
                { step === 'error' &&
                  <h4 className='text-[var(--color-error)]'>
                    Ha ocurrido un error inesperado, vuelva a intentarlo y en caso de persistir contacte con soporte
                  </h4>
                }
              </div>
            </div>                  
            <div className='w-full flex flex-row gap-3 justify-start items-center'>
              <button 
                onClick={sendImportRequest}
                disabled={isButtonDisabled}
                className={isButtonDisabled ? 'bg-[var(--color-text-light)] cursor-auto hover:bg-[var(--color-text-light)] hover:border-[var(--color-text-light)]': '' }
              >
                Crear Tarjetas
              </button>
              { (currentTokens !== undefined && (!currentSubscription && currentTokens < costInTokens || currentSubscription && currentSubscription.tokens+currentTokens < costInTokens)) && 
                <label className='text-[var(--color-error)]'>Tokens insuficientes</label>
              }
            </div>
          </div>
          <div className='w-full md:w-1/2 h-90 mt-5 mb-3 mr-10 ml-0'>
            <FileDragAndDrop 
              fileUrl={file?.url ?? null}
              setFile={setFile}
              openFileInput={() => hiddenFileInput.current?.click()}
              isLoading={step === 'processing'}
            />
          </div>
        </div>
      </div>    
    </Popup>
  )
  else { // step === 'done'
    setTimeout(() => {
      setFile(null)
      setStep('loading')
      onClose(exitRefresh)
    }, 1000)
    return (
      <Popup 
        className='mt-5 max-h-[500px] md:max-h-full min-w-80 md:min-w-[600px] max-w-2xl'
        width='50vw' 
        height='75vh' 
        open={open} 
        closeIcon={false}
        closeOnBlur={false}
      >
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <h2 className='font-medium mb-5 text-[var(--color-secondary)]'>Operación realizada con éxito</h2>
          <Icon 
            className='text-[var(--color-secondary)] rounded-full border-solid border-[var(--color-secondary)] p-1'
            iconName='check' 
            size='60'
            isClickable={false}
          />
        </div>
      </Popup>
    )
  }
}
