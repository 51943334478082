import { useEffect, useState } from 'react'
import Popup from './common/ui/Popup';
import { Exam, Folder, Mazo } from '@/types/types'
import TemarioExamen from './TemarioExamen';
import TemarioExamenOrden from './TemarioExamenOrden';
import { parse } from 'postcss';

interface CreateExamProps {
    show: boolean
    examenes: Exam[]
    close: () => void
    exitAndRefresh: () => void
    examUpdate: Exam | null
}

const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export default function CreateExam({show, examenes, close, exitAndRefresh, examUpdate}: CreateExamProps){

    const [fechaExamen, setFechaExamen] = useState<string>(examUpdate ? formatDate(new Date(examUpdate.fecha_fin)) : '');
    const [nombreExamen, setNombreExamen] = useState<string | null>(examUpdate?examUpdate.name:null)
    const [folders, setFolders] = useState<Folder[]>([])
    const [mazos, setMazos] = useState<Mazo[][]>([])
    const [botonCrearDesactivado, setBotonCrearDesactivado] = useState<boolean>(true)
    const [nombresObtenidos, setNombresObtenidos] = useState<boolean>(false)
    const [mazosSeleccionados, setMazosSeleccionados] = useState<Mazo[]>([])
    const [paso, setPaso] = useState<number>(0) // 0: Nombre y fecha, 1: Temario, 2: Orden

    useEffect(() => {

        const obtenerMazos = async () => {
            try{
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/folder/get_from_user`,
                    { 
                        method: 'GET',
                        credentials: 'include'
                    }
                )
                if(!response.ok) {
                    throw new Error('Error en la obtencion de las carpetas')
                }
                const data = await response.json()
                let folderAgregar: Folder[] = []
                for(let i=0;i<data.length;i++) {
                    folderAgregar.push(data[i])
                }
                setFolders(folderAgregar)
                let mazosAgregar: Mazo[][] = []
                for(let i=0;i<folderAgregar.length;i++) {
                    mazosAgregar[i] = []
                    const response = await fetch(
                        `${import.meta.env.VITE_API_URL}/mazo/get_from_folder?folderId=${folderAgregar[i].id}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    if (!response.ok) {
                        throw new Error('Error en la actualizacion de la asignatura');
                    }
                    let data = await response.json();
                    for (let j = 0; j < data.length; j++) {
                        mazosAgregar[i].push(data[j]);
                    }
                }
                setMazos(mazosAgregar);
            } catch(error) {
                console.log(error)
            }
        }
        obtenerMazos()
    }, []);

    const parseDate = (dateString: string): Date | null => {
        const [day, month, year] = dateString.split('/').map(Number);
    
        // Validar que día, mes y año sean números y que tengan valores lógicos
        if (!isNaN(day) && !isNaN(month) && !isNaN(year) &&
            month >= 1 && month <= 12 &&
            day >= 1 && day <= 31 && // Validar el rango del día
            year >= 2023) {
    
            // Crear una cadena de fecha manualmente para evitar problemas de mes
            const dateStringFormatted = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T12:00:00`; // yyyy-mm-dd formato
            
            // Crear un objeto Date a partir de la cadena bien formateada
            const date = new Date(dateStringFormatted);
    
            // Verificar que la fecha creada sea válida
            if (date.getFullYear() === year && (date.getMonth() + 1) === month && date.getDate() === day) {
                return date;
            }
        }
    
        // Retornar null si no es una fecha válida
        return null;
    };

    useEffect(() => {
        if (nombreExamen && fechaExamen) {

            let nombreExamenExiste = examenes.some(examen => examen.name == nombreExamen);
            if(nombreExamen==examUpdate?.name){
                nombreExamenExiste=false
            }
            const parsedDate = parseDate(fechaExamen);
            const fechaEsValida = parsedDate !== null && parsedDate > new Date();
            if (nombreExamen.length > 0 && !nombreExamenExiste && fechaEsValida) {
                setBotonCrearDesactivado(false);
            } else {
                setBotonCrearDesactivado(true);
            }
        } else {
            setBotonCrearDesactivado(true);
        }
    }, [nombreExamen, fechaExamen]);

    useEffect(() => {
        if(mazos && folders){
            setNombresObtenidos(true)
        }
    }, [mazos,folders])

    const handleClose = () => {
        setBotonCrearDesactivado(true)
        setNombreExamen(null)
        setFechaExamen('')
        close()
        setPaso(0)
    }

    const handleDateChange = (e: { target: { value: any; }; }) => {
        let input = e.target.value;
        
        // Verificar si el usuario está borrando
        const isDeleting = fechaExamen.length > input.length;
    
        // Eliminar cualquier carácter que no sea número o barra
        input = input.replace(/[^0-9/]/g, '');
    
        // Si el usuario está borrando y el cursor está en una posición de barra (después de la barra)
        if (isDeleting) {
            if (input.length === 2 || input.length === 5) {
                input = input.slice(0, -1); // Elimina la barra automáticamente al borrar
            }
        } else {
            // Insertar barras automáticamente mientras se escribe
            if (input.length === 2 || input.length === 5) {
                input += '/';
            }
        }
    
        // Limitar la longitud del input a 10 caracteres (dd/mm/aaaa)
        if (input.length <= 10) {
            setFechaExamen(input);
        }
    };

    return (
        <Popup open={show} onClose={handleClose} width='500px'>
            {paso == 0 ?
                <>
                    <h3 className='flex justify-center'>
                        {!examUpdate? ('Crear nuevo examen') : ('Editar examen')}
                    </h3>
                    <div style={{ width: '60%', marginLeft: '30px' }}>
                        <div className={'w-full flex flex-col items-start mt-6 text-text-color'}>
                            Nombre del examen
                        <input
                            className={'h-10 bg-white border-solid border-2 mt-1 border-border-color text-text-color focus:border-secondary-color'}
                            type={'text'}
                            value={nombreExamen || ''}
                            onChange={(e) => setNombreExamen(e.target.value)} />
                        <div className={'mt-3'}>Fecha del examen</div>
                        <input
                            className='h-10 bg-white border-solid border-2 mt-1 mb-2 border-border-color text-text-color focus:border-secondary-color'
                            type='text'
                            placeholder='dd/mm/aaaa'
                            value={fechaExamen || ''}
                            onChange={handleDateChange}
                            maxLength={10} // Limita la longitud máxima del input
                        />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', marginTop: '30px', marginBottom: '10px' }}>
                        <button
                            onClick={handleClose}
                            style={{
                                backgroundColor: 'var(--color-delete)',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                cursor: 'pointer'
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-error)'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-delete)'}
                        >
                            Cancelar
                        </button>
                        <button
                            style={{
                                backgroundColor: botonCrearDesactivado ? 'var(--color-text-light)' : 'var(--color-secondary)',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                cursor: botonCrearDesactivado ? 'default' : 'pointer'
                            }}
                            onMouseEnter={(e) => {
                                if (!botonCrearDesactivado) {
                                    e.currentTarget.style.backgroundColor = 'var(--color-medium)';
                                }
                            } }
                            onMouseLeave={(e) => {
                                if (!botonCrearDesactivado) {
                                    e.currentTarget.style.backgroundColor = 'var(--color-secondary)';
                                }
                            } }
                            disabled={botonCrearDesactivado}
                            onClick={() => setPaso(1)}
                        >
                            Continuar
                        </button>
                    </div>
                </> : paso == 1 ?
                    <TemarioExamen  
                        mazos={mazos}
                        folders={folders}
                        nombresObtenidos={nombresObtenidos}
                        siguientePaso={(paso: number) => setPaso(paso)}
                        exit={handleClose}
                        sendSelected={(mazosSeleccionados: Mazo[]) => setMazosSeleccionados(mazosSeleccionados)}
                        examUpdate={examUpdate}
                    />
                : paso == 2 && nombreExamen && fechaExamen ?
                    <TemarioExamenOrden
                        mazos={mazosSeleccionados}
                        exit={handleClose}
                        salirRefrescar={() =>exitAndRefresh()}
                        nombreExamen={nombreExamen}
                        fechaExamen={parseDate(fechaExamen) || new Date()}
                        examUpdate={examUpdate}
                    />
                : null}
        </Popup >
    )
}
