import { FlashCard } from '@/types/cards'
import  Latex  from 'react-latex-next'

interface FlashCardStudyProps {
  card: FlashCard 
  revealed: boolean
}
/**
 * Componente FlashCardStudy.
 * Se encarga de el estudio de una carta de tipo flashcard.
 * @param props de la interfaz FlashCardStudyProps
 * @returns componente con la interfaz de estudio de una carta de tipo flashcard
 */
export default function FlashCardStudy({ card, revealed }: FlashCardStudyProps) {

  return(
    <div className='flex flex-col items-center justify-center w-[73vw] max-w-[1000px] max-h-[60vh] mb-3'>
      { !revealed ?
        <h2><Latex>{card.pregunta}</Latex></h2>
      :
        <>
          <h3><Latex>{card.pregunta}</Latex></h3>
          <hr/>
          <h2><Latex>{card.respuesta}</Latex></h2>      
        </>
      }
    </div>
  )
}
