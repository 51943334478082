import { ImportFile, getImportFileFromRawFile } from './importFile'
import useWindowSize from '@/hooks/useWindowSize'
import Icon from '@/components/icons/Icon'
import Spinner from '@/components/common/ui/Spinner'

interface FileDragAndDropProps {
  fileUrl: string | null
  setFile: (file: ImportFile | null) => void
  openFileInput?: () => void
  isLoading?: boolean
}

export default function FileDragAndDrop({ fileUrl, setFile, openFileInput, isLoading }: FileDragAndDropProps) {

  const { width } = useWindowSize()

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const rawFile = event.dataTransfer.files[0]
    const importFile = await getImportFileFromRawFile(rawFile)
    setFile(importFile)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleRemoveFile = () => {
    setFile(null)
  }

  return (
    <>
      {isLoading && (
        <div className='absolute w-1/2 h-full -ml-2 -mt-5 flex items-center z-[100]'>
          <Spinner />
        </div>
      )}
      {fileUrl ? (
        <div className='w-full h-full overflow-hidden rounded-lg' style={isLoading ? {opacity: '70%'}: {}}>
          {/* width < 1682 && // En pantallas más grandes el icono tapa funcionalidades del iframe
            <Icon
              className='absolute m-2 text-white p-2 rounded-full'
              iconName='xmark'
              size='25'
              onClick={handleRemoveFile}
            />*/
          }
          <iframe
            src={fileUrl}
            className='w-full h-full border-none'
          ></iframe>
        </div>
      ) : (
        <div 
          className='h-full flex flex-col items-center justify-center border-dashed border-5 border-[var(--color-secondary)] rounded-3xl cursor-pointer text-[var(--color-secondary)]'
          onDrop={handleDrop} 
          onDragOver={handleDragOver}
          onClick={openFileInput && openFileInput}
        >
          <Icon iconName='upload' size='40' />
          <h3 className='w-2/3 text-center m-3'>Arrastra o selecciona tus apuntes en PDF</h3>
        </div>
      )}
    </>
  )
}
