import Icon from '@/components/icons/Icon'
import { useNavigate } from 'react-router-dom'

export default function LockedContentMessage() {

  const navigate = useNavigate()

  return (
    <div className='p-10'>
      <Icon iconName='lock' size='80' className='text-[var(--color-secondary)]' isClickable={false} />
      <h2>Contenido Premium</h2>
      <h3>Debes mejorar tu plan para acceder a este contenido</h3>
      <div className='flex flex-row justify-center gap-3 mt-5'>
          <button 
              className='w-[100px] hover:bg-[var(--color-primary)]'
              onClick={() => navigate('/pricing')}
          >
              Mejorar
          </button>
          <button 
              className='w-[100px] bg-[var(--color-medium)] hover:bg-[var(--color-primary)]'
              onClick={() => window.history.back()}
          >
              Volver
          </button>
      </div>
    </div>
  )
}