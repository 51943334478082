interface SelectButtonProps {
  options: { label: string; value: string }[]
	name: string
  label?: string
	defaultValue?: string | null
  className?: string
	getValue: (value: string) => void
}

/**
 * Componente SelectButton
 * @param props de la interfaz SelectButtonProps
 * @returns componente que renderiza un select con sus opciones, permitiendo obtener el valor seleccionado
 */
export default function SelectButton({ options, name, label, defaultValue, className, getValue }: SelectButtonProps) {

  let totalClassName = 'w-full flex flex-col items-start text-text-color'
  if (label) totalClassName = `${totalClassName} mt-5`
  if (className) totalClassName = `${totalClassName} ${className}` 

	return (
    <div className={className}>
      {label && 
        <label>{label}</label>
      }
      <select name={name} defaultValue={defaultValue? defaultValue : ''} onChange={(e) => getValue(e.target.value)}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
	)
}
