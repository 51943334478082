import { useState, useEffect, useRef } from 'react'
import Icon from '@/components/icons/Icon'
import Spinner from './common/ui/Spinner'
import { Folder, Mazo } from '../types/types'
import '../styles/Mazos.css'
import { Card } from '../types/cards'

interface MazoProps {
  mazo: Mazo
  folder: Folder | null
  reload: (mazo: Mazo) => void
	onClickMazo: (mazo: Mazo) => void
  onClickPlay: (flashcards: Card[], modoEstudiar: number) => void
}

export const MazoCard = ({ mazo, folder, reload, onClickMazo, onClickPlay }: MazoProps) => {

  const [loading, setLoading] = useState(true)
  const [isOptions, setIsOptions] = useState(false)
  const [isOnEdit, setIsOnEdit] = useState(false)
  const [isOnDelete, setIsOnDelete] = useState(false)
  const [isOnForget, setIsOnForget] = useState(false)
  const [nombreExiste, setNombreExiste] = useState(false)
  const [nombresOcupados, setNombresOcupados] = useState<string[]>([]);
  const nameInputRef = useRef<HTMLInputElement>(null)

  const [numEstudiar, setNumEstudiar] = useState(0)
  const [flashcards, setFlashcards] = useState<Card[]>([])
  const [modoEstudiar, setModoEstudiar] = useState(0)

  useEffect(() => {
    const fetchFlashcards = async () => {
      if(mazo){
        try {
          const response = await fetch(
            `${import.meta.env.VITE_API_URL}/card/get_for_study?mazoId=${mazo.id}`, 
            {
              method: 'GET',
              headers: { 'Content-Type': 'application/json', },
              credentials: 'include'
            }
          )
          if (!response.ok){
            throw new Error('Error al conseguir las flashcards');
          }			


          const data = await response.json()
          if (data.modoEstudio == 4) {
            setModoEstudiar(4)
            setNumEstudiar(data.Array.length)
          } else if (data.modoEstudio == 5) {
            setModoEstudiar(5)
            setNumEstudiar(0)
          } else if (data.modoEstudio == 2) {
            let tarjetas=0
            let numero=data.numEstudiar
            for(let i=0; i<data.Array.length; i++){
              if(data.Array[i].final_bool==false && numero>0){
                tarjetas++
                numero--
              }
            }
            setNumEstudiar(Math.min(data.numEstudiar,tarjetas))
            setModoEstudiar(2)
            //setNumEstudiar(data.numEstudiar)
          } else if (data.modoEstudio == 1) {
            setNumEstudiar(data.numEstudiar)
            setModoEstudiar(1)
          }
          setFlashcards(data.Array)
          setLoading(false)

        } catch (error) {
          console.error('Error:', error);
          return
        }
      }
    }
    fetchFlashcards()
}, [mazo])



  useEffect(() => {
		if (isOnEdit) 
      nameInputRef.current?.focus()
	}, [isOnEdit])

  useEffect(() => {
		setIsOptions(false)
    setIsOnEdit(false)
    setIsOnDelete(false)
    setIsOnForget(false)
	}, [folder])

  const comprobarN = async () => {
    let bandera=0
    for(let i=0; i<nombresOcupados.length; i++){
      if(nameInputRef.current?.value==nombresOcupados[i]){
        setNombreExiste(true)
        bandera++
      }
    }
    if(nameInputRef.current?.value==''){
      setNombreExiste(true)
      bandera++
    }
    if (bandera==0){
      setNombreExiste(false)
    }
  }

  const handleDeleteMazo = async (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOptions(false)
    setIsOnDelete(false)
    try {
			const response = await fetch(
        `${import.meta.env.VITE_API_URL}/mazo/delete?mazoId=${mazo.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', },
          credentials: 'include'
			  },
      )
			if (!response.ok){
				throw new Error('Error al eliminar mazo');
			}			
			reload(mazo)
		} catch (error) {
			console.error('Error:', error);
		}
  }

  const handleForgetMazo = async (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOptions(false)
    setIsOnForget(false)
    try {
			const response = await fetch(
        `${import.meta.env.VITE_API_URL}/mazo/forget?mazoId=${mazo.id}`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json', },
			  }
      )
			if (!response.ok){
				throw new Error('Error al olvidar mazo');
			}			
			reload(mazo)
		} catch (error) {
			console.error('Error:', error);
		}
  }

  const setNewName = async () => {
    if (nameInputRef.current) {
			if(folder){
        const newName = nameInputRef.current?.value
        try {
          const response = await fetch(
            `${import.meta.env.VITE_API_URL}/mazo/rename?mazoId=${mazo.id}&newName=${newName}`,
            {
              method: 'PATCH',
              headers: { 'Content-Type': 'application/json', },
            }
          )
          if (!response.ok){
            throw new Error('Error al editar nombre de mazo');
          }			
          setIsOnEdit(false)
          setIsOptions(false)
          setNombresOcupados([])
          reload(mazo)
        } catch (error) {
          console.error('Error:', error);
        }
        setNombreExiste(false)
      } 
    }
  }

  const handleOpenOptions = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOptions(true)
  }

  const handleCloseOptions = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOnEdit(false)
    setIsOnDelete(false)
    setIsOnForget(false)
    setIsOptions(false)
    setNombreExiste(false)
    setNombresOcupados([])
  }
  
  const handleOnClickPlay = (event: React.MouseEvent) => {
    event.stopPropagation()
    if(modoEstudiar == 4 || modoEstudiar == 5){
      onClickPlay(flashcards, modoEstudiar)
    } else if(modoEstudiar == 2){
      let tarjetas=[]
      let numero=numEstudiar
      for(let i=0; i<flashcards.length; i++){
        if(flashcards[i].final_bool==false && numero>0){
          tarjetas.push(flashcards[i])
          numero--
        }
      }
      onClickPlay(tarjetas, modoEstudiar)
    } else if(modoEstudiar == 1){
      let tarjetas: Card[]=[]
      function obtenerFechaActual() {
        const fecha = new Date();
      
        const año = fecha.getFullYear();
        const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // getMonth() devuelve de 0 a 11, por eso sumamos 1
        const dia = ('0' + fecha.getDate()).slice(-2);
      
        return `${año}-${mes}-${dia}`;
      }
      let hoy = obtenerFechaActual();
      flashcards.forEach(flashcard => {
        flashcard.exam_date.forEach((date, index) => {
          if (date === hoy && !flashcard.exam_bool[index]) {
            tarjetas.push(flashcard)
          }
        });
      });


      onClickPlay(tarjetas, modoEstudiar)
    }
  }

	return (
		<div
      className={isOptions ? 'mazo_container_options' : 'mazo_container'}
      onClick={isOptions && !loading ? undefined : (() => onClickMazo(mazo))}
    >
    { isOptions ?( 
      isOnDelete ?
        <div className='mazo_container_left_options_delete'>
          <div className='mazo_container_eliminar_options'>
            <h4 className="h4Hover" >¿Estás seguro de que deseas eliminar {mazo.name.length > 30 ? mazo.name.substring(0, 30) + '...' : mazo.name}?</h4>
            <Icon iconName='xmark' size='30' onClick={handleCloseOptions} className='xmark-icon' />
          </div>
          <div className='mazo_container_si_no'>
            <button className='mal' onClick={()=>setIsOnDelete(false)}>
              No
            </button>
            <button className='bien' onClick={handleDeleteMazo}>
              Si
            </button>
          </div>
        </div>
      : ( isOnForget ?
        <div className='mazo_container_left_options_delete'>
          <div className='mazo_container_eliminar_options'>
            <h4 className="h4Hover" >¿Deseas eliminar tu progreso con el tema {mazo.name.length > 20 ? mazo.name.substring(0, 20) + '...' : mazo.name}?</h4>
            <Icon iconName='xmark' size='30' onClick={handleCloseOptions} className='xmark-icon' />
          </div>
          <div className='mazo_container_si_no'>
            <button className='mal' onClick={()=>setIsOnForget(false)}>
              No
            </button>
            <button className='bien' onClick={handleForgetMazo}>
              Si
            </button>
          </div>
        </div>
      : ( isOnEdit ?
        <div className='mazo_container_left_options_delete'>
          <div className='mazo_container_editar_nombre'>
            <input className='input' ref={nameInputRef} onChange={comprobarN}  type='text' defaultValue={mazo.name} style={{border:'none'}}
            onKeyDown=
            {(e) => {
              if(!nombreExiste){
                if (e.key === 'Enter' ) {
                  e.preventDefault();
                  setNewName()
                }
              }
            }} onClick={(e) => e.stopPropagation()}/> 
            <Icon iconName='xmark' size='30' onClick={handleCloseOptions} className='xmark-icon' />
          </div>
          <div className='mazo_container_si_no'>
            
              <button className='mal' onClick={() => { setIsOnEdit(false); setNombresOcupados([]); setNombreExiste(false) }}>
                No                
              </button>
              <button className='bien' disabled={nombreExiste} onClick={setNewName}>
                Si
              </button>
        
          </div>
        </div>
      : 
        <div className='mazo_container_left_options'>
          
          <div className='mazo_option_borrar' onClick={() => setIsOnDelete(true)}>
            <Icon iconName='trash' />
            <label>Eliminar Tema</label>
          </div>
          <div className='mazo_option_renombrar' onClick={() => setIsOnEdit(true)}>
            <Icon iconName='edit' />
            <label>Renombrar</label>
          </div>
          <div className='mazo_option_olvidar' onClick={() => setIsOnForget(true)}>
            <Icon iconName='redo' />
            <label>Olvidar Tema</label>
          </div>
        </div>
      ))) 
      :
      <div className='mazo_container_left' style={{ overflow: 'visible' }}>
      {(modoEstudiar == 2 || modoEstudiar == 1) ? <div style={{display:'flex', position: 'relative', marginBottom:'auto', top: '-3px', color:'var(--color-medium)', alignItems:'center', fontWeight:'500'}}>
      <Icon iconName='scroll' size='30'/><div style={{marginLeft:'10px'}}>Pendiente para hoy</div>
        </div>:<div style={{marginTop:'8px'}}></div>}
        <h2 style={{ fontSize: '1.1em', overflow: 'hidden', textOverflow: 'ellipsis' }}>{mazo.name}</h2>
        { !loading ? 
          <h4>
            { flashcards.length == 0 ? ('Tema vacío') :
              (numEstudiar > 1 ? (numEstudiar+' pendientes hoy ') :
              (numEstudiar==1?(numEstudiar+' pendiente hoy '):('Estudio diario hecho')))
            }
          </h4>
        : <Spinner fillAll={false} size='20px'/>
        }
      </div>
    }
    {!isOnDelete && !isOnForget && !isOnEdit?(
    <div className='mazo_container_right'>
      { isOptions ?
        <Icon iconName='xmark' size='30' onClick={handleCloseOptions} className='xmark-icon' />
        :
        <Icon iconName='options' size='25' onClick={handleOpenOptions} />
      }
      {!isOptions && !(flashcards.length == 0) && <Icon iconName='play' size='15' onClick={handleOnClickPlay} className='play-icon'/>}
    </div>
    ) : null}
    </div>
	)
}