import Icon from '@/components/icons/Icon'

interface PricingCardHomeProps {
  name: string,
  duration: number, // Duración en meses
  price: number, // Precio en centimos
  features?: string[],
  current: boolean
  renew?: boolean
  daysToExpire?: number
  callToAction?: string
  onClickButton?: () => void
  height?: string
}

export default function PricingCardHome ({ name, duration, price, features, current, renew, daysToExpire, callToAction, onClickButton, height='430px' }: PricingCardHomeProps) {
  
  if (current === true && renew === false && daysToExpire === undefined) {
    throw new Error('daysToExpire is required when current is true and renew is false');
  }

  return (
    <div className={ current ?
      'flex flex-col justify-between box-border border-4 border-solid border-[var(--color-secondary)] bg-[var(--color-background)] rounded-[20px] text-white p-3 pt-4 pb-5'
      : 'flex flex-col justify-between box-border border-4 border-solid border-[var(--color-medium)] bg-white rounded-[20px] text-white p-2 pt-10 pb-4 hover:bg-[var(--color-medium)] hover:text-white transition-colors duration-200'
    } style={{height:height}}>  
      <div>
        <div className='flex flex-col items-center'>
          {current && 
            <h4 className='text-[var(--color-primary)]'>
              { renew? '(Contratado Actualmente)'
              : `(Expira en ${daysToExpire} dias)`}
            </h4>
          }
          <h3 className='text-[var(--color-primary)]'>Plan {name}</h3>
        </div>
        <div className='text-[var(--color-primary)] flex flex-row items-end justify-center'>
          <h2>{(price/100).toFixed(2)}€</h2>
          <h3>/</h3>  
          <h3>{duration} {duration == 1 ? 'Mes' : 'Meses'}</h3>
        </div>
        { features &&
          <ul className='p-1' style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            {features.map((feature, index) => (
              <li key={index} className='flex justify-between items-center mt-5'>
                <Icon iconName='check' size={'20'} isClickable={false} className={current ? 'absolute text-[var(--color-primary)]': 'absolute text-[var(--color-secondary)]'}/>
                <div className='w-full flex justify-center pl-8 pr-8'>
                  <p className={current ? 'text-[var(--color-primary)] m-0' : 'text-[var(--color-text)] m-0'}>{feature}</p>
                </div>
              </li>    
            ))}
          </ul>
        } 
      </div>
      { onClickButton &&
        <>{ current && renew ?
          <button onClick={onClickButton} className='bg-[var(--color-delete)] hover:bg-[var(--color-error)] rounded-[var(--border-radius)] text-white p-2 mt-3 w-full'>
            Cancelar Plan
          </button> :
          <button onClick={onClickButton} className='bg-[var(--color-secondary)] rounded-[var(--border-radius)] text-white p-2 mt-3 w-full'>
            {current && !renew ? 'Renovar Plan' : callToAction}
          </button>
        }</>
      }
    </div> 
  )
}
