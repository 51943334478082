import { ReactNode } from 'react'

interface FormInputRowProps {
  children: ReactNode | ReactNode[];
}

export default function FormInputRow({ children }: FormInputRowProps) {

	return (
    <div className='w-full flex flex-col sm:flex-row items-start sm:space-x-3'>
      {children}
    </div>
	)
}
