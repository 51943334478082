import { Link, useNavigate } from 'react-router-dom'
import React from 'react'
import '../../styles/Login.css'
import landingImage from '../../assets/landingImage.svg'
import greenlogoblueletter from '../../assets/greenlogoblueletter.svg'
import LoginForm from "@/pages/Login/components/LoginForm";

export default function Login() {
	return (
		<div className='Login'>
			<img className='landing-img' src={landingImage}/>
			<div className='Login-container items-center'>
				<div>
					<img src={greenlogoblueletter} alt='Bilern logo' className="w-[250px] h-[80px]"/>
					<h3>Bienvenido de nuevo!</h3>
				</div>
				<div className='w-72 md:w-96'>
					<LoginForm />
				</div>
				<p>
					No tienes cuenta?{' '}
					<Link to='/register'>
					<strong>Crear cuenta</strong>
				</Link>
			</p>
		</div>
</div>
)
}