import { useEffect, useState } from 'react'
import Popup from './common/ui/Popup';
import { useSubscription } from '@/context/auth/useSubscription';

interface CreateTareasPendientesOptions {
    showOptions: boolean
    close: (refrescarTrue: boolean) => void
}

export default function TareasPendientesOptions({showOptions, close}: CreateTareasPendientesOptions){
    const [selectedDays, setSelectedDays] = useState<boolean[]>(Array(7).fill(false));
    const [guardandoAjustes, setGuardandoAjustes] = useState<boolean>(false);
    const [current, setCurrent] = useState<boolean[]>(Array(7).fill(false));
    const daysOfWeek = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    const areStatesEqual = JSON.stringify(current) === JSON.stringify(selectedDays);

    const suscription = useSubscription()

    const funcionInicio = async () => {
        try{
            const response = await fetch (
                `${import.meta.env.VITE_API_URL}/user/data`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include',
                }
            )
            if(!response.ok) {
                throw new Error('Error al obtener los datos del usuario')
            }
            const user = await response.json()
            if(user.dias_descanso === null){
                setSelectedDays(Array(7).fill(false));
                setCurrent(Array(7).fill(false))
            } else if (Array.isArray(user.dias_descanso)) {
                setSelectedDays(user.dias_descanso);
                setCurrent(user.dias_descanso)
            }
        } catch(error) {
            console.log(error)
        }
    };

    useEffect(() => {
        funcionInicio()
    }, []);

    const toggleDay = (index: number) => {
        setSelectedDays(prev => {
            const newDays = [...prev];
            newDays[index] = !newDays[index];
            return newDays;
        });
    };

    useEffect(() => {
        if(guardandoAjustes && !suscription.loading){
            if (typeof suscription.subscriptionPlanId === 'string' && suscription.subscriptionPlanId != 'ef0d1a2c-1075-4576-ac9f-276bbb25296c') {
                guardarAjustes()
            } else{
                setGuardandoAjustes(false)
                close(true);
            }
        }
    }, [suscription, guardandoAjustes])

    const guardarAjustes = async () => {
        try{
            const updatedUser = {
                dias_descanso: selectedDays,
            }
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/user/update`, 
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify(updatedUser),
                    credentials: 'include',
                }
            )
            if (!response.ok){
                throw new Error('Error en la actualización de los datos del usuario');
            }

            const secondResponse = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({}),
                    credentials: 'include'
              }
            )
            if (!secondResponse.ok){
                throw new Error('Error al actualizar los examenes');
            }	
            close(true);
        } catch(error) {
            console.log(error)
        }
    }

    return (
        <Popup open={showOptions} onClose={()=>close(false)} width='500px'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                <h3 style={{ margin: '0 20px 10px 0' }}>Ajustes de la organización diaria</h3>
                <div style={{ margin: '10px 0 10px 0', marginRight:'auto' }}><div style={{fontWeight:'bold', display:'inline'}}>Dias de descanso:</div> se organizará tu estudio para que puedas descansar 
                    los días indicados
                </div>
                <div className="flex justify-center flex-wrap">
                    {daysOfWeek.map((day, index) => (
                        <button
                        key={day}
                        className={`m-[5px] w-[calc(25%-10px)] md:w-[40px] h-[40px] ${selectedDays[index] ? 'bg-[var(--color-secondary)] text-white' : 'bg-white text-black'} border border-gray-400 rounded-full cursor-pointer flex items-center justify-center`}
                        onClick={() => toggleDay(index)}
                        >
                        {day}
                        </button>
                    ))}
                </div>
                <button
                    style={{
                        marginTop: '20px',
                        border: 'none',
                        backgroundColor: guardandoAjustes || areStatesEqual ? 'var(--color-text-light)' : 'var(--color-secondary)',
                        color: guardandoAjustes || areStatesEqual ? 'var(--color-background)' : 'white',
                        cursor: guardandoAjustes || areStatesEqual ? 'default' : 'pointer'
                    }}
                    onClick={() => setGuardandoAjustes(true)}
                    onMouseEnter={(e) => {
                        if (!guardandoAjustes && !areStatesEqual) {
                            e.currentTarget.style.backgroundColor = 'var(--color-medium)';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!guardandoAjustes && !areStatesEqual) {
                            e.currentTarget.style.backgroundColor = 'var(--color-secondary)';
                        }
                    }}
                    disabled={guardandoAjustes || areStatesEqual}
                >
                    {guardandoAjustes?"Guardando...":"Guardar"}
                </button>
            </div>
        </Popup>
    )
}
