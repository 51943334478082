import { OcultarCard } from '@/types/cards'
import  Latex  from 'react-latex-next'

interface OcultarCardStudyProps {
  card: OcultarCard 
  revealed: boolean
}
/**
 * Componente OcultarCardStudy.
 * Se encarga de el estudio de una carta de tipo ocultar.
 * @param props de la interfaz OcultarCardStudyProps
 * @returns componente con la interfaz de estudio de una carta de tipo ocultar
 */
export default function OcultarCardStudy({ card, revealed }: OcultarCardStudyProps) {

  const ocultarSpanRegex = /<span elemento-oculto="true" class="oculto">.*?<\/span>/g
  const initialText = card.text.replace(ocultarSpanRegex, '<span elemento-oculto=true class="oculto">...</span>')

  return(
    <div className='flex flex-col items-center justify-center w-[73vw] max-w-[1000px] max-h-[50vh] mb-3'>
      { !revealed ?
        <h2><Latex>{initialText}</Latex></h2>
      :
        <h2><Latex>{card.text}</Latex></h2>
      }
    </div>
  )
}
