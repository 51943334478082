import { useEffect, useRef, useState } from 'react'
import Icon from '@/components/icons/Icon'

interface CheckBoxProps {
	label?: string
	value?: string
	key?: any
	changeChecked?: boolean
	irreversible?: boolean // Una vez marcado no se puede desmarcar salvo externamente mediante changeChecked
  getChecked: (checked: boolean) => void
}

export default function Datalist({ label, value, key, changeChecked, irreversible=false, getChecked }: CheckBoxProps) {

	const checkboxRef = useRef<HTMLInputElement>(null)

	const [checked, setChecked] = useState<boolean>(false)

	useEffect(() => {
		if (changeChecked !== undefined)
			setChecked(changeChecked)
	}, [changeChecked])

	const handleCheck = () => {
		setChecked(true)
		getChecked(true)
	}
	const handleUncheck = () => {
		if (!irreversible) {
			if (checkboxRef.current?.checked)
				checkboxRef.current.checked = false
			setChecked(false)
			getChecked(false)
		}
	}
 
	return (
		<div className='flex flex-row items-center'>
			<input
				ref={checkboxRef}
				className={ checked ? 'hidden' : 'w-5 h-5 mr-3 border border-[var(--color-text-light)] rounded-md cursor-pointer'}
				type='checkbox' 
				key={key}
				value={value}
				checked={checked}
				onChange={(e) => e.target.checked && handleCheck()} 
			/>
			{ checked &&
				<span 
					className='w-5 h-5 mr-3 border border-[var(--color-text-light)] rounded-md checkmark bg-green-500'
					onClick={handleUncheck}
				>
					<Icon iconName='check' size={'15'} className='text-white mt-[2px]' />
				</span>
			}
			{label && <p>{label}</p>}
		</div>
	)
}
