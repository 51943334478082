import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import './Header.css'
import { ScrollTarget } from '@/pages/Landing'

export default function HeaderLanding({ getScrollTarget }: { getScrollTarget: (target: ScrollTarget) => void }) {

  const [scrollTarget, setScrollTarget] = useState<ScrollTarget>('inicio')

	useEffect(() => {
		getScrollTarget(scrollTarget)
	}, [scrollTarget])

  function isScrolled(target: string) {
		if (scrollTarget == target) return 'active'
		else return ''
	}

  return (
		<Header
			navBar={ window.innerWidth > 1000 &&
				<nav className="Navbar">
					<a onClick={() => setScrollTarget('inicio')} className={isScrolled('inicio')}>
						Inicio
					</a>
					<a onClick={() => setScrollTarget('metodo')} className={isScrolled('metodo')}>
						Método
					</a>
					<a onClick={() => setScrollTarget('interfaz')} className={isScrolled('interfaz')}>
						Interfaz
					</a>
					<a onClick={() => setScrollTarget('contacto')} className={isScrolled('contacto')}>
						Contáctanos
					</a>
				</nav>
			}
			rightNode={
				<Link to='/login'>
					<button>Iniciar Sesión</button>
				</Link>
			}
		/>
  )
}
