import React, { SetStateAction, useEffect, useState } from 'react';
import { isToday, isTomorrow, format, addDays, subDays, eachDayOfInterval } from 'date-fns';
import { es } from 'date-fns/locale';
import OrganizacionCard from './OrganizacionCard'; 
import Icon from './icons/Icon';
import { ExamPrevDays, ExamTask, Task } from '@/types/types';
import TareasPendientesOptions from './TareasPendientesOptions';
import Calendar from '@/components/pages/tareas/Calendar';
import { useSubscription } from '@/context/auth/useSubscription';

interface TareasPendientesPageProps {
    estudio: (tarjetas: any, modo: number) => void
}

export default function TareasPendientesPage({estudio}:TareasPendientesPageProps) {
    const [startDate, setStartDate] = useState(new Date());
    const [expandedDays, setExpandedDays] = useState<{ [key: string]: boolean }>({});
    const [allTareas, setAllTareas] = useState<Task[]>([]);
    const [examCards, setExamCards] = useState<any[]>([]);
    const [exams, setExams] = useState<any[]>([]);
    const [showOptions, setShowOptions] = useState(false);
    const [diasPrevios, setDiasPrevios] = useState<ExamPrevDays[]>([]);
    const [rachaDays, setRachaDays ] = useState(0);
    const [allCards, setAllCards] = useState<any[]>([]);
    const [refrescar, setRefrescar] = useState(false);

    const suscription = useSubscription()

    useEffect(() => {
        const getStreak = async () => {
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/estadisticas/get_stats`, 
                      {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                      }
                    )
                if (!response.ok) {
                    throw new Error('Error al actualizar las estadisticas');
                }
                const data = await response.json()
                setRachaDays(data.racha)
            } catch (error) {
                console.error('Error:', error)
            }
        }
        getStreak()
        getTasks()
        getExamCards()
    }, [refrescar])

    useEffect(() => {
        if (typeof suscription.subscriptionPlanId === 'string' && suscription.subscriptionPlanId != 'ef0d1a2c-1075-4576-ac9f-276bbb25296c' && !suscription.loading) {
            getExamCards()
        }
    }, [suscription.loading,refrescar])

    const getTasks = async () => {
        try {
            const response = await fetch(
            `${import.meta.env.VITE_API_URL}/task/get_from_user_id`, 
                {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include'
                }
            )
            if (!response.ok){
                throw new Error('Error al conseguir las tareas')
            }			
            const data = await response.json()
            setAllTareas(data)
    
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const getExamCards = async () => {
        try {
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/get_cards_from_user`, 
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include'
                }
            )
            if(response.status === 401){
                return 
            }
            if (!response.ok){
                throw new Error('Error al conseguir las tareas')
            }			

            const data = await response.json()
            const cards=data.cards
            setAllCards(cards)
            const exams=data.exams
            const diasPrevios=data.diasPrevios
            setExams(exams)
            let cartasFiltradas=[]
            for (let i = 0; i < cards.length; i++) {
                if(cards[i].exam_date) {
                    for (let j = 0; j < cards[i].exam_date.length; j++) {
                        const cardInfo = {
                            exam_date: cards[i].exam_date[j],
                            exam_bool: cards[i].exam_bool[j],
                            mazo_id: cards[i].mazo_id,
                            mazo_name: cards[i].mazo_name
                        };
                        cartasFiltradas.push(cardInfo);
                    }
                }
            }
            setDiasPrevios(diasPrevios)
            setExamCards(cartasFiltradas)

        } catch (error) {
            console.error('Error:', error)
        }
    }



    const handlePreviousWeek = () => {
        setStartDate(subDays(startDate, 7));
    };

    const handleNextWeek = () => {
        setStartDate(addDays(startDate, 7));
    };

    const daysInInterval = eachDayOfInterval({
        start: startDate,
        end: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6)
    });

    const toggleDay = (day: string) => {
        setExpandedDays(prevState => ({
            ...prevState,
            [day]: !prevState[day]
        }));
    };

    const endDate = addDays(startDate, 6);



    return (
        <>
            <div className='flex flex-col-reverse md:flex-row py-2 px-5 justify-between items-center'>
                <div className="flex box-border flex-row items-center justify-between h-10 w-80 bg-[var(--color-background)] rounded-[8px] p-[5px] mt-[10px] md:ml-[10px] text-[var(--color-text)]">                    
                    <Icon iconName='angleLeft' onClick={handlePreviousWeek} />
                    <h4 style={{ margin: '0 5px'}}>
                        {format(startDate, 'dd MMMM', { locale: es })} - {format(endDate, 'dd MMMM', { locale: es })}
                    </h4>
                    <Icon iconName='angleRight' onClick={handleNextWeek} />
                </div>    
                {showOptions && <TareasPendientesOptions showOptions={showOptions} close={(refrescarTrue)=>{setShowOptions(false); if(refrescarTrue){setRefrescar(!refrescar)}}}/>}
                <div className="flex flex-row items-center text-[#FFC90E] text-[17px] mt-[10px] mb-[5px] h-[37px] md:mr-[8vw] justify-between w-80 md:w-auto">
                    <button
                        className="flex items-center px-[13px] hover:bg-[var(--color-background)] py-[8px] bg-[var(--color-background)] rounded-[8px] border-none cursor-pointer text-[var(--color-text)] md:mx-[15px]"
                        onClick={() => setShowOptions(true)}
                    >
                        Ajustes
                    </button>
                    <div className="flex flex-row items-center" >
                        <Icon iconName='flame' isClickable={false}  size='35'/>
                        <div style={{ margin: '0 3px 0 5px', fontSize: '24px', fontWeight: 'bold', position: 'relative', top: '-3px', color:'var(--color-text)' }}>
                            {rachaDays}
                        </div>
                        <div style={{ position: 'relative', top: '-1px', color:'var(--color-text)' }}>
                            días de racha
                        </div>
                    </div>
                </div>
            </div>
            <div > {/*<div className='flex flex-row justify-between'> */}
            <div style={{ margin: '10px 40px' }} >
            <ul className="scrollable-list" style={{ height: 'auto', marginBottom: '5vh' }}>
                {daysInInterval.map(day => {
                    const dayString = format(day, 'yyyy-MM-dd'); // Convertir day a 'YYYY-MM-DD'
                    const tareasDelDia = allTareas
                    .filter(tarea => tarea.date === dayString)
                    .sort((a, b) => a.content.localeCompare(b.content));      
                    
                    const cartasDelDia = examCards
                    .filter(cartas => cartas.exam_date === dayString)  

                    let tareasExamen: SetStateAction<ExamTask[] | undefined> = [];

                    const groupedTasks: { [key: string]: ExamTask } = {};

                    for (let i = 0; i < cartasDelDia.length; i++) {
                        const mazo_id = cartasDelDia[i].mazo_id;

                        if (groupedTasks[mazo_id]) {
                            groupedTasks[mazo_id].total += 1;
                            if(cartasDelDia[i].exam_bool){
                                groupedTasks[mazo_id].done += 1
                            }
                        } else {
                            let valueDone
                            if(cartasDelDia[i].exam_bool){
                                valueDone = 1
                            } else {
                                valueDone = 0
                            }
                            groupedTasks[mazo_id] = {
                                name: cartasDelDia[i].mazo_name,
                                mazo_id: mazo_id,
                                total: 1,
                                done: valueDone, // Assuming initial value of 'done' is 1
                                cards: []
                            };
                        }
                    }

                    for (let key in groupedTasks) {
                        groupedTasks[key].cards = allCards.filter(card => {
                            const dateIndex = card.exam_date.indexOf(dayString);
                            return card.mazo_id === key && 
                                   dateIndex !== -1 && 
                                   card.exam_bool[dateIndex] === false;
                        });
                    }

                    tareasExamen = Object.values(groupedTasks);

                    let examAlerts = []
                    for(let i=0;i<exams.length;i++){
                        let exam = exams[i]
                        let exam_date = exam.fecha_fin
                        if(exam_date === dayString){
                            examAlerts.push(exam.name)
                        }
                    }

                    function normalizeToMidnightUTC(date: Date): Date {
                        // Ajustar la fecha a la zona horaria local
                        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                        return new Date(Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate()));
                    }
                    
                    const currentDate = normalizeToMidnightUTC(new Date());
                    const todayUTC = currentDate.toISOString().split('T')[0]; // Obtener la fecha de hoy en UTC a medianoche en formato 'YYYY-MM-DD'

                    const examPrevDays = diasPrevios.filter(dia => dia.exam_date === dayString);
                    for(let i=0;i<examPrevDays.length;i++){
                        if(examPrevDays[i].num_cards==0){
                            examPrevDays.splice(i,1)
                        }
                    }
                    const examPrevDaysFiltered = examPrevDays.filter(dia => dia.exam_date === todayUTC);
                    
                    let cartasDiasPrevios =[]
                    for(let i=0;i<examPrevDaysFiltered.length;i++){
                        let totalACoger=examPrevDaysFiltered[i].num_cards-examPrevDaysFiltered[i].num_cartas_estudiadas
                        let temp=[]
                        let mazos_id = examPrevDaysFiltered[i].mazos_id
                        for(let j=0;j<allCards.length;j++){
                            if (mazos_id.includes(allCards[j].mazo_id) && allCards[j].final_bool == false && totalACoger>0){
                                temp.push(allCards[j]);
                                totalACoger--;
                            }
                        }
                        cartasDiasPrevios.push(temp)
                    }

                    return (
                        <React.Fragment key={dayString}>
                            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                                <button onClick={() => toggleDay(dayString)} style={{ border: 'none', cursor: 'pointer', padding: '10px', color: 'black', background: 'none', marginRight: '10px' }}>
                                    {expandedDays[dayString] ? <Icon iconName='angleDown' size='15' /> : <Icon iconName='angleRight' size='15' />}
                                </button>
                                <div style={{ display: 'flex', alignItems: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '85%' }}>
                                    <label style={{ display: 'flex', flexDirection:'row', alignItems: 'flex-end', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '20px' }}>
                                        {isToday(day) ? 'Hoy' : isTomorrow(day) ? 'Mañana' : format(day, 'dd/MM/yy', { locale: es })}
                                        {(tareasDelDia.length > 0 || tareasExamen.length > 0 || examPrevDays.length > 0) && ` (${tareasDelDia.length+tareasExamen.length+examPrevDays.length}) `}
                                        <label style={{ color: 'red', marginLeft: '10px', fontSize: '17px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {examAlerts.length > 0 && `  Examen de ${examAlerts.join(', Examen de ')}`}
                                        </label>                                  
                                    </label>
                                </div>
                            </li>
                            {expandedDays[dayString] && (
                                <li style={{ paddingLeft: '20px', marginTop: '5px' }}>
                                    <OrganizacionCard tareas={tareasDelDia} examCards={tareasExamen} examPrev={examPrevDays} date={day} cartasDiasPrevios={cartasDiasPrevios} updateTasks={()=>getTasks()} play={(cartasDiasPrevios,modo)=>{estudio(cartasDiasPrevios,modo)}}/>
                                    
                                </li>
                            )}
                        </React.Fragment>
                    )
                })}
            </ul>
            </div>
            <div className='flex items-center mr-10'>{/*}
                <Calendar 
                    anchorDates={allTareas.map(tarea => new Date(tarea.date))}
                    onToggleDate={ (date) => toggleDay(format(date, 'yyyy-MM-dd'))} 
                />
            */}</div>
            </div>
        </>
    )
}